<template>
  <div class="released">
    <div class="bidding_top">
      <div class="bidding_content">
        <div class="name">拼团交易</div>
        <div class="release" @click="addClick">发布拼团交易信息</div>
      </div>
    </div>
    <div class="form">
      <div class="fromContent">
        <el-input
          v-model="goodsNameOrNum"
          placeholder="请输入商品名称/商品编号"
          maxLength="99"
          style="width: 300px; margin-left: 5px"
        ></el-input>
        <el-select
          placeholder="拼团状态"
          style="width: 300px; margin-left: 10px"
          v-model="config.groupStatus"
        >
          <el-option
            placeholder="请选择拼团状态"
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button class="hy-btn" @click="searchClick">搜索</el-button>
        <el-button class="cl-btn" @click="eliminateClick">清除</el-button>
      </div>
    </div>

    <div class="bidd">
      <div class="biddings" v-if="readlist.length > 0">
        <div
          class="item"
          v-for="(item, index) in readlist"
          :key="index"
          @click="ItemClick(item)"
        >
          <div class="item_title">
            <div style="display: flex">
              <span style="font-size: 20px; font-weight: bold">{{
                item.enterpriseName
              }}</span>
              <div
                style="
                  line-height: 30px;
                  background: orange;
                  width: 80px;
                  height: 30px;
                  text-align: center;
                  border-radius: 10px;
                  color: white;
                  margin-left: 15px;
                "
              >
                {{ item.tradeType | tradeTypes
                }}{{ item.orderType | orderType }}
              </div>
              <div
                style="
                  color: white;
                  border-radius: 10px;
                  line-height: 30px;
                  background: red;
                  margin-left: 10px;
                  width: 80px;
                  height: 30px;
                  text-align: center;
                "
              >
                拼团订单
              </div>
              <div
                style="line-height: 30px; margin-left: 10px; color: red"
                v-if="item.goodsStatus == 0 && item.auditStatus == 0"
              >
                待审核
              </div>
              <div
                style="line-height: 30px; margin-left: 10px; color: green"
                v-if="item.goodsStatus == 0 && item.auditStatus == 1"
              >
                审核通过
              </div>
            </div>
            <div style="display: flex">
              <div style="line-height: 40px; margin-right: 20px">
                {{ item.createTime }}
              </div>
              <div class="detele">
                <el-button
                  v-if="item.groupStatus == 3"
                  @click.stop="detele(item)"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
          <div class="item_name">
            <div class="item_images">
              <span v-if="item.taxIncluded == 0">含税</span>
              <span v-if="item.taxIncluded == 1">不含税</span>
              <span v-if="item.priceType == 0">未知</span>
              <span v-if="item.priceType == 1">坑口价</span>
              <span v-if="item.priceType == 2">出厂价</span>
              <span v-if="item.priceType == 3">到港价</span>
              <span v-if="item.priceType == 4">平仓价</span>
              <span v-if="item.priceType == 5">车板价</span>
              <span v-if="item.priceType == 6">到厂价</span>
            </div>

            <div class="tradeName">
              商品名称：
              <span>{{ item.goodsName }}</span>
            </div>
            <div class="tradeNumber">
              我已拼团数量：<span>{{ item.meGroupCount }}</span
              >吨
            </div>
          </div>
          <div style="display: flex; justify-content: space-between">
            <div>
              <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
              <div class="place">产地：{{ item.originPlace }}</div>
            </div>

            <div class="time">
              <div class="spell">
                <span v-if="item.groupStatus == 0">未开始</span>
                <span v-if="item.groupStatus == 1">进行中</span>
                <span v-if="item.groupStatus == 2">拼团成功</span>
                <span v-if="item.groupStatus == 3">拼团失败</span>
                <span v-if="item.groupStatus == 4">拼团校验中</span>
              </div>
              <div class="countdown" v-if="item.groupStatus === 1">
                <span>倒计时:</span>
                <CountDown :endDate="`${item.residueTime}`"></CountDown>
              </div>
            </div>
          </div>
        </div>

        <div class="allpage" v-if="readlist.length">
          <el-pagination
            background
            :total="total"
            :page-size="size"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </div>
      <div class="nodataImg" v-else>
        <img src="../../assets/zxx_img/nodata.png" alt="" />
      </div>
    </div>
  </div>
</template>
    
    <script>
import Vue from "vue";
import * as types from "@/store/action-types";
import { groupTransactionList, setclose, deteleGroup } from "@/api/hy.js";
import CountDown from "@/components/countDown.vue";
import { formateTimeStamp } from "@/utils/countdown";
export default {
  data() {
    return {
      goodsNameOrNum: "",
      ticker: null,
      options: [
        {
          value: "0",
          label: "未开始",
        },
        {
          value: "1",
          label: "可拼团",
        },

        {
          value: "2",
          label: "拼团成功",
        },

        {
          value: "3",
          label: "拼团失败",
        },
        {
          value: "4",
          label: "拼团校验中",
        },
      ],

      readlist: [],
      page: 1,
      size: 10,
      total: 0,
      config: {
        groupStatus: "",
      },
    };
  },
  components: {
    CountDown,
  },
  mounted() {
    if (this.ticker) {
      clearInterval(this.ticker);
    }
    this.beginTimer();
    this.getlist();
  },
  filters: {
    tradeTypes(val) {
      switch (val) {
        case 1:
          return "挂牌";
          break;
        case 2:
          return "竞价";
          break;
      }
    },
    orderType(val) {
      switch (val) {
        case 1:
          return "销售";
          break;
        case 2:
          return "采购";
          break;
      }
    },
    // 拼团有效期的filter
    limitTimeFilter(val) {
      if (val > 0) {
        let formateLimitTimes = formateTimeStamp(val);
        let txt = `${formateLimitTimes.hour} 时 ${formateLimitTimes.min} 分 ${formateLimitTimes.seconds} 秒`;
        if (formateLimitTimes.day != "00") {
          txt = `${Number(formateLimitTimes.day) * 24} 时 ${
            formateLimitTimes.min
          } 分   ${formateLimitTimes.seconds} 秒`;
        }
        return txt;
      } else {
        const twtxt = `0 时 0 分 0 秒`;
        return twtxt;
      }
    },
  },
  methods: {
    beginTimer() {
      //这个计时器是每秒减去数组中指定字段的时间
      this.ticker = setInterval(() => {
        for (let i = 0, len = this.readlist.length; i < len; i++) {
          const item = this.readlist[i];
          if (item.residueTime > 0) {
            this.readlist[i].residueTime = this.readlist[i].residueTime - 1;
          }
        }
      }, 1000);
    },
    searchClick() {
      this.getlist();
    },
    eliminateClick() {
      this.goodsNameOrNum = "";
      this.config.groupStatus = "";
    },
    addClick() {
      if (!this.beforhandle("personalCenter:auctionSale:publish:release"))
        return;
      this.$router.push({
        path: "piecingTrade",
        query: {
          title: "发布拼团交易信息",
        },
      });
    },
    async handleCurrentChange(val) {
      let config = {
        page: val,
        size: this.size,
        selectOrderType: 1,
        selectTradeType: 2,
        selectType: 2,
      };
      let res = await groupTransactionList(config);
      if (res.code == 0) {
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
        });
        this.total = res.data.total;
        this.readlist = list;
      }
    },
    async getlist() {
      let enterpriseId = Vue.ls.get(types.enterpriseId);
      let config = {
        goodsNameOrNum: this.goodsNameOrNum,
        pageNum: this.page,
        pageSize: this.size,
        searchType: 2,
        enterpriseId: enterpriseId,
        groupStatus: this.config.groupStatus,
      };
      let res = await groupTransactionList(config);
      if (res.code == 0) {
        let newlist = res.data.list;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          list.push(item);
        });
        this.total = res.data.total;
        this.readlist = list;
      }
    },
    closeDemand(item) {
      if (!this.beforhandle("personalCenter:auctionSale:publish:close")) return;
      console.log(item.goodsId);
      this.$confirm("关闭后商品将无法竞拍", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setclose(item.goodsId).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "关闭成功!",
              });
              this.getlist();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消关闭",
          });
        });
    },
    ItemClick(item) {
      if (!this.beforhandle("personalCenter:auctionSale:publish:detail"))
        return;
      this.$router.push({
        path: "/piecingDetails",
        query: {
          goodsId: item.goodsId,
          status: item.goodsStatus,
          listType: item.listType,
        },
      });
    },
    detele(item) {
      console.log(item);
      let config = {
        goodsId: item.goodsId,
        searchType: 2,
      };
      deteleGroup(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getlist();
        }
      });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}
.released {
  max-width: 1200px;
  margin: 0 auto;
}

.bidd {
  background: white;
  padding: 10px;
  margin: 0 auto;
  .biddings {
    // width: 70%;
    .item {
      width: 100%;
      border: 1px solid black;
      padding: 10px;
      margin-top: 10px;
      .item_title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        .title_but {
          padding: 10px;
          cursor: pointer;
        }
      }
      .item_name {
        display: flex;
        margin-top: 5px;

        .item_images {
          width: 100px;
          height: 24px;
          background: url("../../assets/images/tag.png") no-repeat;
          background-size: 100% 100%;
          color: white;
          padding-left: 3px;
          padding-top: 2px;
          margin-top: 10px;
        }
      }
      .item_num {
        :nth-child(1) {
          width: 300px;
        }
        :nth-child(2) {
          width: 300px;
        }
        display: flex;
        margin-top: 10px;
        // justify-content: space-between;
      }
      .Delivery {
        margin-top: 20px;
      }
      .place {
        margin-top: 20px;
      }
    }
  }
  .allpage {
    text-align: right;
    margin-top: 10px;
  }
}
.form {
  margin: 10px;
  background: white;
  margin: 0 auto;
  padding: 5px 0;
  .fromContent {
    border: 1px solid black;
    display: flex;

    padding: 10px;
    margin: 10px;
  }
  .hy-btn {
    margin-left: 120px;
    width: 100px;
  }

  .cl-btn {
    width: 100px;
  }
}
.tradeName {
  margin-left: 25px;
  margin-top: 10px;
  font-size: 16px;
}
.tradeNumber {
  margin-left: 45px;
  margin-top: 10px;
  font-size: 16px;
  color: red;
}
.time {
  // background: red;

  .spell {
    width: 120px;
    text-align: center;
    background: red;
    color: #fff;
    font-size: 16px;
    float: right;
    padding: 10px 10px;
  }
  .countdown {
    display: flex;
    text-align: center;
    line-height: 30px;
    font-size: 18px;
    span {
      display: inline-block;
      padding: 10px 5px;
    }
  }
}
.detele {
  width: 100px;
  height: 50px;
}
.nodataImg {
  padding: 100px 0;
  text-align: center;
  background: #fff;
}
.bidding_top {
  background: white;
  margin: 0 auto;
  padding: 5px 0;

  .bidding_content {
    border: 1px solid black;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 10px;
    .name {
      font-size: 20px;
      margin-top: 5px;
    }
    .release {
      height: 40px;
      width: 200px;
      text-align: center;
      padding: 10px 20px;
      border-radius: 4px;
      border: 1px solid #ccc;
      cursor: pointer;
      color: white;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
</style>
    