<template>
  <div style="margin: 0 auto; width: 800px">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
    >
      <div class="flex">
        <el-form-item label="交易类型" prop="goodsInfo.orderType">
          <!-- <el-select v-model="ruleForm.goodsInfo.orderType" placeholder="请选择交易类型">
                        <el-option label="挂牌销售" value="0"></el-option>
                        <el-option label="挂牌采购" value="1"></el-option>
                    </el-select> -->
          <el-select
            v-model="ruleForm.goodsInfo.orderType"
            placeholder="请选择类型"
            @change="handleChangeOrderType"
          >
            <el-option
              v-for="item in orderTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="商品总量(吨)"
          prop="goodsInfo.initialCount"
          label-width="200px"
        >
          <div style="display: flex">
            <el-input
              placeholder="请输入商品数量"
              v-model="ruleForm.goodsInfo.initialCount"
              :disabled="checked"
            ></el-input>
            <el-checkbox-group
              v-model="checked"
              @change="checkChange"
              style="margin-left: 20px"
            >
              <el-checkbox label="以实际结算数量为准"></el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
      </div>

      <div
        class="flex"
        style="align-items: flex-start"
        v-for="(goods, index) in ruleForm.goodsCategoryList"
        :key="goods.key"
      >
        <el-form-item
          label="商品类型"
          :prop="'goodsCategoryList.' + index + '.goodsType'"
          :rules="{
            required: true,
            message: '请选择商品类型',
            trigger: 'change',
          }"
        >
          <el-select
            v-model="ruleForm.goodsCategoryList[index].goodsType"
            ref="areaNames"
            @change="goodsChange($event, goods)"
            placeholder="请选择商品类型"
          >
            <el-option
              v-for="item in nameList"
              :key="item.id"
              :label="item.dictName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="商品名称"
          :prop="'goodsCategoryList.' + index + '.goodsName'"
          :rules="{
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          }"
        >
          <el-input
            v-model="ruleForm.goodsCategoryList[index].goodsName"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="价格(元)"
          :prop="'goodsCategoryList.' + index + '.goodsPrice'"
          :rules="{
            required: true,
            message: '请输入价格',
            trigger: 'blur',
          }"
        >
          <el-input
            v-model="ruleForm.goodsCategoryList[index].goodsPrice"
            placeholder="请输入价格"
          ></el-input>
        </el-form-item>
        <el-button
          v-if="goods.key == 0 && ruleForm.goodsInfo.orderType == 1"
          style="margin-left: 10px"
          @click="addGoodes()"
          >增加</el-button
        >
        <el-button
          v-if="goods.key != 0 && ruleForm.goodsInfo.orderType == 1"
          style="margin-left: 10px"
          @click="delGoods(goods)"
          >删除</el-button
        >
      </div>

      <div class="flex">
        <el-form-item label="价格类型" prop="goodsInfo.taxIncluded">
          <div style="display: flex" class="pirceType">
            <el-select
              v-model="ruleForm.goodsInfo.taxIncluded"
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in taxIncludedList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="ruleForm.goodsInfo.priceType"
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in priceTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="配送方式" prop="goodsInfo.deliveryMode">
          <el-select
            v-model="ruleForm.goodsInfo.deliveryMode"
            placeholder="请选择类型"
          >
            <el-option
              v-for="item in ruleForm.goodsInfo.orderType == 1
                ? deliveryModeList1
                : deliveryModeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div
        v-for="(goods, goodsIndex) in ruleForm.goodsCategoryList"
        :key="'goods' + goods.key"
      >
        <div class="quotaList-box" v-if="goods.quotaList.length > 0">
          <h3>{{ goods.dictName }}商品指标</h3>
          <div class="list">
            <div
              class="quota-item"
              v-for="(item, index) in goods.quotaList"
              :key="item.id"
            >
              <el-form-item
                :label="item.dictName"
                :prop="
                  'goodsCategoryList.' +
                  goodsIndex +
                  '.quotaList.' +
                  index +
                  '.quotaValue'
                "
                :rules="{
                  required: item.ifMust == 0,
                  message: item.dictName + '不能为空',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model.trim="item.quotaValue"
                  :placeholder="`请输入${item.dictName}`"
                  @input="formatFloat(goodsIndex, index, 'quotaValue')"
                >
                  <template slot="append" v-if="item.dictValue">{{
                    item.dictValue
                  }}</template>
                </el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
      <div class="flex"></div>
      <div class="keyType">
        <h3>请选择字段是否展示给其他用户</h3>
        <div class="key-group">
          <el-checkbox v-model="viewSet.viewPrice">价格</el-checkbox>
          <el-checkbox v-model="viewSet.viewCount">数量</el-checkbox>
          <el-checkbox v-model="viewSet.viewInviteEnterprise"
            >被邀约方企业名称</el-checkbox
          >
        </div>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <el-button type="primary" @click="nextStep('ruleForm')"
          >下一步</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { reqDictType } from "@/api/hy";
import { updateInviteProgress, getInviteProgress } from "@/api/foundation";
export default {
  data() {
    return {
      goodsName: "",
      checked: false,
      ruleForm: {
        goodsInfo: {
          orderType: "",
          initialCount: "",
          deliveryMode: "",
          tradeType: "1",
          taxIncluded: "",
        },
        goodsCategoryList: [
          {
            goodsName: "",
            goodsPrice: "",
            goodsType: "",
            key: 0,
            quotaList: [],
          },
        ],
      },
      nameList: [],
      orderTypeList: [
        { value: 1, label: "挂牌销售" },
        { value: 2, label: "挂牌采购" },
      ],
      taxIncludedList: [
        { value: 0, label: "含税" },
        { value: 1, label: "不含税" },
      ],
      deliveryModeList: [
        { value: 1, label: "送货" },
        { value: 2, label: "自提" },
      ],
      deliveryModeList1: [
        { value: 1, label: "送货(以实际拉运方式为准)" },
        { value: 2, label: "自提(以实际拉运方式为准)" },
      ],
      priceTypeList: [
        { value: 1, label: "坑口价" },
        { value: 2, label: "出厂价" },
        { value: 3, label: "到港价" },
        { value: 4, label: "平仓价" },
        { value: 5, label: "车板价" },
        { value: 6, label: "到厂价" },
      ],
      rules: {
        "goodsInfo.goodsName": [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        "goodsInfo.orderType": [
          { required: true, message: "请选择交易类型", trigger: "change" },
        ],
        "goodsInfo.initialCount": [
          { required: false, message: "请输入商品数量", trigger: "blur" },
        ],
        "goodsInfo.goodsType": [
          { required: true, message: "请选择商品名称", trigger: "change" },
        ],
        "goodsInfo.listingPrice": [
          { required: true, message: "请输入价格", trigger: "blur" },
        ],
        "goodsInfo.taxIncluded": [
          { required: true, message: "请选择价格类型", trigger: "change" },
        ],
        "goodsInfo.deliveryMode": [
          { required: true, message: "请选择配送方式", trigger: "change" },
        ],
      },
      viewSet: {
        viewCount: false,
        viewInviteEnterprise: false,
        viewPrice: false,
      },
    };
  },

  created() {
    this.getGoodsName();
    this.getInviteProgress();
  },
  methods: {
    handleChangeOrderType(e) {
      if (e == 2) {
        this.ruleForm.goodsCategoryList = this.ruleForm.goodsCategoryList.slice(
          0,
          1
        );
      }
    },
    formatFloat(goodsIndex, index, name) {
      this.ruleForm.goodsCategoryList[goodsIndex].quotaList[index][name] =
        this.ruleForm.goodsCategoryList[goodsIndex].quotaList[index][
          name
        ].match(/^\d*(\.?\d{0,23})/g)[0] || "";
      if (
        this.ruleForm.goodsCategoryList[goodsIndex].quotaList[index][
          name
        ].indexOf(".") > 0
      ) {
        this.ruleForm.goodsCategoryList[goodsIndex].quotaList[index][name] =
          this.ruleForm.goodsCategoryList[goodsIndex].quotaList[index][
            name
          ].substring(
            0,
            this.ruleForm.goodsCategoryList[goodsIndex].quotaList[index][
              name
            ].indexOf(".") + 3
          );
      } else {
        this.ruleForm.goodsCategoryList[goodsIndex].quotaList[index][name] =
          this.ruleForm.goodsCategoryList[goodsIndex].quotaList[index][
            name
          ].substring(0, 9);
      }
    },
    delGoods(goods) {
      this.ruleForm.goodsCategoryList = this.ruleForm.goodsCategoryList.filter(
        (item) => item.key != goods.key
      );
    },
    addGoodes() {
      if (this.ruleForm.goodsCategoryList.length >= 5) {
        this.$message.error("数量超出限制");
        return;
      }
      this.ruleForm.goodsCategoryList.push({
        goodsType: "",
        goodsName: "",
        goodsPrice: "",
        key:
          this.ruleForm.goodsCategoryList[
            this.ruleForm.goodsCategoryList.length - 1
          ].key + 1,
        quotaList: [],
      });
      console.log(this.ruleForm);
    },
    checkChange(e) {
      // if (e) {
      //   console.log(this.rules);
      //   let list = this.rules;
      //   for (const key in list) {
      //     if (key == "goodsInfo.initialCount"&&!e) {
      //       list[key][0].required = true;
      //       return false;
      //     }
      //   }
      // }
    },
    deliveryModeChange(e) {},
    formatQuotaList(res) {
      let data = [
        {
          goodsName: "",
          goodsPrice: "",
          goodsType: "",
          key: 0,
          quotaList: [],
        },
      ];
      if (
        !res.data?.goodsCategoryList ||
        res.data?.goodsCategoryList?.length == 0
      ) {
        return data;
      }
      // debugger;
      data = res.data.goodsCategoryList.map((item, index) => {
        let quota = this.dictList.find((e) => {
          return item.goodsType == e.id;
        });
        item.quotaList = item.quotaList.map((i) => {
          let curQuota = quota.children.find((e) => {
            return i.quotaId == e.id;
          });
          let curQuotaValue = curQuota.dictValue.split(";");
          i.dictName =
            curQuota.dictName + "(" + curQuotaValue[0] + ")" + curQuotaValue[2];
          return i;
        });
        return Object.assign(JSON.parse(JSON.stringify(item)), { key: index });
      });
      return data;
    },
    getInviteProgress() {
      getInviteProgress().then((res) => {
        // console.log(res);
        if (res.data?.viewSet) {
          let keys = Object.keys(this.viewSet);
          keys.forEach((item) => {
            this.viewSet[item] = res.data.viewSet[item] == 1 ? true : false;
          });
        }
        try {
          this.ruleForm.goodsInfo = res.data.goodsInfo;
        } catch (error) {}

        this.ruleForm.goodsCategoryList = this.formatQuotaList(res);
        if (this.ruleForm.goodsInfo.initialCount == "0") {
          this.checked = true;
          this.ruleForm.goodsInfo.initialCount = "";
        }
        // this.ruleForm.goodsInfo.initialCount = parseInt(this.ruleForm.goodsInfo.initialCount)
        // this.goodsName = parseInt(res.data.goodsType)
        // this.ruleForm.goodsInfo.goodsType = res.data.goodsInfo.goodsName
      });
    },
    goodsChange(e, obj) {
      // console.log(e, obj);
      // console.log(this.dictList);
      outer: for (let i = 0; i < this.dictList.length; i++) {
        let item = this.dictList[i];
        if (item.id == e) {
          obj.dictName = item.dictName;
          item.children.forEach((items) => {
            this.$set(items, "quotaValue", "");
          });
          obj.quotaList = item.children.map((item) => {
            let arr = item.dictValue.split(";");
            return {
              ...item,
              dictName: item.dictName + "(" + arr[0] + ")" + arr[2],
              dictValue: arr[1],
            };
          });
          // console.log(obj);
          break outer;
        }
        // for (let j = 0; j < item.children.length; j++) {
        //   let sub = item.children[j];
        //   if (item.id == e[0] && sub.id == e[1]) {
        //     sub.children.forEach((item) => {
        //       this.$set(item, "quotaValue", "");
        //     });
        //     console.log(sub);
        //     this.goodsName = sub.dictName;
        //     this.formData.quotaList = sub.children.map((item) => {
        //       let arr = item.dictValue.split(";");
        //       return {
        //         ...item,
        //         dictName: item.dictName + "(" + arr[0] + ")" + arr[2],
        //         dictValue: arr[1],
        //       };
        //     });
        //     console.log(this.formData.quotaList);
        //     break outer;
        //   }
        // }
      }
      if (e.length > 0) {
        this.$refs.ruleForm.clearValidate("goodsName");
      }
    },
    getGoodsName() {
      reqDictType({ dictType: "coal_type" })
        .then((ret) => {
          if (ret.code == 0) {
            this.dictList = JSON.parse(JSON.stringify(ret.data.children));
            let data = ret.data.children;
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              for (let j = 0; j < item.children.length; j++) {
                let sub = item.children[j];
                delete sub.children;
              }
            }
            this.nameList = data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    nextStep(formName) {
      if (this.checked) {
        this.ruleForm.goodsInfo.initialCount = "0";
      }
      if (!this.ruleForm.goodsInfo.initialCount && !this.checked) {
        this.$message({
          message: "请填写商品数量",
          type: "warning",
        });
        return false;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.ruleForm.goodsInfo.goodsName = this.$refs.areaNames.selected.currentLabel

          let keys = Object.keys(this.viewSet);
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
          ruleForm.goodsCategoryList.forEach((item) => {
            item.quotaList = item.quotaList.map((quota) => {
              return {
                quotaId: quota.id || quota.quotaId,
                quotaValue: quota.quotaValue,
              };
            });
            delete item["key"];
          });
          let viewSet = {};
          keys.forEach((item) => {
            viewSet[item] = this.viewSet[item] ? 1 : 0;
          });
          let data = Object.assign(ruleForm, { viewSet });
          console.log(data);
          // return
          updateInviteProgress(data).then((res) => {
            if (res.code == 0) {
              this.$emit("change", res);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}

.pirceType {
  display: flex !important;
  align-items: center !important;

  .el-select,
  .el-input {
    width: 137.5px !important;
  }
}
::v-deep .quotaList-box {
  h3 {
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    font-weight: normal;
    width: 140px;
    text-align: right;
    padding-right: 12px;
  }

  .list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .quota-item {
      width: 50%;
      flex-shrink: 0;
    }
  }
}
.keyType {
  padding-top: 10px;
  border-top: 1px solid #c0c4cc;
  .key-group {
    padding: 10px;
    display: flex;
    justify-content: space-around;
  }
}
</style>
