<template>
  <div class="markethangtag">
    <div class="bidd">
      <!-- 发布竞价销售信息 -->
      <div class="biddings">
        <div class="biddings_title">
          <div class="biddings_name">挂牌销售</div>
          <div class="release hy-btn" @click="addClick">发布挂牌销售信息</div>
        </div>

        <div
          class="item"
          v-for="(item, index) in readlist"
          :key="index"
          @click="ItemClick(item)"
        >
          <div class="item_title">
            <div class="item_goodsName">
              <div>
                <el-tooltip effect="dark" placement="top-start">
                  <div slot="content">
                    <span
                      v-for="(goodsName, index) in item.goodsCategoryList"
                      :key="index"
                      >{{ goodsName.goodsName }} &nbsp;
                      {{
                        index == item.goodsCategoryList.length - 1 ? "" : "|"
                      }}&nbsp;</span
                    >
                  </div>
                  <div>
                    <span
                      v-for="(goodsName, index) in item.goodsCategoryList"
                      :key="index"
                      >{{ goodsName.goodsName }} &nbsp;
                      {{
                        index == item.goodsCategoryList.length - 1 ? "" : "|"
                      }}&nbsp;</span
                    >
                  </div>
                </el-tooltip>
              </div>
              <!-- <div style="color: rgb(208, 17, 26)">
                交割开始日期：{{ item.deliveryStartTime }}
              </div>
              <div style="margin-left: 20px; color: rgb(208, 17, 26)">
                交割结束日期：{{ item.deliveryEndTime }}
              </div> -->
              <div class="goods_status" style="color: #ec6941">
                {{ item.auditStatus | auditStatus }}
              </div>
            </div>
            <div style="display: flex">
              <div style="line-height: 40px; margin-right: 10px">
                <i
                  class="hy-icon-shijian1"
                  style="fontsize: 10px; marginright: 5px"
                ></i
                >{{ item.createTime }}
              </div>
              <div
                class="title_but"
                @click.stop="closeDemand(item)"
                v-if="item.goodsStatus == 0 && item.auditStatus != 2"
              >
                关闭需求
              </div>
              <div
                class="title_but"
                v-if="item.goodsStatus == 2"
                style="color: #d0111a"
              >
                已流拍
              </div>
              <div
                class="title_but"
                v-if="item.goodsStatus == 1"
                style="color: #d0111a"
              >
                已关闭
              </div>
            </div>

            <div class="title_but" v-if="item.goodsStatus == 3">拍卖成功</div>
            <div class="title_but" v-if="item.goodsStatus == 4">已取消</div>
          </div>
          <div class="item_name">
            <div class="item_images">
              <span v-if="item.taxIncluded == 0">含税</span>
              <span v-if="item.taxIncluded == 1">不含税</span>
              <span v-if="item.priceType == 0">未知</span>
              <span v-if="item.priceType == 1">坑口价</span>
              <span v-if="item.priceType == 2">出厂价</span>
              <span v-if="item.priceType == 3">到港价</span>
              <span v-if="item.priceType == 4">平仓价</span>
              <span v-if="item.priceType == 5">车板价</span>
              <span v-if="item.priceType == 6">到厂价</span>
            </div>
            <div style="margin-left: 20px">
              价格：<span
                style="color: #d0111a"
                v-if="item.goodsCategoryList.length == 1"
                >￥</span
              >
              <span style="color: #d0111a; font-size: 30px">{{
                item.goodsCategoryList.length > 1
                  ? "详情查看"
                  : item.goodsCategoryList[0].goodsPrice == 0
                  ? "面议"
                  : item.goodsCategoryList[0].goodsPrice
              }}</span>
              <span
                v-if="
                  item.goodsCategoryList.length == 1 &&
                  item.goodsCategoryList[0].goodsPrice != 0
                "
                >/吨</span
              >
            </div>
            <div style="margin-left: 20px">
              <span>数量：</span>
              <span style="font-size: 30px; color: #d0111a">{{
                item.ifConsultRealityCount == 0
                  ? item.stockCount + "吨"
                  : "以实际结算数量为准"
              }}</span>
            </div>
          </div>
          <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
          <div class="place">产地：{{ item.originPlace }}</div>
        </div>
        <div class="allpage" v-if="readlist.length > 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tradeGoods, setclose } from "@/api/public.js";
import { tradeGoodsClose } from "@/api/home";
export default {
  data() {
    return {
      readlist: [],
      size: 10,
      page: 1,
      total: null,
    };
  },
  mounted() {
    this.getlist();
  },
  filters: {
    auditStatus(val) {
      let _data = {
        0: "待审核",
        1: "审核通过",
        2: "审核拒绝",
      };
      return _data[val];
    },
  },
  methods: {
    addClick() {
      if (!this.beforhandle("personalCenter:listingSales:publish:publish"))
        return;
      this.$router.push({
        path: "/addGoods",
        query: {
          selectTradeType: 1,
          selectOrderType: 1,
          title: "挂牌销售",
        },
      });
    },
    async handleCurrentChange(val) {
      let config = {
        page: val,
        size: this.size,
        selectOrderType: 1,
        selectTradeType: 1,
        selectType: 2,
      };
      let res = await tradeGoods(config);
      if (res.code == 0) {
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.baddingEtartTime) {
            item.baddingEtartTime = item.baddingEtartTime.replace("T", " ");
          }
          if (item.baddingStartTime) {
            item.baddingStartTime = item.baddingStartTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
        });
        this.total = res.data.total;
        this.readlist = list;
        console.log(this.readlist);
      }
    },
    async getlist() {
      let config = {
        page: this.page,
        size: this.size,
        selectOrderType: 1,
        selectTradeType: 1,
        selectType: 2,
      };
      let res = await tradeGoods(config);
      if (res.code == 0) {
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.baddingEtartTime) {
            item.baddingEtartTime = item.baddingEtartTime.replace("T", " ");
          }
          if (item.baddingStartTime) {
            item.baddingStartTime = item.baddingStartTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
        });
        this.total = res.data.total;
        this.readlist = list;
        console.log(this.readlist);
      }
    },
    closeDemand(item) {
      if (!this.beforhandle("personalCenter:listingSales:publish:close"))
        return;
      this.$confirm("关闭后商品将下架，请确认是否关闭", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        setclose(item.goodsId).then((res) => {
          // console.log(res);
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "关闭成功!",
            });
            this.getlist();
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        });
        // this.tradeGoodsClose(item);
      });
    },
    tradeGoodsClose(data) {
      tradeGoodsClose(data.goodsId).then((res) => {
        if (res.code == 0) {
          this.getlist();
          this.$message({
            type: "success",
            message: "关闭成功!",
          });
        }
      });
    },
    ItemClick(item) {
      if (!this.beforhandle("personalCenter:listingSales:publish:detail"))
        return;
      this.$router.push({
        path: "/purcDetails",
        query: {
          goodsId: item.goodsId,
          status: item.goodsStatus,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}
.markethangtag {
  margin: 20px auto 0;
}

.bidd {
  // width: 80%;
  // display: flex;
  // justify-content: space-between;
  margin: 0 auto;
  .biddings {
    // width: 70%;
    background: #ffffff;
    padding-bottom: 10px;
    .biddings_title {
      display: flex;
      justify-content: space-between;
      background: #e5e5e5;
      padding: 10px;
      .biddings_name {
        font-size: 18px;
        font-weight: bold;
        line-height: 40px;
      }
    }
    .item {
      width: 100%;
      border: 1px solid black;
      padding: 10px;
      margin-top: 10px;
      .item_title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        .item_goodsName {
          display: flex;
          line-height: 40px;
          :nth-child(1) {
            font-size: 18px;
            color: black;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 500px;
          }
          :nth-child(2) {
            margin-left: 13px;
          }
        }
        .title_but {
          padding: 10px;
          border: 1px solid #ccc;
          cursor: pointer;
        }
      }
      .item_name {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-top: 5px;
        .item_images {
          width: 100px;
          height: 24px;
          background: url("../../assets/images/tag.png") no-repeat;
          background-size: 100% 100%;
          color: white;
          padding-left: 3px;
          padding-top: 2px;
          margin-top: 4px;
        }
        // :nth-child(1) {
        //   width: 300px;
        // }
        // :nth-child(2) {
        //   width: 300px;
        // }
      }
      .item_num {
        :nth-child(1) {
          width: 300px;
        }
        :nth-child(2) {
          width: 300px;
        }
        display: flex;
        margin-top: 10px;
        // justify-content: space-between;
      }
      .Delivery {
        margin-top: 10px;
      }
      .place {
        margin-top: 10px;
      }
    }
  }
  .allpage {
    text-align: right;
    margin-top: 10px;
  }
  .release {
    height: 40px;
    padding: 10px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
}
</style>
