var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bidd"},[_c('div',{staticClass:"biddings"},[_c('div',{staticClass:"biddings_title"},[_c('div',{staticClass:"biddings_name"},[_vm._v("挂牌采购")]),_c('div',{staticClass:"release hy-btn",on:{"click":_vm.addClick}},[_vm._v("发布挂牌采购信息")])]),_vm._l((_vm.readlist),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.ItemClick(item)}}},[_c('div',{staticClass:"item_title"},[_c('div',{staticClass:"item_goodsName"},[_c('div',[_c('el-tooltip',{attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((item.goodsCategoryList),function(goodsName,index){return _c('span',{key:index},[_vm._v(_vm._s(goodsName.goodsName)+"   "+_vm._s(index == item.goodsCategoryList.length - 1 ? "" : "|")+" ")])}),0),_c('div',_vm._l((item.goodsCategoryList),function(goodsName,index){return _c('span',{key:index},[_vm._v(_vm._s(goodsName.goodsName)+"   "+_vm._s(index == item.goodsCategoryList.length - 1 ? "" : "|")+" ")])}),0)])],1),(item.goodsStatus == 0 && item.auditStatus == 2)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" 审核拒绝 ")]):_vm._e(),(item.goodsStatus == 0 && item.auditStatus == 0)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" 待审核 ")]):_vm._e(),(item.goodsStatus == 1)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" 已关闭 ")]):_vm._e()]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"line-height":"40px","margin-right":"10px"}},[_vm._v(" "+_vm._s(item.createTime)+" ")]),(
                (item.auditStatus == 0 && item.goodsStatus == 0) ||
                (item.auditStatus == 1 && item.goodsStatus == 0)
              )?_c('div',{staticClass:"title_but",staticStyle:{"border":"1px solid #ccc"},on:{"click":function($event){$event.stopPropagation();return _vm.closeDemand(item)}}},[_vm._v(" 关闭需求 ")]):_vm._e()])]),_c('div',{staticClass:"item_name"},[_c('div',{staticClass:"item_images"},[(item.taxIncluded == 0)?_c('span',[_vm._v("含税")]):_vm._e(),(item.taxIncluded == 1)?_c('span',[_vm._v("不含税")]):_vm._e(),(item.priceType == 0)?_c('span',[_vm._v("未知")]):_vm._e(),(item.priceType == 1)?_c('span',[_vm._v("坑口价")]):_vm._e(),(item.priceType == 2)?_c('span',[_vm._v("出厂价")]):_vm._e(),(item.priceType == 3)?_c('span',[_vm._v("到港价")]):_vm._e(),(item.priceType == 4)?_c('span',[_vm._v("平仓价")]):_vm._e(),(item.priceType == 5)?_c('span',[_vm._v("车板价")]):_vm._e(),(item.priceType == 6)?_c('span',[_vm._v("到厂价")]):_vm._e()]),_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._v(" 价格："),(item.goodsCategoryList.length == 1)?_c('span',{staticStyle:{"color":"#d0111a"}},[_vm._v("￥")]):_vm._e(),_c('span',{staticStyle:{"color":"#d0111a","font-size":"30px"}},[_vm._v(_vm._s(item.goodsCategoryList.length > 1 ? "详情查看" : item.goodsCategoryList[0].goodsPrice == 0 ? "面议" : item.goodsCategoryList[0].goodsPrice))]),(
                item.goodsCategoryList.length == 1 &&
                item.goodsCategoryList[0].goodsPrice != 0
              )?_c('span',[_vm._v("/吨")]):_vm._e()]),_c('div',{staticStyle:{"line-height":"33px","margin-left":"20px"}},[_c('span',[_vm._v("初始数量:")]),(item.ifConsultRealityCount == 0)?_c('span',{staticStyle:{"font-size":"30px","color":"#d0111a"}},[_vm._v(_vm._s(item.initialCount)+"吨")]):_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v("以实际结算数量为准")])]),(item.initialCount > 0)?_c('div',{staticStyle:{"line-height":"33px","margin-left":"20px"}},[_c('span',[_vm._v("库存数量")]),_c('span',{staticStyle:{"font-size":"30px","color":"#d0111a"}},[_vm._v(_vm._s(item.stockCount))]),_vm._v("吨 ")]):_vm._e()]),_c('div',{staticClass:"Delivery"},[_vm._v("交割地："+_vm._s(item.deliveryPlace))]),_c('div',{staticClass:"place"},[_vm._v("产地："+_vm._s(item.originPlace))])])}),(_vm.readlist.length > 0)?_c('div',{staticClass:"allpage"},[_c('el-pagination',{attrs:{"background":"","page-size":_vm.size,"layout":"prev, pager, next","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }