<template>
  <div>
    <div class="order_all">
      <div class="order_top">
        <el-input
          v-model="config.goodsNum"
          placeholder="请输入商品编号"
          class="order_soso"
          clearable
          maxlength="20"
        ></el-input>
        <el-input
          v-model="config.goodsName"
          placeholder="请输入货物名称"
          class="order_soso"
          clearable
          maxlength="20"
        ></el-input>
        <el-select
          v-model="config.type"
          placeholder="请选择"
          class="order_transaction"
          clearable
        >
          <el-option
            v-for="item in tradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" class="order_btn" @click="searchClick"
          >搜索</el-button
        >
        <el-button
          style="width: 100px"
          type="info"
          plain
          @click="eliminateClick"
          >清除</el-button
        >
      </div>
      <div>
        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
          <el-tab-pane label="进行中" name="first">
            <div
              class="item"
              @click="ItemClick(item)"
              v-for="(item, index) in readerList"
              :key="index"
            >
              <div class="item_title">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <div style="display: flex">
                    <div class="title_creatTime">
                      {{ item.lineName }}
                    </div>
                    <i
                      class="hy-icon-shijian1"
                      style="margin-top: 10px; margin-right: 10px"
                    ></i>
                    <div class="title_creatTime">
                      {{ item.createTime | formTime }}
                    </div>
                  </div>
                  <div>
                    <div class="title_but" v-if="!item.isPublisher">
                      我参与的
                    </div>
                    <div class="title_buts" v-if="item.isPublisher">
                      我发布的
                    </div>
                  </div>
                </div>
              </div>
              <div class="clyede_all">
                <div>
                  <span class="sanjiao"></span>
                  <div class="cluded_item">
                    货物名称：<span>{{ item.goodsName }}</span>
                  </div>
                  <div class="cluded_item">
                    发运量：<span>{{ item.monthFreightVolume }}吨</span>
                  </div>
                  <div class="cluded_item">
                    出发地：<span
                      >{{ item.originPlace }}{{ item.originPlaceDetail }}</span
                    >
                  </div>
                  <div class="cluded_item">
                    目的地：<span
                      >{{ item.deliveryPlace
                      }}{{ item.deliveryPlaceDetail }}</span
                    >
                  </div>
                </div>

                <div class="cluded">
                  <span>中标价：{{ item.unitPrice }}元/吨</span>
                  <span v-if="item.taxIncluded == 0">含税</span>
                  <span v-if="item.taxIncluded == 1">不含税</span>
                  <span v-if="item.priceType == 0">未知</span>
                  <span v-if="item.priceType == 1">坑口价</span>
                  <span v-if="item.priceType == 2">出厂价</span>
                  <span v-if="item.priceType == 3">到港价</span>
                  <span v-if="item.priceType == 4">平仓价</span>
                  <span v-if="item.priceType == 5">车板价</span>
                  <span v-if="item.priceType == 6">到厂价</span>
                  <div class="finish-btn1" style="margin-top: 10px">
                    <span v-if="item.ifViewConfirmComplete == 1"
                      >等待对方操作完成</span
                    >
                    <el-button
                      type="primary"
                      v-if="item.ifViewConfirmComplete == 2"
                      @click.stop="handleFinishOrder(item)"
                      >确认订单完成</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="item_num"></div>
              <div
                class="item_btContent"
                v-if="item.ifInvite == 0 || item.ifInvite == 2"
              >
                <div class="content_pl">
                  <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
                  <div class="place">产地：{{ item.originPlace }}</div>
                </div>
                <div class="price" v-if="item.tradeType == '竞价'">
                  <div class="price_item">
                    <div>中标价：<span style="color: #d0111a">￥</span></div>
                    <div>{{ item.dealPrice }}</div>
                    <!-- <div>800</div> -->
                    <div>/吨</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 分页 -->
            <el-row
              type="flex"
              justify="end"
              style="height: 50px"
              align="middle"
            >
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :current-page="config.page"
                :page-size="config.size"
                :page-sizes="[10, 20, 50]"
                @current-change="changePage"
                @size-change="handleSizeChange"
                :pagerCount="5"
              />
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="已完成" name="second">
            <div
              class="item"
              @click="ItemClick(item)"
              v-for="(item, index) in readerList"
              :key="index"
            >
              <div class="item_title">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <div style="display: flex">
                    <div class="title_creatTime">
                      {{ item.lineName | formTime }}
                    </div>
                    <i
                      class="hy-icon-shijian1"
                      style="margin-top: 10px; margin-right: 10px"
                    ></i>
                    <div class="title_creatTime">
                      {{ item.createTime }}
                    </div>
                  </div>
                  <div>
                    <div class="title_but" v-if="!item.isPublisher">
                      我参与的
                    </div>
                    <div class="title_buts" v-if="item.isPublisher">
                      我发布的
                    </div>
                  </div>
                </div>
              </div>
              <div class="clyede_all">
                <div>
                  <span class="sanjiao"></span>
                  <div class="cluded_item">
                    货物名称：<span>{{ item.goodsName }}</span>
                  </div>
                  <div class="cluded_item">
                    发运量：<span>{{ item.monthFreightVolume }}吨</span>
                  </div>
                  <div class="cluded_item">
                    出发地：<span
                      >{{ item.originPlace }}{{ item.originPlaceDetail }}</span
                    >
                  </div>
                  <div class="cluded_item">
                    目的地：<span
                      >{{ item.deliveryPlace
                      }}{{ item.deliveryPlaceDetail }}</span
                    >
                  </div>
                </div>

                <div class="cluded">
                  <span>中标价：{{ item.unitPrice }}元/吨</span>
                  <span v-if="item.taxIncluded == 0">含税</span>
                  <span v-if="item.taxIncluded == 1">不含税</span>
                  <span v-if="item.priceType == 0">未知</span>
                  <span v-if="item.priceType == 1">坑口价</span>
                  <span v-if="item.priceType == 2">出厂价</span>
                  <span v-if="item.priceType == 3">到港价</span>
                  <span v-if="item.priceType == 4">平仓价</span>
                  <span v-if="item.priceType == 5">车板价</span>
                  <span v-if="item.priceType == 6">到厂价</span>
                </div>
              </div>
              <div class="item_num"></div>
              <div
                class="item_btContent"
                v-if="item.ifInvite == 0 || item.ifInvite == 2"
              >
                <div class="content_pl">
                  <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
                  <div class="place">产地：{{ item.originPlace }}</div>
                </div>
                <div class="price" v-if="item.tradeType == '竞价'">
                  <div class="price_item">
                    <div>中标价：<span style="color: #d0111a">￥</span></div>
                    <div>{{ item.dealPrice }}</div>
                    <!-- <div>800</div> -->
                    <div>/吨</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 分页 -->
            <el-row
              type="flex"
              justify="end"
              style="height: 50px"
              align="middle"
            >
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :current-page="config.page"
                :page-size="config.size"
                :page-sizes="[10, 20, 50]"
                @current-change="changePages"
                @size-change="handleSizeChanges"
                :pagerCount="5"
              />
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { listTbOrder } from "@/api/Logistics";
import { tradeOrder, finish } from "@/api/public";
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  data() {
    return {
      total: 0,
      activeName: "first",
      options: [
        {
          value: "1",
          label: "销售",
        },
        {
          value: "2",
          label: "采购",
        },
      ],
      transaction: [
        {
          value: "2",
          label: "竞价",
        },
        {
          value: "1",
          label: "挂牌",
        },
      ],
      tradeOptions: [
        {
          value: "1",
          label: "我参与",
        },
        {
          value: "2",
          label: "我发布的",
        },
      ],
      config: {
        orderStatus: 1,
        pageNum: 1,
        pageSize: 10,
      },

      readerList: [],
    };
  },
  mounted() {
    this.getList();
  },

  filters: {
    formTime(val) {
      if (val) {
        return val.replace("T", " ");
      }
    },
  },
  methods: {
    searchClick() {
      this.config.pageNum = 1;
      this.getList();
    },
    traderFinish(config) {
      finish(config).then((res) => {
        this.$message({
          message: "合同整合完成",
          type: "success",
        });
        this.getList();
      });
    },
    // 处理完成订单
    handleFinishOrder(order) {
      let config = {
        orderId: order.orderId,
      };
      finish(config).then((res) => {
        switch (res.code) {
          case -1:
            this.$confirm(res.message, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.traderFinish({ ...config, confirm: true });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
            break;
          case 0:
            this.traderFinish({ ...config, confirm: true });
            break;

          case 1:
            this.$message({
              message: res.message,
              type: "error",
            });
            break;
        }
      });
    },
    eliminateClick() {
      this.config.goodsNum = "";
      this.config.goodsName = "";
      this.config.type = "";
      this.getList();
    },
    getList() {
      this.config.enterpriseId = Vue.ls.get(types.enterpriseId);
      listTbOrder(this.config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let list = res.data.list;
          for (let i = 0; i < list.length; i++) {
            if (list[i].type == 1) {
              list[i].isPublisher = false;
            } else {
              list[i].isPublisher = true;
            }
          }
          this.total = res.data.total;
          this.readerList = res.data.list;
        }
      });
    },
    // 每页显示信息条数
    handleSizeChange(val) {
      this.config.size = val;
      this.getList();
    },
    changePage(newPage) {
      console.log(newPage);
      this.config.pageNum = newPage;
      this.getList();
    },
    changePages(newPage) {
      this.config.pageNum = newPage;
      this.getList2();
    },
    handleSizeChanges(val) {
      this.config.size = val;
      this.getList();
    },
    handleClick(tab) {
      this.config.pageNum = 1;
      this.config.size = 10;
      if (tab.index == 0) {
        this.config.orderStatus = 1;
      } else {
        this.config.orderStatus = 2;
      }
      this.getList();
    },
    ItemClicks(item) {
      if (!this.beforhandle("personalCenter:orderInfo:detail")) return;
      let allid;
      if (item.isPublisher) {
        allid = item.publisherId;
      } else {
        allid = item.partiesId;
      }
      console.log(item);
      this.$router.push({
        path: "/finishedDetails",
        query: {
          goodsId: item.goodsId,
          orderId: item.orderId,
          status: item.goodsStatus,
          orderType: item.orderType,
          tradeType: item.tradeType,
          tradeMode: item.tradeMode,
          companyId: allid,
          isPublisher: item.isPublisher,
        },
      });
    },
    ItemClick(item) {
      let enterpriseId = Vue.ls.get(types.enterpriseId);
      let userId = Vue.ls.get(types.userId);
      if (enterpriseId != userId && item.contractStatus == 0) {
        if (
          item.contractStatus == 0 &&
          !this.beforhandle("personalCenter:orderInfo:detail:contract", "1")
        ) {
          this.$message({
            message: "当前合同未确认，请等待合同确认完成！",
            type: "error",
          });
          return;
        }
        if (
          item.signatureStatus != null &&
          !this.beforhandle("personalCenter:orderInfo:detail:contract")
        ) {
          if (!item.signatureStatus) {
            this.$message({
              message: "当前合同未确认，请等待合同确认完成！",
              type: "error",
            });
            return;
          }
        }
      }

      let allid;
      if (item.isPublisher) {
        allid = item.publisherId;
      } else {
        allid = item.partiesId;
      }
      this.$router.push({
        path: "/purchaseOn",
        query: {
          goodsId: item.goodsId,
          orderId: item.orderId,
          status: item.goodsStatus,
          orderType: true,
          companyId: allid,
          isPublisher: item.isPublisher,
          tradeType: "2",
          deliveryMode: item.deliveryMode,
          ifInvite: item.ifInvite,
          isLogistics: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}

.header {
  max-width: 1200px;
  margin: 0 auto;
  height: 50px;
  // border: 1px solid black;
}
.order_all {
  max-width: 1200px;
  margin: 20px auto 0;
  padding: 10px;
  background: #ffffff;
  .order_top {
    display: flex;
    padding: 10px;
    border: 1px solid #ccc;
    .order_soso {
      width: 300px;
    }
    .order_options {
      margin-left: 20px;
    }
    .order_transaction {
      margin-left: 20px;
    }
    .order_btn {
      margin-left: 100px;
      width: 100px;
    }
  }
}
.item {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  .item_title {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    border-bottom: 1px dashed #ccc;
    // padding-bottom: 10px;
    .title_creatTime {
      margin-right: 10px;
      margin-top: 10px;
      color: #999;
    }
    .item_time {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      .item_nameone {
        display: inline-block;
        white-space: nowrap;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .endtime {
        margin-left: 10px;
        background: #fa9120;
        color: white;
        font-size: 14px;
        height: 30px;
        padding: 5px 10px;
        border-radius: 10px;
      }
      .endtimes {
        margin-left: 10px;
        background: #099502;
        color: white;
        font-size: 14px;
        height: 30px;
        padding: 5px 10px;
        border-radius: 10px;
      }
    }
    .title_but {
      padding: 10px 20px;
      // border: 1px solid #ccc;
      cursor: pointer;
      // background: blue;
      color: #fd6001;
    }
    .title_buts {
      padding: 10px 20px;
      // border: 1px solid #ccc;
      cursor: pointer;
      // background: blue;
      color: #139102;
    }
  }
  .item_name {
    display: flex;
    // justify-content: space-between;
    margin-top: 20px;
    .cluded {
      height: 24px;
      background: #d0111a;
      font-size: 12px;
      text-align: center;
      line-height: 24px;
      color: white;
      position: relative;
      width: 76px;
    }
    .sanjiao {
      position: absolute;
      left: 78px;
      margin-top: 3.5px;
      display: inline-block;
      width: 0px;
      height: 0px;
      border-top: solid 16.9px #d0111a;
      border-left: 16.9px solid transparent;
      transform: rotate(45deg);
    }
    .cluded_item {
      margin-left: 50px;
    }
    // :nth-child(2) {
    //   width: 300px;
    // }
    // :nth-child(3) {
    //   width: 300px;
    // }
  }
  .item_num {
    :nth-child(1) {
      width: 300px;
    }
    :nth-child(2) {
      width: 300px;
    }
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    // justify-content: space-between;
  }
  .item_btContent {
    width: 90%;
    display: flex;
    justify-content: space-between;
    .content_pl {
      .Delivery {
        margin-top: 10px;
      }
      .place {
        margin-top: 10px;
      }
    }
    .price {
      //   display: flex;
      text-align: center;
      font-size: 20px;
      .price_item {
        // margin-left: 20px;
        display: flex;
        justify-content: center;
        // margin-top: -30px;
        text-align: center;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        :nth-child(2) {
          // margin-top: -15px;
          // font-size: 40px;
          color: #d0111a;
        }
      }
    }
  }
}
.clyede_all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cluded_item {
  margin-top: 10px;
}
</style>
