<template>
  <div class="width_center">
    <div class="go_0" @click="$router.go(-1)">
      <i class="hy-icon-fanhui" style="color: #4e6ef2;font-size:18px"></i>
      返回
    </div>
    <div class="title">{{inner.messageContent}}</div>
    <div class="data"> <i class="hy-icon-shijian1"></i> {{inner.createTime}}</div>
    <div class="img"  v-if="inner.articleContent">
      <!-- <img 
        style="width: 100%; height: 100%"
        :src="baseUrl+'third/third/ossGetFile/'+inner.messageImg"
      /> -->
      <div v-html="inner.articleContent" class="articlContet"></div>
      <div class="content_all">
          <div v-for="(item,index) in inner.fileList" :key="index">
           <!-- <el-button style="height:60px;line-height: 30px;" @click="examinecontract(item.storageName)">点击下载文件({{item.originalName}}) -->
            <div class="fileListImg">
                  <img
                    src="../../assets/img/pdf.png"
                    alt=""
                    v-if="
                      item.storageName.slice(-4) == '.pdf' ||
                      item.storageName.slice(-4) == '.PDF'
                    "
                  />
                  <img
                    src="../../assets/img/word.png"
                    alt=""
                    v-if="
                      item.storageName.slice(-4) == '.doc' ||
                      item.storageName.slice(-5) == '.docx'
                    "
                  />
                  <a
                    :href="
                      baseUrl + 'third/third/ossGetFile/' + item.storageName
                    "
                    target="_blank"
                    >{{ item.originalName }}</a
                  >
                </div>
				</el-button>
      </div>

      </div>
    </div>
    <div v-else class="img" >
      <img
        style="width: 100%; height: 100%"
        :src="baseUrl+'third/third/ossGetFile/'+inner.messageImg"
      />
    </div>
  </div>
</template>

<script>
import { biddingdetail } from "@/api/home";
const { baseUrl } = require("@/config");
export default {
  created() {
    this.type = this.$route.query.type;
    this.messageId = this.$route.query.messageId;
    this.getinner();
  },
  data() {
    return {
      baseUrl: baseUrl,
      inner: {},
      messageId: "",
      type: "",
    };
  },
  methods: {
    examinecontract(item) {
      let url;
      if (item == 1) {
        url = this.baseUrl + "third/third/ossGetFile/" + "竞价声明函.docx";
      } else if (item == 2) {
        url =
          this.baseUrl +
          "third/third/ossGetFile/" +
          "法定代表人授权书格式.docx";
      } else if (item == 3) {
        url =
          this.baseUrl +
          "third/third/ossGetFile/" +
          "新疆能源（集团）哈密煤炭运销有限公司.docx";
      } else if (item == 4) {
        url = this.baseUrl + "third/third/ossGetFile/" + "竞价文件清单.docx";
      }
      window.open(url);
    },
    getinner() {
      biddingdetail({ messageId: this.messageId }).then((res) => {
        if (res.code == 0) {
          this.inner = res.data;
          console.log(res.data);
          this.inner.createTime = res.data.createTime.replace("T", " ");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.go_0 {
  position: relative;
  top: 20px;
  left: 20px;
  text-align: center;
  line-height: 30px;
  width: 90px;
  height: 34px;
  border: 1px solid #d2d2d2;
  border-radius: 17px;
  font-size: 14px;
  cursor:pointer;
}
.width_center {
  width: 1200px;
  margin: 0 auto;
  background: #ffffff;
}
::v-deep .articlContet {
  width: 70%;
  margin: 0 auto;
  img {
    display: block;
    margin: 0 auto;
  }
}
.img {
  min-height: 500px;
}
.title {
  padding-top: 29px;
  height: 60px;
  font-size: 30px;
  font-weight: 500;
  color: #000000;
  line-height: 35px;
  text-align: center;
}
.data {
  margin-top: 27px;
  height: 35px;
  line-height: 35px;
  background: #f8f8f8;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
.content_all {
  margin: 20px auto;
  background: #f9f9f9;
  width: 70%;
  border: 1px dashed #d2d2d2;
  border-radius: 4px;
}
.fileListImg {
  // text-align: center;
  padding: 10px;
  display: flex;

  // justify-content: center;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
}
</style>