var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"0 auto","width":"800px"}},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"120px"}},[_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"交易类型","prop":"goodsInfo.orderType"}},[_c('el-select',{attrs:{"placeholder":"请选择类型"},on:{"change":_vm.handleChangeOrderType},model:{value:(_vm.ruleForm.goodsInfo.orderType),callback:function ($$v) {_vm.$set(_vm.ruleForm.goodsInfo, "orderType", $$v)},expression:"ruleForm.goodsInfo.orderType"}},_vm._l((_vm.orderTypeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"商品总量(吨)","prop":"goodsInfo.initialCount","label-width":"200px"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-input',{attrs:{"placeholder":"请输入商品数量","disabled":_vm.checked},model:{value:(_vm.ruleForm.goodsInfo.initialCount),callback:function ($$v) {_vm.$set(_vm.ruleForm.goodsInfo, "initialCount", $$v)},expression:"ruleForm.goodsInfo.initialCount"}}),_c('el-checkbox-group',{staticStyle:{"margin-left":"20px"},on:{"change":_vm.checkChange},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_c('el-checkbox',{attrs:{"label":"以实际结算数量为准"}})],1)],1)])],1),_vm._l((_vm.ruleForm.goodsCategoryList),function(goods,index){return _c('div',{key:goods.key,staticClass:"flex",staticStyle:{"align-items":"flex-start"}},[_c('el-form-item',{attrs:{"label":"商品类型","prop":'goodsCategoryList.' + index + '.goodsType',"rules":{
          required: true,
          message: '请选择商品类型',
          trigger: 'change',
        }}},[_c('el-select',{ref:"areaNames",refInFor:true,attrs:{"placeholder":"请选择商品类型"},on:{"change":function($event){return _vm.goodsChange($event, goods)}},model:{value:(_vm.ruleForm.goodsCategoryList[index].goodsType),callback:function ($$v) {_vm.$set(_vm.ruleForm.goodsCategoryList[index], "goodsType", $$v)},expression:"ruleForm.goodsCategoryList[index].goodsType"}},_vm._l((_vm.nameList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.dictName,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"商品名称","prop":'goodsCategoryList.' + index + '.goodsName',"rules":{
          required: true,
          message: '请输入商品名称',
          trigger: 'blur',
        }}},[_c('el-input',{attrs:{"placeholder":"请输入商品名称"},model:{value:(_vm.ruleForm.goodsCategoryList[index].goodsName),callback:function ($$v) {_vm.$set(_vm.ruleForm.goodsCategoryList[index], "goodsName", $$v)},expression:"ruleForm.goodsCategoryList[index].goodsName"}})],1),_c('el-form-item',{attrs:{"label":"价格(元)","prop":'goodsCategoryList.' + index + '.goodsPrice',"rules":{
          required: true,
          message: '请输入价格',
          trigger: 'blur',
        }}},[_c('el-input',{attrs:{"placeholder":"请输入价格"},model:{value:(_vm.ruleForm.goodsCategoryList[index].goodsPrice),callback:function ($$v) {_vm.$set(_vm.ruleForm.goodsCategoryList[index], "goodsPrice", $$v)},expression:"ruleForm.goodsCategoryList[index].goodsPrice"}})],1),(goods.key == 0 && _vm.ruleForm.goodsInfo.orderType == 1)?_c('el-button',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.addGoodes()}}},[_vm._v("增加")]):_vm._e(),(goods.key != 0 && _vm.ruleForm.goodsInfo.orderType == 1)?_c('el-button',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.delGoods(goods)}}},[_vm._v("删除")]):_vm._e()],1)}),_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"价格类型","prop":"goodsInfo.taxIncluded"}},[_c('div',{staticClass:"pirceType",staticStyle:{"display":"flex"}},[_c('el-select',{attrs:{"placeholder":"请选择类型"},model:{value:(_vm.ruleForm.goodsInfo.taxIncluded),callback:function ($$v) {_vm.$set(_vm.ruleForm.goodsInfo, "taxIncluded", $$v)},expression:"ruleForm.goodsInfo.taxIncluded"}},_vm._l((_vm.taxIncludedList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-select',{attrs:{"placeholder":"请选择类型"},model:{value:(_vm.ruleForm.goodsInfo.priceType),callback:function ($$v) {_vm.$set(_vm.ruleForm.goodsInfo, "priceType", $$v)},expression:"ruleForm.goodsInfo.priceType"}},_vm._l((_vm.priceTypeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_c('el-form-item',{attrs:{"label":"配送方式","prop":"goodsInfo.deliveryMode"}},[_c('el-select',{attrs:{"placeholder":"请选择类型"},model:{value:(_vm.ruleForm.goodsInfo.deliveryMode),callback:function ($$v) {_vm.$set(_vm.ruleForm.goodsInfo, "deliveryMode", $$v)},expression:"ruleForm.goodsInfo.deliveryMode"}},_vm._l((_vm.ruleForm.goodsInfo.orderType == 1
              ? _vm.deliveryModeList1
              : _vm.deliveryModeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_vm._l((_vm.ruleForm.goodsCategoryList),function(goods,goodsIndex){return _c('div',{key:'goods' + goods.key},[(goods.quotaList.length > 0)?_c('div',{staticClass:"quotaList-box"},[_c('h3',[_vm._v(_vm._s(goods.dictName)+"商品指标")]),_c('div',{staticClass:"list"},_vm._l((goods.quotaList),function(item,index){return _c('div',{key:item.id,staticClass:"quota-item"},[_c('el-form-item',{attrs:{"label":item.dictName,"prop":'goodsCategoryList.' +
                goodsIndex +
                '.quotaList.' +
                index +
                '.quotaValue',"rules":{
                required: item.ifMust == 0,
                message: item.dictName + '不能为空',
                trigger: 'blur',
              }}},[_c('el-input',{attrs:{"placeholder":`请输入${item.dictName}`},on:{"input":function($event){return _vm.formatFloat(goodsIndex, index, 'quotaValue')}},model:{value:(item.quotaValue),callback:function ($$v) {_vm.$set(item, "quotaValue", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.quotaValue"}},[(item.dictValue)?_c('template',{slot:"append"},[_vm._v(_vm._s(item.dictValue))]):_vm._e()],2)],1)],1)}),0)]):_vm._e()])}),_c('div',{staticClass:"flex"}),_c('div',{staticClass:"keyType"},[_c('h3',[_vm._v("请选择字段是否展示给其他用户")]),_c('div',{staticClass:"key-group"},[_c('el-checkbox',{model:{value:(_vm.viewSet.viewPrice),callback:function ($$v) {_vm.$set(_vm.viewSet, "viewPrice", $$v)},expression:"viewSet.viewPrice"}},[_vm._v("价格")]),_c('el-checkbox',{model:{value:(_vm.viewSet.viewCount),callback:function ($$v) {_vm.$set(_vm.viewSet, "viewCount", $$v)},expression:"viewSet.viewCount"}},[_vm._v("数量")]),_c('el-checkbox',{model:{value:(_vm.viewSet.viewInviteEnterprise),callback:function ($$v) {_vm.$set(_vm.viewSet, "viewInviteEnterprise", $$v)},expression:"viewSet.viewInviteEnterprise"}},[_vm._v("被邀约方企业名称")])],1)]),_c('div',{staticStyle:{"text-align":"center","margin-top":"20px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.nextStep('ruleForm')}}},[_vm._v("下一步")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }