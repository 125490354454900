<template>
  <div>
    <div class="order_all">
      <div class="order_top">
        <el-input
          v-model="config.keyword"
          placeholder="请输入商品名称/商品编号"
          class="order_soso"
          clearable
          maxlength="20"
        ></el-input>
        <el-select
          v-model="config.orderType"
          placeholder="请选择订单类型"
          class="order_options"
          clearable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="config.tradeType"
          placeholder="请选择交易类型"
          class="order_transaction"
          clearable
        >
          <el-option
            v-for="item in transaction"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="config.tradeMode"
          placeholder="请选择交易发起方式"
          class="order_transaction"
          clearable
        >
          <el-option
            v-for="item in tradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" class="order_btn" @click="searchClick"
          >搜索</el-button
        >
        <el-button
          style="width: 100px"
          type="info"
          plain
          @click="eliminateClick"
          >清除</el-button
        >
      </div>
      <div>
        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
          <el-tab-pane label="进行中" name="first">
            <div
              class="item"
              @click="ItemClick(item)"
              v-for="(item, index) in readerList"
              :key="index"
            >
              <div class="item_title">
                <div class="item_time">
                  <div class="item_nameone">
                    <div class="enterpriseName">
                      发布方:
                      <div :title="item.publisherName">
                        {{ item.publisherName }}
                      </div>
                    </div>
                    &nbsp; &nbsp; &nbsp;
                    <div class="partiesName">
                      参与方:
                      <div :title="item.partiesName">
                        {{ item.partiesName }}
                      </div>
                    </div>
                  </div>
                  <div class="item-tag">
                    <div
                      :class="item.orderType == '销售' ? 'endtime' : 'endtimes'"
                    >
                      {{ item.tradeType + item.orderType }}
                    </div>

                    <div
                      v-if="item.ifInvite == 1"
                      style="font-weight: 400; font-size: 14px"
                      :class="item.orderType == '销售' ? 'endtime' : 'endtimes'"
                    >
                      邀约交易
                    </div>
                    <div
                      v-if="item.ifInvite == 2"
                      style="font-weight: 400; font-size: 14px"
                      :class="item.orderType == '销售' ? 'endtime' : 'endtimes'"
                    >
                      拼团交易
                    </div>
                  </div>
                </div>
                <div style="display: flex">
                  <i
                    class="hy-icon-shijian1"
                    style="margin-top: 10px; margin-right: 10px"
                  ></i>
                  <div class="title_creatTime">
                    {{ item.createTime }}
                  </div>
                  <div class="title_but" v-if="!item.isPublisher">我参与的</div>
                  <div class="title_buts" v-if="item.isPublisher">我发布的</div>
                </div>
              </div>
              <div class="item_name">
                <div class="cluded">
                  <span v-if="item.taxIncluded == 0">含税</span>
                  <span v-if="item.taxIncluded == 1">不含税</span>
                  <span v-if="item.priceType == 0">未知</span>
                  <span v-if="item.priceType == 1">坑口价</span>
                  <span v-if="item.priceType == 2">出厂价</span>
                  <span v-if="item.priceType == 3">到港价</span>
                  <span v-if="item.priceType == 4">平仓价</span>
                  <span v-if="item.priceType == 5">车板价</span>
                  <span v-if="item.priceType == 6">到厂价</span>
                </div>
                <span class="sanjiao"></span>
                <div class="cluded_item" v-if="!isListingSales(item)">
                  商品编号：<span>{{ item.goodsNum }}</span>
                </div>
                <div
                  class="cluded_item order-list-goods-name"
                  :title="item.goodsName"
                >
                  商品名称：<span>{{ item.goodsName }}</span>
                </div>
                <div
                  class="cluded_item"
                  v-if="
                    item.tradeType == '挂牌' &&
                    item.orderCategoryList?.length == 1
                  "
                >
                  价格：<span>
                    <span style="color: #d0111a">￥</span>
                    <span style="color: #d0111a; font-size: 20px">{{
                      item.orderCategoryList[0].goodsPrice == 0
                        ? "面议"
                        : item.orderCategoryList[0].goodsPrice
                    }}</span>
                    <span v-if="item.orderCategoryList[0].goodsPrice != 0"
                      >元/吨</span
                    ></span
                  >
                </div>
                <div class="cluded_item" v-if="item.tradeType != '挂牌'">
                  价格：<span>
                    <span style="color: #d0111a">￥</span>
                    <span style="color: #d0111a; font-size: 20px">{{
                      item.goodsCategoryList[0].goodsPrice == 0
                        ? "面议"
                        : item.goodsCategoryList[0].goodsPrice
                    }}</span>
                    <span v-if="item.goodsCategoryList[0].goodsPrice != 0"
                      >元/吨</span
                    ></span
                  >
                </div>
                <div
                  class="cluded_item"
                  v-if="
                    item.ifInvite != 1 ||
                    (item.ifInvite == 1 &&
                      item.tradeType + item.orderType == '挂牌销售')
                  "
                >
                  订单数量：<span
                    style="color: #d0111a; font-size: 20px"
                    v-if="item.tradeType == '竞价'"
                    >{{
                      item.ifConsultRealityCount == 0
                        ? item.initialCount
                        : "以实际结算数量为准"
                    }}</span
                  >
                  <span style="color: #d0111a; font-size: 20px" v-else>{{
                    item.ifInvite != 1
                      ? item.orderCount
                      : item.ifConsultRealityCount == 0
                      ? item.initialCount
                      : "以实际结算数量为准"
                  }}</span>
                  <span
                    v-if="
                      item.ifInvite != 1 ||
                      (item.ifInvite == 1 && item.ifConsultRealityCount == 0)
                    "
                    >吨</span
                  >
                </div>
                <div
                  class="cluded_item"
                  style="margin-top: 3px"
                  v-if="
                    item.ifInvite == 1 ||
                    item.tradeType + item.orderType == '挂牌销售'
                  "
                >
                  配送方式：<span>{{ formatDeliveryMode(item) }}</span>
                </div>
              </div>
              <div class="item_num"></div>
              <div
                style="
                  display: flex;
                  align-items: flex-end;
                  width: 100%;
                  justify-content: space-between;
                "
              >
                <div
                  class="item_btContent"
                  v-if="item.ifInvite == 0 || item.ifInvite == 2"
                >
                  <div class="content_pl">
                    <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
                    <div class="place">产地：{{ item.originPlace }}</div>
                  </div>
                  <div class="price" v-if="item.tradeType == '竞价'">
                    <div class="price_item">
                      <div>中标价：</div>
                      <span style="color: #d0111a">￥</span>
                      <div
                        v-for="(goods, i) in item.goodsCategoryList"
                        :key="i"
                      >
                        <span style="font-size: 30px; color: #d0111a">{{
                          goods.dealPrice
                        }}</span>
                        <span>/吨</span>
                        <span v-if="i != item.goodsCategoryList.length - 1"
                          >丨</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else></div>
                <div class="finish-btn1">
                  <span v-if="item.ifViewConfirmComplete == 1"
                    >等待对方操作完成</span
                  >
                  <el-button
                    type="primary"
                    v-if="item.ifViewConfirmComplete == 2"
                    @click.stop="handleFinishOrder(item)"
                    >确认订单完成</el-button
                  >
                </div>
              </div>

              <div></div>
            </div>
            <!-- 分页 -->
            <el-row
              type="flex"
              justify="end"
              style="height: 50px"
              align="middle"
            >
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :current-page="config.page"
                :page-size="config.size"
                :page-sizes="[10, 20, 50]"
                @current-change="changePage"
                @size-change="handleSizeChange"
                :pagerCount="5"
              />
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="已完成" name="second">
            <div
              class="item"
              @click="ItemClicks(item)"
              v-for="(item, index) in readerList"
              :key="index"
            >
              <div class="item_title">
                <div class="item_time">
                  <div class="item_nameone">
                    <div class="enterpriseName">
                      发布方:
                      <div :title="item.publisherName">
                        {{ item.publisherName }}
                      </div>
                    </div>
                    &nbsp; &nbsp; &nbsp;
                    <div class="partiesName">
                      参与方:
                      <div :title="item.partiesName">
                        {{ item.partiesName }}
                      </div>
                    </div>
                  </div>
                  <div class="item-tag">
                    <div
                      :class="item.orderType == '销售' ? 'endtime' : 'endtimes'"
                    >
                      {{ item.tradeType + item.orderType }}
                    </div>
                    <div
                      v-if="item.ifInvite == 1"
                      style="font-weight: 400; font-size: 14px"
                      :class="item.orderType == '销售' ? 'endtime' : 'endtimes'"
                    >
                      邀约交易
                    </div>
                    <div
                      v-if="item.ifInvite == 2"
                      style="font-weight: 400; font-size: 14px"
                      :class="item.orderType == '销售' ? 'endtime' : 'endtimes'"
                    >
                      拼团交易
                    </div>
                  </div>
                </div>
                <div style="display: flex">
                  <i
                    class="hy-icon-shijian1"
                    style="margin-top: 10px; margin-right: 10px"
                  ></i>
                  <div class="title_creatTime">
                    {{ item.createTime }}
                  </div>
                  <div class="title_but" v-if="!item.isPublisher">参与方</div>
                  <div class="title_buts" v-if="item.isPublisher">发布方</div>
                </div>
              </div>
              <div class="item_name">
                <div class="cluded">
                  <span v-if="item.taxIncluded == 0">含税</span>
                  <span v-if="item.taxIncluded == 1">不含税</span>
                  <span v-if="item.priceType == 0">未知</span>
                  <span v-if="item.priceType == 1">坑口价</span>
                  <span v-if="item.priceType == 2">出厂价</span>
                  <span v-if="item.priceType == 3">到港价</span>
                  <span v-if="item.priceType == 4">平仓价</span>
                  <span v-if="item.priceType == 5">车板价</span>
                  <span v-if="item.priceType == 6">到厂价</span>
                </div>
                <span class="sanjiao"></span>
                <div class="cluded_item" v-if="!isListingSales(item)">
                  商品编号：<span>{{ item.goodsNum }}</span>
                </div>
                <div class="cluded_item" :title="item.goodsName">
                  商品名称：<span>{{ item.goodsName }}</span>
                </div>
                <div
                  class="cluded_item"
                  v-if="
                    item.tradeType == '挂牌' &&
                    item.orderCategoryList?.length == 1
                  "
                >
                  价格：<span>
                    <span style="color: #d0111a">￥</span>
                    <span style="color: #d0111a; font-size: 20px">{{
                      item.orderCategoryList[0].goodsPrice == 0
                        ? "面议"
                        : item.orderCategoryList[0].goodsPrice
                    }}</span>
                    <span v-if="item.orderCategoryList[0].goodsPrice != 0"
                      >元/吨</span
                    ></span
                  >
                </div>
                <div class="cluded_item" v-if="item.tradeType != '挂牌'">
                  价格：<span>
                    <span style="color: #d0111a">￥</span>
                    <span style="color: #d0111a; font-size: 20px">{{
                      item.goodsCategoryList[0].goodsPrice == 0
                        ? "面议"
                        : item.goodsCategoryList[0].goodsPrice
                    }}</span>
                    <span v-if="item.goodsCategoryList[0].goodsPrice != 0"
                      >元/吨</span
                    ></span
                  >
                </div>
                <div
                  class="cluded_item"
                  v-if="
                    item.ifInvite != 1 ||
                    (item.ifInvite == 1 &&
                      item.tradeType + item.orderType == '挂牌销售')
                  "
                >
                  订单数量：<span
                    style="color: #d0111a; font-size: 20px"
                    v-if="item.tradeType == '竞价'"
                    >{{
                      item.ifConsultRealityCount == 0
                        ? item.initialCount
                        : "以实际结算数量为准"
                    }}</span
                  >
                  <span style="color: #d0111a; font-size: 20px" v-else>{{
                    item.ifInvite != 1
                      ? item.orderCount
                      : item.ifConsultRealityCount == 0
                      ? item.initialCount
                      : "以实际结算数量为准"
                  }}</span>
                  <span
                    v-if="
                      item.ifInvite != 1 ||
                      (item.ifInvite == 1 && item.ifConsultRealityCount == 0)
                    "
                    >吨</span
                  >
                </div>
                <div
                  class="cluded_item"
                  style="margin-top: 3px"
                  v-if="
                    item.ifInvite == 1 ||
                    item.tradeType + item.orderType == '挂牌销售'
                  "
                >
                  配送方式：<span>{{ formatDeliveryMode(item) }}</span>
                </div>
                <!-- <div
                  class="cluded_item"
                  style="margin-top: 3px"
                  v-if="item.ifInvite == 1"
                >
                  配送方式：<span style="">{{
                    item.deliveryMode == 1 ? "送货" : "自提"
                  }}</span>
                </div> -->
              </div>
              <div class="item_num"></div>
              <div class="item_btContent" v-if="item.ifInvite != 1">
                <div class="content_pl">
                  <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
                  <div class="place">产地：{{ item.originPlace }}</div>
                </div>
                <div class="price" v-if="item.tradeType == '竞价'">
                  <div class="price_item">
                    <div>中标价：</div>
                    <span style="color: #d0111a">￥</span>
                    <div v-for="(goods, i) in item.goodsCategoryList" :key="i">
                      <span style="font-size: 30px; color: #d0111a">{{
                        goods.dealPrice
                      }}</span>
                      <span>/吨</span>
                      <span v-if="i != item.goodsCategoryList.length - 1"
                        >丨</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 分页 -->
            <el-row
              type="flex"
              justify="end"
              style="height: 50px"
              align="middle"
            >
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :current-page="config.page"
                :page-size="config.size"
                :page-sizes="[10, 20, 50]"
                @current-change="changePage"
                @size-change="handleSizeChange"
                :pagerCount="5"
              />
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { tradeOrder, finish } from "@/api/public";
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  data() {
    return {
      total: 0,
      activeName: "first",
      options: [
        {
          value: "1",
          label: "销售",
        },
        {
          value: "2",
          label: "采购",
        },
      ],
      transaction: [
        {
          value: "2",
          label: "竞价",
        },
        {
          value: "1",
          label: "挂牌",
        },
      ],
      tradeOptions: [
        {
          value: "0",
          label: "市场化交易",
        },
        {
          value: "1",
          label: "邀约交易",
        },
        // {
        //   value: "2",
        //   label: "拼团交易",
        // },
      ],
      config: {
        keyword: "",
        orderType: "",
        tradeType: "",
        tradeMode: "",
        page: 1,
        size: 10,
        total: 0,
        orderStatus: 1,
      },

      readerList: [],
      enterpriseId: Vue.ls.get(types.enterpriseId),
      userId: Vue.ls.get(types.userId),
    };
  },
  computed: {
    isListingSales() {
      return (obj) => {
        return obj.tradeType + obj.orderType == "挂牌销售";
      };
    },
    formatDeliveryMode() {
      return (val) => {
        let arr = ["", "送货", "自提"];
        if (val.tradeType == "挂牌" && val.orderType == "销售") {
          return arr[val.deliveryMode] + "(以实际拉运方式为准)";
        } else {
          return arr[val.deliveryMode];
        }
      };
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    traderFinish(config) {
      finish(config).then((res) => {
        this.$message({
          message: "合同整合完成",
          type: "success",
        });
        this.getList();
      });
    },
    // 处理完成订单
    handleFinishOrder(order) {
      let config = {
        orderId: order.orderId,
      };
      finish(config).then((res) => {
        switch (res.code) {
          case -1:
            this.$confirm(res.message, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.traderFinish({ ...config, confirm: true });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
            break;
          case 0:
            this.traderFinish({ ...config, confirm: true });
            break;

          case 1:
            this.$message({
              message: res.message,
              type: "error",
            });
            break;
        }
      });
    },
    searchClick() {
      this.config.page = 1;
      this.getList();
    },
    eliminateClick() {
      this.config.keyword = "";
      this.config.orderType = "";
      this.config.tradeType = "";
      this.config.tradeMode = "";
    },
    getList() {
      tradeOrder(this.config).then((res) => {
        if (res.code == 0) {
          let list = res.data.records;
          list.forEach((item) => {
            if (item.orderType == 1) {
              item.orderType = "销售";
            } else if (item.orderType == 2) {
              item.orderType = "采购";
            } else {
              item.orderType = "未知";
            }

            if (item.tradeType == 1) {
              item.tradeType = "挂牌";
            } else if (item.tradeType == 2) {
              item.tradeType = "竞价";
            } else {
              item.tradeType = "未知";
            }
          });
          this.total = res.data.total;
          this.readerList = list;
          console.log(this.readerList);
          this.readerList.forEach((item) => {
            if (item.tradeType == "挂牌") {
              let goodsName = "";
              item.goodsCategoryList.forEach((good, index) => {
                goodsName +=
                  good.goodsName +
                  (index == item.goodsCategoryList.length - 1 ? "" : ",");
              });
              item.goodsName = goodsName;
            }
          });
          console.log(list);
        }
      });
    },
    // 每页显示信息条数
    handleSizeChange(val) {
      this.config.size = val;
      this.getList();
    },
    changePage(newPage) {
      this.config.page = newPage;
      this.getList();
    },
    handleClick(tab) {
      this.config.page = 1;
      this.config.size = 10;
      this.readerList = [];
      if (tab.index == 0) {
        this.config.orderStatus = 1;
      } else {
        this.config.orderStatus = 2;
      }
      this.getList();
    },
    ItemClicks(item) {
      if (!this.beforhandle("personalCenter:orderInfo:detail")) return;
      let allid;
      if (item.isPublisher) {
        allid = item.publisherId;
      } else {
        allid = item.partiesId;
      }
      console.log(item);
      this.$router.push({
        path: "/finishedDetails",
        query: {
          orderId: item.orderId,
          status: item.goodsStatus,
          orderType: item.orderType,
          tradeType: item.tradeType,
          tradeMode: item.tradeMode,
          companyId: allid,
          isPublisher: item.isPublisher,
        },
      });
    },
    ItemClick(item) {
      let enterpriseId = Vue.ls.get(types.enterpriseId);
      let userId = Vue.ls.get(types.userId);
      // if (!this.beforhandle("personalCenter:orderInfo:detail:contract")) return;
      if (enterpriseId != userId && item.contractStatus == 0) {
        if (
          item.contractStatus == 0 &&
          !this.beforhandle("personalCenter:orderInfo:detail:contract", "1")
        ) {
          this.$message({
            message: "当前合同未确认，请等待合同确认完成！",
            type: "error",
          });
          return;
        }
        if (
          item.signatureStatus != null &&
          !this.beforhandle("personalCenter:orderInfo:detail:contract")
        ) {
          if (!item.signatureStatus) {
            this.$message({
              message: "当前合同未确认，请等待合同确认完成！",
              type: "error",
            });
            return;
          }
        }
      }

      let allid;
      if (item.isPublisher) {
        allid = item.publisherId;
      } else {
        allid = item.partiesId;
      }
      let tradeType;
      if (item.tradeType == "挂牌") {
        tradeType = 1;
      } else {
        tradeType = 2;
      }
      if (item.orderType == "销售") {
        this.$router.push({
          path: "/orderDetails",
          query: {
            orderId: item.orderId,
            status: item.goodsStatus,
            isPublisher: item.isPublisher,
            companyId: allid,
            tradeType: tradeType,
            deliveryMode: item.deliveryMode,
            ifInvite: item.ifInvite,
          },
        });
      } else if (item.orderType == "采购") {
        this.$router.push({
          path: "/purchaseOrder",
          query: {
            orderId: item.orderId,
            status: item.goodsStatus,
            orderType: true,
            companyId: allid,
            isPublisher: item.isPublisher,
            tradeType: tradeType,
            deliveryMode: item.deliveryMode,
            ifInvite: item.ifInvite,
          },
        });
      }

      // this.$router.push("/orderDetails")
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes titlescroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}

.header {
  max-width: 1200px;
  margin: 0 auto;
  height: 50px;
  // border: 1px solid black;
}
.order_all {
  max-width: 1200px;
  margin: 20px auto 0;
  padding: 10px;
  background: #ffffff;
  .order_top {
    display: flex;
    padding: 10px;
    border: 1px solid #ccc;
    .order_soso {
      width: 300px;
    }
    .order_options {
      margin-left: 20px;
    }
    .order_transaction {
      margin-left: 20px;
    }
    .order_btn {
      margin-left: 100px;
      width: 100px;
    }
  }
}
.item {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
  .item_title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
    // padding-bottom: 10px;
    .title_creatTime {
      margin-right: 10px;
      margin-top: 10px;
      color: #999;
    }
    .item_time {
      font-size: 20px;
      font-weight: bold;
      .item_nameone {
        width: 580px;
        white-space: nowrap;
        .enterpriseName,
        .partiesName {
          width: 50%;
          display: inline-block;
          & > div {
            display: inline-block;
            width: 230px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: top;
          }
        }
      }
      .item-tag {
        display: flex;
        padding-bottom: 10px;
        .endtime {
          margin-left: 10px;
          background: #fa9120;
          color: white;
          font-size: 14px;
          height: 30px;
          padding: 5px 10px;
          border-radius: 10px;
          &:first-child {
            margin-left: 0;
          }
        }
        .endtimes {
          margin-left: 10px;
          background: #099502;
          color: white;
          font-size: 14px;
          height: 30px;
          padding: 5px 10px;
          border-radius: 10px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    .title_but {
      padding: 10px 20px;
      // border: 1px solid #ccc;
      cursor: pointer;
      // background: blue;
      color: #fd6001;
    }
    .title_buts {
      padding: 10px 20px;
      // border: 1px solid #ccc;
      cursor: pointer;
      // background: blue;
      color: #139102;
    }
  }
  .item_name {
    display: flex;
    // justify-content: space-between;
    margin-top: 20px;
    .cluded {
      height: 24px;
      background: #d0111a;
      font-size: 12px;
      text-align: center;
      line-height: 24px;
      color: white;
      position: relative;
      width: 76px;
    }
    .sanjiao {
      position: absolute;
      left: 78px;
      margin-top: 3.5px;
      display: inline-block;
      width: 0px;
      height: 0px;
      border-top: solid 16.9px #d0111a;
      border-left: 16.9px solid transparent;
      transform: rotate(45deg);
    }
    .cluded_item {
      margin-left: 50px;
    }

    .order-list-goods-name {
      margin-left: 30px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 180px;
    }
    // :nth-child(2) {
    //   width: 300px;
    // }
    // :nth-child(3) {
    //   width: 300px;
    // }
  }
  .item_num {
    :nth-child(1) {
      width: 300px;
    }
    :nth-child(2) {
      width: 300px;
    }
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    // justify-content: space-between;
  }

  .item_btContent {
    width: 80%;
    display: flex;
    justify-content: space-between;
    .content_pl {
      .Delivery {
        margin-top: 10px;
      }
      .place {
        margin-top: 10px;
      }
    }
    .price {
      //   display: flex;
      text-align: center;
      font-size: 20px;
      .price_item {
        // margin-left: 20px;
        display: flex;
        justify-content: center;
        // margin-top: -30px;
        text-align: center;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        :nth-child(2) {
          // margin-top: -15px;
          // font-size: 40px;
          color: #d0111a;
        }
      }
    }
  }
  .finish-btn {
    position: absolute;
    right: 30px;
    bottom: 10px;
  }
}
</style>
