<template>
  <div class="authentication">
    <div class="state-box" v-if="!notSubmitted">
      <span>{{ formData.auditStatus | auditStatusFileter }}</span>
    </div>
    <el-form ref="form" :model="formData" :rules="rules" label-width="40px">
      <div class="group">
        <div class="top">
          <div class="lines">
            <el-form-item
              label-width="80px"
              label="企业类型"
              prop="companyTypes"
            >
              <el-checkbox-group
                v-model="formData.companyTypes"
                :disabled="!notSubmitted"
                @change="companyTypesChange"
              >
                <el-checkbox
                  v-for="item in firmtypeList"
                  :label="item.dictId"
                  :key="item.dictId"
                  >{{ item.dictValue }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div class="upload-box">
            <el-form-item prop="idcardBackUrl" label-width="0">
              <MyUpLoad
                v-model="formData.idcardBackUrl"
                name="上传法人身份证人像面"
                type="2"
                validate="idcardBackUrl"
                @ocrIdcardCertificate="changeIdcard"
                :disabled="!notSubmitted"
              />
            </el-form-item>
            <el-form-item prop="idcardFrontUrl" label-width="0">
              <MyUpLoad
                v-model="formData.idcardFrontUrl"
                name="上传法人身份证国徽面"
                type="1"
                @idcardCertificate="changeIdcards"
                validate="idcardFrontUrl"
                :disabled="!notSubmitted"
              />
            </el-form-item>
            <el-form-item prop="businessLicenseUrl" label-width="0">
              <MyUpLoad
                v-model="formData.businessLicenseUrl"
                type="3"
                name="上传营业执照"
                validate="businessLicenseUrl"
                @tradingCertificate="changeTrading"
                :disabled="!notSubmitted"
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="roadTransportLicenseUrl" label-width="0">
              <MyUpLoad
                v-show="isRoadTransportLicense"
                v-model="formData.roadTransportLicenseUrl"
                type="4"
                name="上传运输许可证"
                validate="roadTransportLicenseUrl"
                @tradingCertificate="changeTradings"
                :disabled="!notSubmitted"
              />
            </el-form-item>
          </div>
          <!-- <div class="entrust">
            <div class="top">
              <p>委托书模板</p>
              <span>预览</span>
              <span>|</span>
              <span class="download">下载</span>
            </div>
            <el-form-item prop="attorneyPowerUrl" label-width="0">
              <el-upload
                action=""
                :before-upload="beforeUpload"
                v-if="!formData.attorneyPowerUrl || !disabled"
              >
                <el-button size="small" type="primary" class="hy-btn"
                  >点击上传委托书</el-button
                >
              </el-upload>
              <div v-if="formData.attorneyPowerUrl && notSubmitted">
                <span>{{ attorneyPowerName }}</span>
                <span @click="formData.attorneyPowerUrl = ''" class="remove"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </el-form-item>
          </div> -->
        </div>
      </div>
      <div class="title">认证信息</div>
      <div class="group">
        <div class="line">
          <el-form-item label=" " prop="companyName">
            <el-input
              v-model.trim="formData.companyName"
              placeholder="请输入企业名称"
              maxlength="64"
              prefix-icon="hy-icon-yinhang"
              :disabled="!notSubmitted"
            ></el-input>
          </el-form-item>
          <el-form-item label=" " prop="legalName">
            <el-input
              v-model.trim="formData.legalName"
              placeholder="请输入法人名称"
              maxlength="20"
              prefix-icon="hy-icon-shenfenzheng"
              :disabled="!notSubmitted"
            ></el-input>
          </el-form-item>
        </div>
        <div class="line">
          <el-form-item label=" " prop="legalIdcard">
            <el-input
              v-model="formData.legalIdcard"
              placeholder="请输入法人身份证号"
              prefix-icon="hy-icon-shenfenzheng"
              maxlength="18"
              :disabled="!notSubmitted"
            ></el-input>
          </el-form-item>
          <el-form-item label=" " prop="socialCode">
            <el-input
              v-model="formData.socialCode"
              placeholder="请输入统一信用代码"
              prefix-icon="hy-icon-yinhang"
              maxlength="30"
              :disabled="!notSubmitted"
            ></el-input>
          </el-form-item>
        </div>
        <div class="line">
          <el-form-item
            label=" "
            prop="idcardValidityTerm"
            style="position: relative"
          >
            <span style="position: absolute; left: -21px; top: 3px; color: red"
              >*</span
            >
            <el-date-picker
              class="date-pick"
              v-model="formData.idcardValidityTerm"
              type="daterange"
              value-format="yyyy.MM.dd"
              range-separator="至"
              start-placeholder="身份证有效期"
              end-placeholder="结束日期"
              :disabled="!notSubmitted || formData.ifLong"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label=" " prop="ifLong">
            <el-checkbox
              v-model="formData.ifLong"
              :disabled="!notSubmitted || formData.idcardValidityTerm != null"
              >长期</el-checkbox
            >
          </el-form-item>
        </div>
        <div class="line">
          <el-form-item label=" " prop="email">
            <el-input
              v-model="formData.email"
              placeholder="请输入电子邮箱"
              prefix-icon="el-icon-message"
              maxlength="30"
              :disabled="!notSubmitted"
            ></el-input>
          </el-form-item>
          <el-form-item label=" " prop="address">
            <el-input
              v-model="formData.address"
              placeholder="请输入住所"
              prefix-icon="el-icon-message"
              maxlength="30"
              :disabled="!notSubmitted"
            ></el-input>
          </el-form-item>
        </div>

        <!-- <div class="line">
          <el-form-item label-width="80px" label="企业类型" prop="companyTypes">
            <el-checkbox-group
              v-model="formData.companyTypes"
              :disabled="!notSubmitted"
            >
              <el-checkbox
                v-for="item in firmtypeList"
                :label="item.dictId"
                :key="item.dictId"
                >{{ item.dictValue }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </div> -->
      </div>
      <div class="title">
        收款账户信息<span>（请填写后核实,账号为您的线下收款账号）</span>
      </div>
      <div class="group">
        <div class="line">
          <el-form-item label=" " prop="accountNumber">
            <el-input
              v-model.trim="formData.accountNumber"
              placeholder="请输入收款账号"
              maxlength="30"
              prefix-icon="hy-icon-yinhangka"
              @change="formDataaCcountNumber"
              oninput="value=value.replace(/[^\d]/g,'')"
              :disabled="!disabled"
            ></el-input>
          </el-form-item>
          <el-form-item label=" " prop="accountName">
            <el-input
              v-model.trim="formData.accountName"
              placeholder="请输入收款户名"
              prefix-icon="hy-icon-yonghuming"
              maxlength="30"
              :disabled="!disabled"
            ></el-input>
          </el-form-item>
        </div>
        <div class="line">
          <el-form-item label=" " prop="accountBank">
            <!-- <el-input
              v-model.trim="formData.accountBank"
              placeholder="请输入收款银行名称"
              maxlength="20"
              :disabled="!disabled"
              prefix-icon="hy-icon-yinhang"
            ></el-input> -->
            <!-- 远程搜索要使用filterable和remote -->
            <el-select
              v-model.trim="formData.accountBank"
              filterable
              remote
              placeholder="请选择或输入银行名称"
              :remote-method="remoteMethod"
              :loading="loading"
              :disabled="!disabled"
              clearable
            >
              <!-- remote-method封装好的钩子函数。当用户在输入框中输入内容的时候，会触发这个函数的执行，
   把输入框对应的值作为参数带给回调函数，loading的意思就是远程搜索的时候等待的时间，即：加载中-->
              <el-option
                v-for="item in options"
                :key="item.dictValue"
                :label="item.dictValue"
                :value="item.dictValue"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="bottom" v-if="notSubmitted || isagin">
        <div class="checked">
          <el-checkbox v-model="radio" @change="radioChangeFn" :label="true"
            >&nbsp;</el-checkbox
          >
          <p>
            已阅读并同意<span @click="user = true" class="agreement"
              >《新疆煤炭交易中心企业认证协议》</span
            >
          </p>
        </div>
        <div class="btn-wrap">
          <el-button
            @click="submit"
            class="hy-btn"
            :disabled="!radio"
            :loading="submiting"
            >提交认证</el-button
          >
        </div>
        <div class="tip">认证成功后,当前申请账号将自动成为企业管理员账号！</div>
      </div>
      <div v-else>
        <div class="bottom" v-if="formData.auditStatus == 0">
          <div class="tip">审核通过后才可修改收款账号</div>
        </div>
        <div class="bottom" v-if="formData.auditStatus == 1">
          <div class="btn-wrap" v-if="userId == formData.enterpriseId">
            <el-button @click="edit" v-if="!disabled">修改账户信息</el-button>
            <el-button @click="submitedit" v-else :loading="submiting"
              >确认修改</el-button
            >
          </div>
        </div>
        <div class="bottom" v-if="formData.auditStatus == 2">
          <div class="btn-wrap">
            <el-button @click="recertification">重新认证</el-button>
          </div>
        </div>
      </div>
    </el-form>
    <el-dialog
      title="企业认证协议"
      :visible.sync="user"
      width="1000px"
      :before-close="handleClose"
    >
      <div style="height: 500px; overflow-y: scroll">
        <div style="height: 1800px">
          用户服务协议 一、一般规定
          雨果数据服务协议（以下简称本协议）（以下简称“用户”）
          本协议用于规范双方之间全部的权利义务及法律关系，一经用户点击确认，等同签订本协议。
          雨公司。 1.
          本协议内容包括协议正文及所有雨发布的或将来可能发布的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。
          2.
          用户在使服务的同时，承诺接受并遵守各项相关规则的规定。雨果数据有权根据需要不定期的制定、修改各类规则，此类规则会在发布后或经用户确认后生效，亦成为本协议一部分。
          3. 用户确认本协议后，本协议即在用户 4.
          请用户务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可据咨询。
          5.
          无论事实上用户是否在注册之前认真阅读了本协议，一旦用户确认本协议，在法律上其行为就等同于用户同意并签署了本协议。
          6. 本协议不涉及用户之间因网上交易而产生的法律关系及法律纠纷。
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
    <message :visible.sync="mssagevisible" />
  </div>
</template>

<script>
function insertStr(soure, start, newStr) {
  return soure.slice(0, start) + newStr + soure.slice(start);
}
import { getbannerList } from "@/api/home";
import {
  reqCompanyAuthDetail,
  reqAddAuth,
  getSysDictDataByByDictType,
  reqUploadfile,
  updateConpanyBankAccount,
} from "@/api/hy";
// import { reqUploadfile } from "@/api/hy";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  data() {
    const validateLegerName = (rule, value, callback) => {
      let validate = /^[\u4e00-\u9fa5·]{2,20}$/;
      if (!validate.test(value)) {
        return callback(new Error("法人名称只能输入2-20位汉字"));
      } else {
        return callback();
      }
    };
    return {
      mssagevisible: false,
      firmtypeList: [],
      //模糊搜索
      options: [],
      loading: false,
      //
      userId: Vue.ls.get(types.userId),
      formData: {
        enterpriseId: Vue.ls.get(types.enterpriseId),
        idcardFrontUrl: "",
        idcardBackUrl: "",
        businessLicenseUrl: "",
        roadTransportLicenseUrl: "",
        legalName: "",
        companyName: "",
        legalIdcard: "",
        socialCode: "",
        idcardValidityTerm: null,
        accountNumber: "",
        accountName: "",
        accountBank: "",
        attorneyPowerUrl: "",
        auditStatus: null,
        ifLong: false,
        companyTypes: [],
        address: "",
      },
      rules: {
        legalName: [
          { required: true, message: "请输入法人名称", trigger: "blur" },
          { validator: validateLegerName, trigger: "blur" },
        ],
        idcardFrontUrl: [
          {
            required: true,
            message: "请上传法人身份证国徽面",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入电子邮箱",
            trigger: "change",
          },
        ],
        idcardBackUrl: [
          {
            required: true,
            message: "请上传法人身份证人像面",
            trigger: "change",
          },
        ],
        businessLicenseUrl: [
          { required: true, message: "请上传营业执照", trigger: "change" },
        ],
        companyName: [
          { required: true, message: "请填写企业名称", trigger: "blur" },
          {
            min: 1,
            max: 99,
            message: "长度在 1 到 99 个字符",
            trigger: "blur",
          },
        ],
        legalIdcard: [
          { required: true, message: "请填写法人身份证号", trigger: "blur" },

          {
            pattern:
              /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
            message: "请输入有效身份证号码",
            trigger: "blur",
          },
        ],
        socialCode: [
          { required: true, message: "请填写统一信用代码", trigger: "blur" },
          {
            pattern: /^[A-Za-z0-9]{1,30}$/,
            message: "请输入有效统一信用代码",
            trigger: "blur",
          },
          { min: 1, max: 30, trigger: "blur" },
        ],

        accountNumber: [
          { required: false, message: "请填写收款账号", trigger: "blur" },
          {
            pattern: /^[0-9]{1,30}/,
            message: "请输入有效收款账号",
            trigger: "blur",
          },
          { min: 1, max: 30, trigger: "blur" },
        ],
        accountName: [
          { required: false, message: "请填写收款户名", trigger: "blur" },
          {
            pattern: /^[\u4E00-\u9FA5]{1,30}$/,
            message: "请输入正确收款户名",
            trigger: "blur",
          },
          { min: 1, max: 30, trigger: "blur" },
        ],
        accountBank: [
          { required: false, message: "请填写收款银行", trigger: "blur" },
        ],
        attorneyPowerUrl: [
          { required: true, message: "请上传委托书", trigger: "change" },
        ],
        companyTypes: [
          { required: true, message: "请选择企业类型", trigger: "change" },
        ],
        address: [{ required: true, message: "请填写住所", trigger: "blur" }],
      },
      radio: false,
      attorneyPowerName: "",
      submiting: false,
      notSubmitted: false,
      disabled: false,
      user: false,
      isagin: false,
      isRoadTransportLicense: false,
    };
  },
  filters: {
    auditStatusFileter(val) {
      return {
        "-1": "未提交认证",
        0: "审核中",
        1: "已通过",
        2: "已拒绝",
      }[val];
    },
  },
  components: {
    MyUpLoad: () => import("./components/MyUpLoad.vue"),
    message: () => import("./components/message.vue"),
  },
  mounted() {
    this.getCompanyDetail();
    this.handleSelect();
  },
  methods: {
    companyTypesChange(e) {
      console.log(e);
      let list = e;
      if (list.length > 0) {
        if (list.includes("408")) {
          this.isRoadTransportLicense = true;
        } else {
          this.isRoadTransportLicense = false;
        }
      } else {
        this.isRoadTransportLicense = false;
      }
    },
    async getFirmtype() {
      const obj = {
        pageNum: 1,
        pageSize: 1000,
        dictType: "company_type",
      };
      const res = await getbannerList(obj);
      if (res.code == 0) {
        if (!this.notSubmitted && this.formData.auditStatus == 1) {
          let newlist = [];
          this.formData.companyTypes.map((item) => {
            res.data.list.map((it) => {
              if (item == it.dictId) {
                this.firmtypeList.push(it);
                newlist.push(it.dictId);
              }
            });
          });

          if (newlist.length > 0) {
            if (newlist.includes("408")) {
              this.isRoadTransportLicense = true;
            } else {
              this.isRoadTransportLicense = false;
            }
          } else {
            this.isRoadTransportLicense = false;
          }
        } else {
          this.firmtypeList = res.data.list;
        }
      }
    },
    changeTrading(data) {
      console.log(data);
      this.formData.address = data.address;
      this.formData.companyName = data.name;
      this.formData.legalName = data.owner;
      this.formData.socialCode = data.code;
    },
    changeTradings(data) {},
    changeIdcards(data) {
      let arr = data?.timelimit?.split("-") || null;
      this.formData.idcardValidityTerm = arr;
    },
    changeIdcard(data) {
      this.formData.legalIdcard = data.number;
    },
    // console.log(data);
    // console.log(this.formData.idcardValidityTerm);
    // 当用户输入内容开始远程搜索模糊查询的时候，会触发remoteMethod方法
    remoteMethod(query) {
      // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
      if (query !== "") {
        this.loading = true; // 开始拿数据喽
        // 这里模拟发请求，res就当成发请求返回来的数据吧。
        let config = {
          dictType: "bank_name",
          pageNum: 1,
          pageSize: 10000000,
          searchParam: query,
        };
        getSysDictDataByByDictType(config).then((ret) => {
          if (ret.code == 0) {
            let res = ret.data.list;
            console.log(res);
            this.loading = false; //
            // 然后把拿到的所有数据，首先进行一个过滤，把有关联的过滤成一个新数组给到options使用
            this.options = res.filter((item) => {
              // indexOf等于0代表只要首个字匹配的，如：搜索 王 王小虎数据会被过滤出来，但是 小虎王的数据不会被过滤出来。因为indexOf等于0代表以什么开头
              // return item.label.toLowerCase().indexOf(query.toLowerCase()) == 0

              // indexOf大于-1代表的是，只要有这个字出现的即可，如：搜索 王 王小虎、小虎王、小王虎都会被过滤出来。因为indexOf找不到才会返回-1，
              // 大于-1说明只要有就行，不论是不是开头也好，中间也好，或者结尾也好
              return (
                item.dictValue.toLowerCase().indexOf(query.toLowerCase()) > -1
              );
            });
          }
        });
      } else {
        this.options = [];
      }
    },

    handleSelect(e) {
      let config = {
        dictType: "bank_name",
        pageNum: 1,
        pageSize: 10000000,
        searchParam: e ? e : "",
      };
      getSysDictDataByByDictType(config).then((res) => {
        if (res.code == 0) {
          this.options = res.data.list;
        }
      });
    },
    checkboxChang(e) {
      console.log(e);
    },
    formDataaCcountNumber(e) {
      this.formData.accountNumber = e;
    },
    handleClose() {
      this.user = false;
    },
    radioChangeFn(e) {
      console.log(e);
    },
    recertification() {
      // this.formData.auditStatus=0
      this.isagin = true;
      this.notSubmitted = true;
      this.disabled = true;
    },
    edit() {
      // this.formData.auditStatus=0
      this.disabled = true;
    },
    async getCompanyDetail() {
      const ret = await reqCompanyAuthDetail({
        enterpriseId: Vue.ls.get(types.enterpriseId),
      });
      if (ret.code == 0) {
        this.isagin = false;
        this.notSubmitted = false;
        if (ret.data.auditStatus == -1) {
          this.notSubmitted = true;
          this.disabled = true;
        } else {
          this.bindData(ret.data);
          this.setDisabled(ret.data);
        }
      } else {
        this.notSubmitted = true;
      }
      this.getFirmtype();
    },
    setDisabled(item) {
      this.disabled = item.auditStatus != 1;
      if (item.auditStatus == 0) {
        this.disabled = false;
      }
      if (item.auditStatus == 2) {
        this.disabled = false;
      }
    },
    bindData(data) {
      this.formData = data;
      if (data.idcardValidityTerm != null && data.idcardValidityTerm != "") {
        try {
          this.formData.idcardValidityTerm = data.idcardValidityTerm.split("-");
        } catch (e) {
          console.log(e);
        }
      } else {
        this.formData.idcardValidityTerm = null;
      }
      // console.log(this.formData)
    },
    submit() {
      console.log(this.isEdit, this.radio, "sss");
      if (!this.isEdit && !this.radio) return;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitFn();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitedit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          let data = this.initSubmitData();
          updateConpanyBankAccount(data)
            .then((ret) => {
              if (ret.code == 0) {
                // this.mssagevisible = true;
                // this.getCompanyDetail();
                this.disabled = !this.disabled;
                this.formData.auditStatus = 1;
              } else {
                this.$message({
                  type: "error",
                  message: ret.message,
                });
              }
              this.submiting = false;
            })
            .catch((err) => {
              console.log(err);
              this.submiting = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    initSubmitData() {
      this.formData.auditStatus = 0;
      let data = {
        ...this.formData,
      };
      console.log(data);
      if (data.idcardValidityTerm != null && data.idcardValidityTerm != "") {
        try {
          data.idcardValidityTerm = data.idcardValidityTerm.join("-");
        } catch (e) {
          e;
        }
      } else {
        data.idcardValidityTerm = null;
      }
      return data;
    },
    submitFn() {
      if (this.submiting) return;
      this.submiting = true;
      let data = this.initSubmitData();
      reqAddAuth(data)
        .then((ret) => {
          if (ret.code == 0) {
            this.mssagevisible = true;
            // this.getCompanyDetail();
            setTimeout(() => {
              this.$router.push({
                path: "/index",
              });
            }, 3000);
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
          this.submiting = false;
        })
        .catch((err) => {
          console.log(err);
          this.submiting = false;
        });
    },
    beforeUpload(file) {
      this.attorneyPowerName = file.name;
      reqUploadfile(file)
        .then((ret) => {
          if (ret.code == 0) {
            this.formData.attorneyPowerUrl = ret.data;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {});
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__error {
  min-width: 800px;
}
.el-range-separator {
  border: 1px solid blue;
}
.authentication {
  width: 1195px;
  margin: 15px auto;
  background: white;
  &::before,
  &::after {
    display: table;
    content: "";
  }
}
.state-box {
  margin: 20px 0;
  text-align: right;
  span {
    display: block;
    margin-right: 30px;
    color: green;
  }
}
.entrust {
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    p {
      margin-right: 40px;
      color: #2084d6;
    }
    span {
      cursor: pointer;
      & + span {
        margin-left: 5px;
      }
    }
  }
}
.title {
  font-size: 18px;
  font-weight: bold;
  font-family: PingFang SC;
  padding: 14px 15px;
  border: 1px solid #e5e5e5;
  border-left: none;
  border-right: none;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    color: red;
    font-weight: normal;
  }
}
.upload-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom {
  text-align: center;
  margin-bottom: 40px;
  .checked {
    display: flex;
    align-items: center;
    justify-content: center;
    &::v-deep .el-radio {
      margin-right: 0;
    }
  }
  .btn-wrap {
    margin: 25px 0 18px 0;
    .el-button {
      width: 200px;
    }
  }
  .tip {
    color: red;
  }
}
.remove {
  cursor: pointer;
  margin-left: 10px;
  vertical-align: middle;
}
.group {
  padding: 32px;
  background: white;
}
.line {
  display: flex;
  align-items: center;
  margin-left: 140px;

  .el-input {
    width: 360px;
  }
}
.entrust {
  margin-left: 104px;
  .hy-btn {
    width: 163px;
  }
}
.download,
.agreement {
  cursor: pointer;
  color: #3889fb;
}
.lines {
  display: flex;
  justify-content: space-between;
}
</style>
