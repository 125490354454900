<template>
  <div class="detailed">
    <div class="detaile">
      <div class="flex hljs">
        <div>
          <div style="font-size: 19px; font-weight: bold">
            {{ rulform.goodsName }}
          </div>
          <div style="margin-top: 10px" v-if="rulform?.orderType != 1">
            商品编号：{{ rulform.goodsNum }}
          </div>
        </div>
        <div>
          <div style="font-size: 19px; font-weight: bold">
            {{
              !notes
                ? rulform.receiverName
                : rulform.viewSet && rulform.viewSet.viewInviteEnterprise == 1
                ? rulform.receiverName
                : rulform.receiverNameHide
            }}
          </div>
          <div style="margin-top: 10px">{{ rulform.receiverTelephone }}</div>
        </div>
      </div>

      <div class="conetnt_l flex" v-if="!notes">
        <div class="taxInc">
          {{ rulform.taxIncluded | taxIncluded
          }}{{ rulform.priceType | priceType }}
        </div>
        <div style="margin-left: 20px" v-if="rulform.orderType == 2">
          价格：<span style="color: red; font-size: 17px; font-weight: bold"
            >￥{{ rulform.goodsCategoryList[0].goodsPrice }}</span
          >元/吨
        </div>
        <div style="margin-left: 20px">
          订单数量：
          <span v-if="rulform.ifConsultRealityCount == 0"
            ><span style="color: red; font-size: 17px; font-weight: bold">{{
              rulform.initialCount
            }}</span
            >吨</span
          >
          <span v-else>以实际结算数量为准</span>
        </div>
      </div>
      <div class="conetnt_l flex" v-else>
        <div class="taxInc">
          {{ rulform.taxIncluded | taxIncluded
          }}{{ rulform.priceType | priceType }}
        </div>
        <div style="margin-left: 20px" v-if="rulform.orderType == 2">
          价格：<span style="color: red; font-size: 17px; font-weight: bold"
            >￥{{
              rulform.viewSet && rulform.viewSet.viewPrice == 1
                ? rulform.goodsCategoryList[0].goodsPrice
                : "*"
            }}</span
          >元/吨
        </div>
        <div style="margin-left: 20px">
          订单数量：
          <span v-if="rulform.viewSet && rulform.viewSet.viewCount == 1">
            <span
              v-if="rulform.ifConsultRealityCount == 0"
              style="color: red; font-size: 17px"
              >{{ rulform.initialCount }}</span
            >
            <span v-else>以实际结算数量为准</span>
            <span v-show="rulform.ifConsultRealityCount == 0">吨</span>
          </span>
          <span v-else>*</span>
        </div>
      </div>
      <div
        class="goods-list"
        style="margin-top: 20px"
        v-if="rulform.orderType == 1"
      >
        <table border="1">
          <tr>
            <th>商品编号</th>
            <th>商品分类</th>
            <th>商品名称</th>
            <th>价格(元/吨)</th>
          </tr>
          <tr v-for="(item, index) in rulform.goodsCategoryList" :key="index">
            <td>{{ item.goodsNum }}</td>
            <td>{{ item.goodsTypeName }}</td>
            <td>{{ item.goodsName }}</td>
            <td>
              {{
                !notes
                  ? item.goodsPrice
                  : rulform.viewSet && rulform.viewSet.viewPrice == 1
                  ? item.goodsPrice
                  : "*"
              }}
            </td>
          </tr>
        </table>
      </div>
      <!-- <div style="margin-top: 20px">
        <div>
          <span style="font-size: 18px; font-weight: bold">合同信息： </span>
          <span
            style="border-bottom: 1px solid blue; color: blue; cursor: pointer"
            @click="locck"
            >点击查看</span
          >
        </div>
      </div> -->
      <div style="margin-top: 20px; color: red">
        若同意邀约交易,将自动生成订单,且合同信息将转到订单信息内可直接确认
      </div>
    </div>

    <div
      style="display: flex; justify-content: center; margin-top: 100px"
      v-if="rulform.confirmStatus == 0 && rulform.type != '发起'"
    >
      <el-button type="primary" @click="agree('1')">同意邀约交易</el-button>
      <el-button style="margin-left: 50px" @click="agree('2')"
        >拒绝邀约交易</el-button
      >
    </div>

    <el-dialog title="附件预览" :visible.sync="isaccount" center append-to-body>
      <div class="">
        <div
          v-for="(item, index) in contractInfoList"
          :key="index"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
          "
        >
          <el-button
            @click="examinecontract(item.storageName)"
            v-if="
              (item.storageName && item.storageName.slice(-4) == '.pdf') ||
              (item.storageName && item.storageName.slice(-4) == '.doc') ||
              (item.storageName && item.storageName.slice(-5) == '.docx') ||
              (item.storageName && item.storageName.slice(-4) == '.PDF')
            "
            >点击查看文件<br /><br /><br />{{ item.originalName }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { confirmInvite } from "@/api/foundation";
import { getInvoiceDetail } from "@/api/public.js";
const { baseUrl } = require("@/config");
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      baseUrl: baseUrl,
      isaccount: false,
      rulform: {},
      contractInfoList: [],
      notes: "",
      goodsData: [
        {
          no: 1,
          name: "hhh",
          type: "momei",
          price: 222,
        },
      ],
    };
  },
  mounted() {
    this.getDetail();

    let ItemOn = Vue.ls.get(types.ItemOn);
    this.contractInfoList = ItemOn.contractInfoList;
  },
  filters: {
    taxIncluded(val) {
      switch (val) {
        case "0":
          return "含税";
          break;
        case "1":
          return "不含税";
          break;
      }
    },
    priceType(val) {
      switch (val) {
        case "1":
          return "坑口价";
          break;
        case "2":
          return "出厂价";
          break;
        case "3":
          return "到港价";
          break;
        case "4":
          return "平仓价";
          break;
        case "5":
          return "车板价";
          break;
        case "6":
          return "到厂价";
          break;
      }
    },
    confirmStatus(val) {
      let arr = ["待确认", "同意邀请", "拒绝邀请"];
      return arr[val];
    },
    deliveryMode(val) {
      switch (val) {
        case 1:
          return "送货";
          break;
        case 2:
          return "自提";
          break;
      }
    },
  },
  methods: {
    getDetail() {
      getInvoiceDetail({ goodsId: this.$route.query.goodsId }).then((res) => {
        // debugger
        if (res.code == 0) {
          let quer = Object.assign(res.data, this.$route.query);
          if (quer.notes) {
            this.notes = quer.notes;
            let val = quer.receiverName;
            quer.receiverNameHide =
              quer.receiverName.slice(0, 2) + "******" + val.slice(-2);
            let val1 = quer.receiverTelephone;
            quer.receiverTelephone =
              quer.receiverTelephone.slice(0, 3) + "******" + val1.slice(-2);
          }
          this.rulform = quer;
          this.rulform.viewSet = JSON.parse(this.rulform.viewSet);
          console.log(
            this.rulform,
            "222222222222222222222222222222222222222222"
          );
        }
      });
    },
    locck() {
      if (this.notes) {
        this.$message.error("您没有此权限！");
        return false;
      }
      this.isaccount = true;
    },
    examinecontract(item) {
      console.log(item);
      let url = this.baseUrl + "third/third/ossGetFile/" + item;
      window.open(url);
    },
    agree(e) {
      let config = {
        confirmStatus: e,
        inviteEnterpriseId: this.rulform.inviteEnterpriseId,
      };
      confirmInvite(config).then((res) => {
        if (res.code == 0) {
          if (e == 1) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "拒绝成功",
              type: "error",
            });
          }

          this.$router.push({
            path: "/Invitation",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.taxInc {
  width: 100px;
  text-align: center;
  background: #d0111a;
  color: #fff;
  padding: 2px 10px;
}

.detailed {
  width: 1200px;
  margin: 0 auto;
}

.detaile {
  padding: 20px;
  background: #fff;
}

.flex {
  display: flex;
  align-items: center;
}

.hljs {
  justify-content: space-between;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
}

.conetnt_l {
  margin-top: 10px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
}
.goods-list {
  table {
    tr {
      th,
      td {
        padding: 5px 10px;
        text-align: center;
        border: 1px dashed black;
      }
    }
  }
}
</style>
