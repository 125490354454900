<template>
  <div>
    <steps ref="steps"></steps>
    <div class="cont">
      <div class="flex Application">
        <div style="margin-right: 5px">申请状态:{{ statusTitle }}</div>
        <i class="el-icon-refresh icons" @click="statusClick"></i>
      </div>
      <div class="border">
        <div style="font-size: 19px; font-weight: bold">影印件上传</div>
        <div class="flexs">
          <div>
            <div class="title">法人身份证人像面</div>
            <div>
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :http-request="addinvoiceUrls"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="legalCredentialFaceUrl"
                  :src="legalCredentialFaceUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>

          <div>
            <div class="title">法人身份证国徽面</div>
            <div>
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="handleAvatar"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="legalCredentialBackUrl"
                  :src="legalCredentialBackUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>

          <div>
            <div class="title">统一社会信用代码证件照</div>
            <div>
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="businessLicense"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="unifiedSocialCreditUrl"
                  :src="unifiedSocialCreditUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>

          <div>
            <div class="title">基本开户证明</div>
            <div>
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :http-request="addinvoiceUrl"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="basicCertificateUrl"
                  :src="basicCertificateUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
      <div class="border" style="margin-top: 10px">
        <div style="font-size: 19px; font-weight: bold">企业相关信息</div>
        <div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <div class="flex">
              <el-form-item
                label="企业名称"
                prop="corporationName"
                label-width="100px"
              >
                <el-input v-model="ruleForm.corporationName"></el-input>
              </el-form-item>
              <el-form-item
                label="企业简称"
                prop="corporationShort"
                label-width="100px"
              >
                <el-input v-model="ruleForm.corporationShort"></el-input>
              </el-form-item>
              <el-form-item
                label="企业邮箱"
                prop="corEmail"
                label-width="100px"
              >
                <el-input v-model="ruleForm.corEmail"></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <el-form-item
                label="企业地址"
                prop="corAddress"
                label-width="100px"
              >
                <el-input v-model="ruleForm.corAddress"></el-input>
              </el-form-item>
              <el-form-item label="企业规模" prop="scale" label-width="100px">
                <el-select v-model="ruleForm.scale" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="	统一社会信用代码"
                prop="unifiedSocialCreditCode"
                label-width="160px"
              >
                <el-input
                  v-model="ruleForm.unifiedSocialCreditCode"
                  minlength="18"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <el-form-item
                label="营业执照有效期(起始)"
                prop="allLicenceIssDate"
                label-width="160px"
              >
                <el-date-picker
                  v-model="ruleForm.allLicenceIssDate"
                  type="date"
                  format="yyyy-MM-dd"
                  placeholder="选择日期"
                  value-format="yyyyMMdd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="营业执照有效期(到期)"
                prop="allLicenceExpiryDate"
                label-width="160px"
              >
                <el-date-picker
                  :disabled="checkeds"
                  v-model="ruleForm.allLicenceExpiryDate"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyyMMdd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="长期" prop="name" label-width="80px">
                <el-checkbox
                  v-model="checkeds"
                  @change="checkedsC"
                ></el-checkbox>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <div class="border" style="margin-top: 10px">
        <div style="font-size: 19px; font-weight: bold">法人相关信息</div>
        <el-form
          :model="legalperson"
          :rules="legalpersonrules"
          ref="legalperson"
          class="demo-ruleForm"
        >
          <div class="flex">
            <el-form-item
              label="法人姓名"
              prop="legalPersonName"
              label-width="100px"
            >
              <el-input v-model="legalperson.legalPersonName"></el-input>
            </el-form-item>
            <el-form-item label="法人证件类型" prop="type" label-width="120px">
              <el-input v-model="legalperson.type" disabled></el-input>
            </el-form-item>
            <el-form-item
              label="法人证件号码"
              prop="legalCredentialNumber"
              label-width="120px"
            >
              <el-input v-model="legalperson.legalCredentialNumber"></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              label="法人联系电话"
              prop="legalPersonContactNumber"
              label-width="120px"
            >
              <el-input
                v-model="legalperson.legalPersonContactNumber"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="法人身份证件有效期(起始)"
              prop="legalPersonIssDate"
              label-width="200px"
            >
              <el-date-picker
                v-model="legalperson.legalPersonIssDate"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyyMMdd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              label="法人身份证件有效期(到期)"
              prop="legalPersonExpiryDate"
              label-width="200px"
            >
              <el-date-picker
                :disabled="checked"
                v-model="legalperson.legalPersonExpiryDate"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyyMMdd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="长期" label-width="80px">
              <el-checkbox v-model="checked" @change="checkedC"></el-checkbox>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="btns">
        <!-- <el-button>取消</el-button> -->
        <el-button type="primary" @click="addClick">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompanyAuthDetail } from "@/api/userCenters.js";
import * as types from "@/store/action-types";
import Vue from "vue";
import { ossUploadFile } from "@/api/public";
import { accountOpenForZJ, getAccountStatusForZJ } from "@/api/Accountopening";
const { baseUrl } = require("@/config");
export default {
  data() {
    const validateLegerName = (rule, value, callback) => {
      let validate = /^[\u4e00-\u9fa5·]{2,20}$/;
      if (!validate.test(value)) {
        return callback(new Error("法人名称只能输入2-20位汉字"));
      } else {
        return callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请正确填写邮箱"));
      } else {
        if (value !== "") {
          var reg =
            /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
          if (!reg.test(value)) {
            callback(new Error("请输入有效的邮箱"));
          }
        }
        callback();
      }
    };
    return {
      statusTitle: "未申请",
      legalCredentialFaceUrl: "",
      legalCredentialBackUrl: "",
      baseUrl: baseUrl,
      unifiedSocialCreditUrl: "",
      options: [
        {
          value: "01",
          label: "大型",
        },
        {
          value: "02",
          label: "中性",
        },
        {
          value: "03",
          label: "小型",
        },
        {
          value: "04",
          label: "微型",
        },
        {
          value: "98",
          label: "其他",
        },
      ],
      value: "",
      checked: false,
      checkeds: false,
      basicCertificateUrl: "",
      value1: "",

      legalperson: {
        legalPersonName: "",
        type: "身份证",
        legalCredentialNumber: "",
        legalPersonContactNumber: "",
        legalPersonExpiryDate: "",
        legalPersonIssDate: "",
      },
      ruleForm: {
        corporationName: "",
        scale: "",
        corporationShort: "",
        corEmail: "",
        unifiedSocialCreditCode: "",
        corAddress: "",
        allLicenceIssDate: "",
        allLicenceExpiryDate: "",
      },
      legalpersonrules: {
        legalPersonName: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
          { validator: validateLegerName, trigger: "blur" },
        ],
        legalCredentialNumber: [
          { required: true, message: "请输入法人证件号码", trigger: "blur" },

          {
            pattern:
              /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
            message: "请输入有效法人证件号码",
            trigger: "blur",
          },
        ],
        legalPersonContactNumber: [
          { required: true, message: "请输入法人联系电话", trigger: "blur" },
          {
            type: "number",
            message: "手机号格式不正确",
            trigger: "blur",
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
              if (!phonereg.test(value)) {
                return false;
              } else {
                return Number(value);
              }
            },
          },
        ],
        legalPersonIssDate: [
          {
            required: true,
            message: "请选择起始日期",
            trigger: "change",
          },
        ],
        legalPersonExpiryDate: [
          {
            required: true,
            message: "请选择到期日期",
            trigger: "change",
          },
        ],
      },
      rules: {
        corporationName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
          {
            min: 1,
            max: 99,
            message: "长度在 1 到 99 个字符",
            trigger: "blur",
          },
        ],
        corporationShort: [
          { required: true, message: "请输入企业简介", trigger: "blur" },
        ],
        corEmail: [
          { required: true, message: "请输入企业邮箱", trigger: "blur" },
          { validator: validateEmail, trigger: "blur" },
        ],
        unifiedSocialCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9]{1,30}$/,
            message: "请输入有效统一信用代码",
            trigger: "blur",
          },
          {
            min: 18,
            max: 30,
            trigger: "blur",
            message: "有效统一信用代码最低为18位",
          },
        ],
        corAddress: [
          { required: true, message: "请输入企业地址", trigger: "blur" },
        ],
        scale: [
          { required: true, message: "请选择企业规模", trigger: "change" },
        ],
        allLicenceIssDate: [
          {
            required: true,
            message: "请选择起始日期",
            trigger: "change",
          },
        ],
        allLicenceExpiryDate: [
          {
            required: true,
            message: "请选择到期日期",
            trigger: "change",
          },
        ],
      },
    };
  },
  components: {
    steps: () => import("./steps.vue"),
  },
  mounted() {
    this.getDetail();
    this.getStatusForZJ();
  },
  methods: {
    getStatusForZJ() {
      getAccountStatusForZJ().then((res) => {
        if (res.data.accountOpenStatus == 10) {
          this.statusTitle = "受理成功";
        }
        if (res.data.accountOpenStatus == 20) {
          this.statusTitle = "处理中";
        }
        if (res.data.accountOpenStatus == 30) {
          this.statusTitle = "开户成功";
          Vue.ls.set(types.active, 1);
          this.$emit("logoutHeader", 1);
          this.$refs.steps.getactive();
        }
        if (res.data.accountOpenStatus == 40) {
          this.statusTitle = "开户失败";
        }
      });
    },
    statusClick() {
      this.getStatusForZJ();
      this.$message({
        message: "刷新成功",
        type: "success",
      });
    },
    checkedsC(e) {
      if (e == true) {
        this.ruleForm.allLicenceExpiryDate = new Date("9999-12-31");
      } else {
        this.ruleForm.allLicenceExpiryDate = "";
      }
    },
    checkedC(e) {
      if (e == true) {
        this.legalperson.legalPersonExpiryDate = new Date("9999-12-31");
      } else {
        this.legalperson.legalPersonExpiryDate = "";
      }
    },
    getDetail() {
      getCompanyAuthDetail().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.legalCredentialFaceUrl =
            this.baseUrl + "third/third/ossGetFile/" + res.data.idcardFrontUrl;
          this.legalCredentialBackUrl =
            this.baseUrl + "third/third/ossGetFile/" + res.data.idcardBackUrl;
          this.unifiedSocialCreditUrl =
            this.baseUrl +
            "third/third/ossGetFile/" +
            res.data.businessLicenseUrl;
        }
      });
    },
    businessLicense(file) {
      let otherFiles = file.file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.unifiedSocialCreditUrl =
            this.baseUrl + "third/third/ossGetFile/" + res.data;
        }
      });
    },
    handleAvatar(file) {
      let otherFiles = file.file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.legalCredentialBackUrl =
            this.baseUrl + "third/third/ossGetFile/" + res.data;
        }
      });
    },
    addinvoiceUrls(file) {
      let otherFiles = file.file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.legalCredentialFaceUrl =
            this.baseUrl + "third/third/ossGetFile/" + res.data;
        }
      });
    },
    addinvoiceUrl(file) {
      let otherFiles = file.file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.basicCertificateUrl =
            this.baseUrl + "third/third/ossGetFile/" + res.data;
        }
      });
    },
    handleAvatarSuccess() {},
    beforeAvatarUpload() {},

    addClick() {
      if (this.checkeds == true) {
        var d = new Date(this.ruleForm.allLicenceExpiryDate);
        this.ruleForm.allLicenceExpiryDate =
          d.getFullYear() + "" + (d.getMonth() + 1) + "" + d.getDate();
      }
      if (this.checked) {
        let a = new Date(this.legalperson.legalPersonExpiryDate);
        this.legalperson.legalPersonExpiryDate =
          a.getFullYear() + "" + (a.getMonth() + 1) + "" + a.getDate();
      }
      console.log(
        this.legalperson.legalPersonExpiryDate,
        "------------------------------",
        this.ruleForm.allLicenceExpiryDate
      );
      if (this.checkeds == true) {
        this.ruleForm.allLicenceExpiryDate = new Date("9999-12-31");
      }
      if (this.checked == true) {
        this.legalperson.legalPersonExpiryDate = new Date("9999-12-31");
      }
      if (this.basicCertificateUrl == "") {
        this.$message.error("请上传基本开户证明");
        return false;
      }
      this.$refs.ruleForm.validate((valid) => {
        this.$refs.legalperson.validate((valids) => {
          if (valid && valids) {
            if (this.checkeds == true) {
              var d = new Date(this.ruleForm.allLicenceExpiryDate);
              this.ruleForm.allLicenceExpiryDate =
                d.getFullYear() + "" + (d.getMonth() + 1) + "" + d.getDate();
            }
            if (this.checked) {
              let a = new Date(this.legalperson.legalPersonExpiryDate);
              this.legalperson.legalPersonExpiryDate =
                a.getFullYear() + "" + (a.getMonth() + 1) + "" + a.getDate();
            }
            console.log(
              this.legalperson.legalPersonExpiryDate,
              "------------------------------",
              this.ruleForm.allLicenceExpiryDate
            );
            let config = {};
            let legalperson = this.legalperson;
            let ruleForm = this.ruleForm;
            config = Object.assign(ruleForm, legalperson);
            config.legalCredentialFaceUrl = this.legalCredentialFaceUrl;
            config.unifiedSocialCreditUrl = this.unifiedSocialCreditUrl;
            config.legalCredentialBackUrl = this.legalCredentialBackUrl;
            config.basicCertificateUrl = this.basicCertificateUrl;
            console.log(config);
            const loading = this.$loading({
              lock: true,
              text: "正在提交中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            accountOpenForZJ(config).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.getStatusForZJ();
                loading.close();
              } else {
                this.$message.error(res.message);
                loading.close();
              }
            });
            // Vue.ls.set(types.active, 1);
            // this.$emit("logoutHeader", 1);
            // this.$refs.steps.getactive();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.icons {
  cursor: pointer;
}
.btns {
  margin: 20px auto 0;
  text-align: center;
}
.cont {
  margin-top: 20px;
}
.flex {
  display: flex;
  align-items: center;
}
.flexs {
  display: flex;
  justify-content: space-around;
}
.title {
  text-align: center;
  margin: 10px 0;
}
.Application {
  justify-content: right;
  margin-bottom: 5px;
}
.border {
  border: 1px solid black;
  width: 100%;
  padding: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep .el-upload {
  border: 1px solid #ccc;
}
::v-deep .el-date-editor .el-range-separator {
  padding: 0;
}
.demo-ruleForm {
  margin-top: 20px;
}
</style>