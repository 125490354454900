<template>
  <div class="index center">
    <!-- 轮播图 -->
    <div class="banner center">
      <el-carousel ref="banner" :interval="5000" arrow="never">
        <el-carousel-item v-for="(item, i) in bannerList" :key="i">
          <div @click="open(item.jump_url)">
            <el-image
              class="img_item"
              :src="item.banner_img"
              v-if="item.banner_img"
            >
              <div slot="error" style="height: 100%" class="image-slot">
                <div class="error_img"></div>
              </div>
            </el-image>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 累计交易量 -->
    <div class="TextClass">
      <el-carousel
        height="80px"
        direction="vertical"
        :autoplay="true"
        indicator-position="none"
      >
        <el-carousel-item v-for="(item, index) in TextList" :key="index">
          <!-- <img :src="item.src" alt="" v-if="item.src" /> -->
          <div v-if="item.src">
            <div class="business center">
              <div class="itemAll">
                <div class="business_item">
                  <div style="margin-top: 11px; margin-bottom: 5px">
                    <img src="../../assets/home_img/1.png" alt="" />
                  </div>
                  <div style="display: flex; align-items: center">
                    <div style="font-size: 14px">累计交易数量</div>
                    <div
                      style="
                        margin-left: 19px;
                        font-size: 20px;
                        font-weight: bolder;
                      "
                      v-if="gmv.totalCount"
                    >
                      <span>{{
                        Math.round(Number(gmv.totalCount) / 10000 + 720)
                      }}</span>
                      <span class="mini"> 万吨</span>
                    </div>
                    <div v-else style="color: #fff; font-size: 18px">
                      暂无数据
                    </div>
                  </div>
                </div>
                <div class="business_item">
                  <div style="margin-top: 10px; margin-bottom: 4px">
                    <img src="../../assets/home_img/3.png" alt="" />
                  </div>
                  <div style="display: flex; align-items: center">
                    <div style="font-size: 14px">累计交易金额</div>
                    <div
                      style="
                        margin-left: 19px;
                        font-size: 20px;
                        font-weight: bolder;
                      "
                      v-if="gmv.totalPrice"
                    >
                      <span>{{
                        Math.round(Number(gmv.totalPrice) / 10000 + 206200)
                      }}</span>
                      <span class="mini"> 万元</span>
                    </div>
                    <div v-else style="color: #fff; font-size: 18px">
                      暂无数据
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 class="medium">{{ item.content }}</h3>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="priceIndex-box">
      <div class="priceIndex-box_left" @click="isShow = true">
        <PriceIndex></PriceIndex>
      </div>
      <div class="priceIndex-box_right">
        <img class="bg" src="@/assets/priceindex_img/i3.png" alt="" />
        <div class="detail_btn" @click="$router.push('/priceIndex')">
          <img src="@/assets/priceindex_img/icon_arrow.png" class="arrow" />
          <p class="priceIndex-box_btn">点击查看详情</p>
        </div>
      </div>
      <el-dialog :visible.sync="isShow" fullscreen class="my-dialog">
        <div class="priceIndex-dialog-box">
          <PriceIndex :type="2"></PriceIndex>
        </div>
      </el-dialog>
    </div>
    <!-- <div class="business center">
      <div class="itemAll">
        <div class="business_item">
          <div style="margin-top: 11px; margin-bottom: 5px">
            <img src="../../assets/home_img/1.png" alt="" />
          </div>
          <div style="display: flex; align-items: center">
            <div style="font-size: 14px">累计交易数量</div>
            <div
              style="margin-left: 19px; font-size: 20px; font-weight: bolder"
              v-if="gmv.totalCount"
            >
              <span>{{ gmv.totalCount }}</span>
              <span class="mini"> 吨</span>
            </div>
            <div v-else style="color: #fff; font-size: 18px">暂无数据</div>
          </div>
        </div>
        <div class="business_item">
          <div style="margin-top: 10px; margin-bottom: 4px">
            <img src="../../assets/home_img/3.png" alt="" />
          </div>
          <div style="display: flex; align-items: center">
            <div style="font-size: 14px">累计交易金额</div>
            <div
              style="margin-left: 19px; font-size: 20px; font-weight: bolder"
              v-if="gmv.totalPrice"
            >
              <span>{{ gmv.totalPrice }}</span>
              <span class="mini"> 元</span>
            </div>
            <div v-else style="color: #fff; font-size: 18px">暂无数据</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="list_listall">
      <!-- 挂牌销售列表 -->

      <!-- 挂牌采购列表 -->
      <!-- <div class="market_list center">
      <div class="flex_box headbox">
        <div class="boder_bottom">挂牌采购</div>
        <div class="more" @click="tomall(1, 2, 'shinglemarket')" style="cursor: pointer">
          更多+
        </div>
      </div>
      <div class="list_box" v-if="purchaseList.length > 0">
        <div :key="i" class="items" v-for="(item, i) in purchaseList">
          <div class="item-title f-hide item_type" style="display: flex; justify-content: space-between">
            <el-popover placement="top-start" title="" width="200" trigger="hover" :content="item.enterpriseName">
              <div slot="reference" style="
                  width: 220px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                ">
                {{ item.enterpriseName }}
              </div>
            </el-popover>
            <div class="btn_in">
              <el-button type="primary" size="mini" @click="todetail(item.goodsId, 1, 2)">查看详情</el-button>
            </div>
          </div>
          <div class="info">
            <div class="info-left">
              <p class="f-hide">{{ item.goodsName }}</p>
              <p class="f-hide">{{ item.stockCount }}吨</p>
            </div>
            <div class="price" style="color: red; font-weight: bold" :class="[+item.price == 0 ? 'no-price' : '']">
              {{ item.price | priceFilter }}
            </div>
          </div>
          <div class="indicator f-hide2" v-if="item.title">
            <el-tooltip class="item" effect="dark" :content="item.title" placement="top-start">
              <span>
                <span class="indicator_span">商品指标：{{ item.title }}</span></span>
            </el-tooltip>
          </div>
          <div class="origin f-hide2" style="display: flex">
            <i class="hy-icon-shouhuodizhi"></i>
            <span style="
                width: 164px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
              ">
              {{ item.originPlace }}{{ item.originPlaceDetail }}</span>
          </div>
        </div>
      </div>
      <div class="nodataImg" style="margin-top: 20px" v-else>
        <img src="../../assets/zxx_img/nodata.png" alt="" />
      </div>
    </div> -->
      <!-- 竞价销售 -->
      <div class="market center" style="margin-top: 34px">
        <div class="right-freight" @click="freightEntrance">
          <div class="freight-title">
            运费计算
            <div class="line"></div>
          </div>
          <div class="freight-check">点击进入&gt;</div>
        </div>
        <div class="Listings" style="">
          <div style="font-size: 24px">竞价交易</div>
          <div style="margin-top: 17px">灵活反应市场，提升资源价值</div>
          <div style="margin-top: 80px">
            <div>
              ·
              <span
                style="margin-left: 10px; cursor: pointer"
                @click="JCXSClick"
                >竞价销售</span
              >
            </div>
            <div style="margin-top: 10px">
              ·<span
                style="margin-left: 13px; cursor: pointer"
                @click="JJCGClick"
                >竞价采购</span
              >
            </div>

            <div style="margin-top: 10px" v-if="logisticsModule === 'true'">
              ·<span
                style="margin-left: 13px; cursor: pointer"
                @click="tomallClick"
                >物流竞价</span
              >
            </div>
          </div>
        </div>
        <div class="market_left">
          <div class="flex_box headbox">
            <div class="boder_bottom">
              <div
                @click="handleSelects(1)"
                :class="activeIndexs == 1 ? 'active_on' : ''"
              >
                竞价销售
              </div>
              <div
                @click="handleSelects(2)"
                :class="activeIndexs == 2 ? 'active_on' : ''"
                class="caigou"
              >
                竞价采购
              </div>
              <div
                @click="handleSelects(3)"
                :class="activeIndexs == 3 ? 'active_on' : ''"
                class="caigou"
                v-if="logisticsModule === 'true'"
              >
                物流竞价
              </div>
            </div>
            <div
              v-if="activeIndexs != 3"
              class="mores"
              style="cursor: pointer; font-size: 14px; line-height: 48px"
              @click="tomalls(2, 1, 'biddingmarket')"
            >
              查看更多
            </div>

            <div
              v-else
              class="mores"
              style="cursor: pointer; font-size: 14px; line-height: 48px"
              @click="tomallss()"
            >
              查看更多
            </div>
          </div>
          <div v-if="biddingXList.length > 0 && activeIndexs == 1">
            <el-table
              :data="biddingXList"
              @cell-click="ItemClick"
              stripe
              style="width: 100%"
            >
              <el-table-column
                align="center"
                :key="i"
                show-overflow-tooltip
                v-for="(item, i) in tableList"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
              >
                <template slot-scope="scope">
                  <span
                    v-if="item.prop == 'price'"
                    style="color: #d32621; font-weight: 600"
                    >{{ scope.row.price }}</span
                  >
                  <span
                    class="item_Op"
                    v-if="item.prop == 'goodsStatus'"
                    :style="{
                      color:
                        scope.row.goodsStatus == '进行中'
                          ? '#4DC727'
                          : scope.row.goodsStatus == '未开始'
                          ? '#FF0000'
                          : '#FF8F12',
                    }"
                    >{{ scope.row.goodsStatus }}</span
                  >
                  <span
                    v-if="item.prop != 'goodsStatus' && item.prop != 'price'"
                    style="color: #333333; font-weight: 600; font-size: 15px"
                  >
                    {{ scope.row[item.prop] }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="biddingTList.length > 0 && activeIndexs == 2">
            <el-table
              @cell-click="ItemClick"
              :data="biddingTList"
              stripe
              style="width: 100%"
            >
              <el-table-column
                align="center"
                :key="i"
                v-for="(item, i) in tableList"
                :prop="item.prop"
                show-overflow-tooltip
                :label="item.label"
                :width="item.width"
              >
                <template slot-scope="scope">
                  <span
                    v-if="item.prop == 'price'"
                    style="color: #d32621; font-weight: 600"
                    >{{ scope.row.price }}</span
                  >
                  <span
                    class="item_Op"
                    v-if="item.prop == 'goodsStatus'"
                    :style="{
                      color:
                        scope.row.goodsStatus == '进行中'
                          ? '#4DC727'
                          : scope.row.goodsStatus == '未开始'
                          ? '#FF0000'
                          : '#FF8F12',
                    }"
                    >{{ scope.row.goodsStatus }}</span
                  >
                  <span
                    v-if="item.prop != 'goodsStatus' && item.prop != 'price'"
                    style="color: #000000; font-weight: 600; font-size: 15px"
                  >
                    {{ scope.row[item.prop] }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 物流竞价table -->
          <div
            v-if="
              logisticsList.length > 0 &&
              activeIndexs == 3 &&
              logisticsModule === 'true'
            "
          >
            <el-table
              :data="logisticsList"
              stripe
              style="width: 100%"
              @cell-click="ItemClicks"
            >
              <el-table-column
                align="center"
                :key="i"
                v-for="(item, i) in logisList"
                :prop="item.prop"
                show-overflow-tooltip
                :label="item.label"
                :width="item.width"
              >
                <template slot-scope="scope">
                  <span
                    v-if="item.prop == 'price'"
                    style="color: #d32621; font-weight: 600"
                    >{{ scope.row.price }}</span
                  >
                  <span
                    class="item_Op"
                    v-if="item.prop == 'goodsStatus'"
                    :style="{
                      color:
                        scope.row.goodsStatus == '进行中'
                          ? '#4DC727'
                          : scope.row.goodsStatus == '未开始'
                          ? '#FF0000'
                          : '#FF8F12',
                    }"
                    >{{ scope.row.goodsStatus }}</span
                  >
                  <span
                    v-if="item.prop != 'goodsStatus' && item.prop != 'price'"
                    style="color: #000000; font-weight: 600; font-size: 13px"
                  >
                    {{ scope.row[item.prop] }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div
            style="
              border-radius: 8px 0px 0px 8px;
              display: flex;
              background: #fff;
            "
          >
            <div style="background: #7343d1; border-radius: 9px 0px 0px 9px">
              <!-- <div
                :class="InActive == 1 ? 'active_2' : 'actives'"
                @click="activeClick(1)"
              >
                <div
                  v-show="InActive != 1"
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 32px;
                    background-color: #fff;
                  "
                ></div>
                <div
                  v-show="InActive == 1"
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 32px;
                    background-color: #7343d1;
                  "
                ></div>
                <div
                  style="margin-left: 13px; cursor: pointer; font-size: 15px"
                >
                  竞价公告
                </div>
              </div> -->
              <div
                v-if="computedLoginAndAuthentication"
                :class="InActive == 2 ? 'active_2' : 'actives'"
                @click="activeClick(2)"
              >
                <div
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 32px;
                    background-color: #fff;
                  "
                ></div>
                <div
                  v-show="InActive == 2"
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 32px;
                    background-color: #7343d1;
                  "
                ></div>
                <div
                  style="
                    cursor: pointer;
                    font-size: 15px;
                    width: 80px;
                    line-height: 62px;
                  "
                >
                  中标公告
                </div>
              </div>
              <!-- <div
                :class="InActive == 3 ? 'active_2' : 'actives'"
                v-if="logisticsModule === 'true'"
                @click="activeClick(3)"
              >
                <div
                  v-show="InActive != 3"
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 32px;
                    background-color: #fff;
                  "
                ></div>
                <div
                  v-show="InActive == 3"
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 32px;
                    background-color: #7343d1;
                  "
                ></div>
                <div
                  style="margin-left: 13px; cursor: pointer; font-size: 15px"
                >
                  物流竞价
                </div>
              </div> -->
            </div>
            <!-- <div class="list_all" v-if="InActive == 1">
              <div
                v-for="(item, i) in biddingList"
                :key="i"
                style="margin-left: 28px"
                @click="
                  tobiddingdeatil('bidding', item.messageId, 'biddingnotice')
                "
              >
                <div class="jingjia_on">竞价</div>
                <div class="jingjia_in">
                  <el-popover
                    placement="top-start"
                    title=""
                    width="203"
                    trigger="hover"
                    :content="item.messageContent"
                  >
                    <div slot="reference" class="messageCont">
                      {{ item.messageContent }}
                    </div>
                  </el-popover>
                </div>
                <div style="margin-top: 6px; color: #ccc">
                  {{ item.createTime }}
                </div>
              </div>
              <div
                class="gengduo"
                v-if="biddingList.length > 0"
                @click="toannouncement('bidding', 'biddingnotice')"
              >
                查看更多
              </div>
            </div> -->
            <div class="list_all" v-if="computedLoginAndAuthentication">
              <div
                v-for="(item, i) in winList"
                :key="i"
                style="margin-left: 28px"
                @click="tobiddingdeatil('win', item.messageId, 'winnotice')"
              >
                <div class="index_info">中标</div>
                <div class="index_infos">
                  <!-- {{ item.messageContent }} -->
                  <el-popover
                    placement="top-start"
                    title=""
                    width="203"
                    trigger="hover"
                    :content="item.messageContent"
                  >
                    <div class="index_p" slot="reference">
                      {{ item.messageContent }}
                    </div>
                  </el-popover>
                </div>
                <div style="margin-top: 6px; color: #ccc">
                  {{ item.createTime }}
                </div>
              </div>

              <div
                class="gengduo"
                v-if="winList.length > 0"
                @click="toannouncement('win', 'winnotice')"
              >
                查看更多
              </div>
            </div>

            <!-- <div class="list_all" v-if="InActive == 3">
              <div
                v-for="(item, i) in BiddingNotice"
                :key="i"
                style="margin-left: 28px"
                @click="
                  tobiddingdeatil('bidding', item.messageId, 'biddingnotice')
                "
              >
                <div class="jingjia_on">竞价</div>
                <div class="jingjia_in">
                  <el-popover
                    placement="top-start"
                    title=""
                    width="203"
                    trigger="hover"
                    :content="item.messageContent"
                  >
                    <div slot="reference" class="messageCont">
                      {{ item.messageContent }}
                    </div>
                  </el-popover>
                </div>
                <div style="margin-top: 6px; color: #ccc">
                  {{ item.createTime }}
                </div>
              </div>
              <div
                v-if="BiddingNotice.length > 0"
                class="gengduo"
                @click="toannouncement('biddings', 'biddingnotice')"
              >
                查看更多
              </div>
            </div> -->
          </div>
          <!-- <div class="nodataImg" v-else>
          <img src="../../assets/zxx_img/nodata.png" alt="" />
        </div> -->
        </div>
        <!-- <div class="market_right market_righton" style="max-width: 283px">
        <div class="flex_box" style="display: flex; justify-content: center">
          <div class="blue_border" style="margin-top: 5px">竞价公告</div>
        </div>
        <div style="text-align: center" v-if="biddingList.length > 0">
          <div @click="tobiddingdeatil('bidding', item.messageId, 'biddingnotice')" :key="i"
            v-for="(item, i) in biddingList" style="height: 42px; cursor: pointer" class="flex_box">
            <span class="title2">{{ item.messageContent }}</span>
            <span class="data2">{{ item.createTime }}</span>
          </div>
          <div style="cursor: pointer" class="more_tree" @click="toannouncement('bidding', 'biddingnotice')">
            查看更多>>
          </div>
        </div>
        <div class="nodataImg" v-else>
          <img src="../../assets/zxx_img/nodata.png" alt="" />
        </div>
      </div> -->
      </div>
      <div class="market_list center" style="margin-top: 20px">
        <div class="Listingss" style="margin-right: 20px">
          <div style="font-size: 24px">邀约交易</div>
          <div style="margin-top: 17px">更精准的交易服务</div>
          <div style="margin-top: 80px">
            <div>
              ·
              <span
                style="margin-left: 10px; cursor: pointer"
                @click="InvitationClick"
                >邀约信息</span
              >
            </div>
          </div>
        </div>
        <div class="Invitation">
          <div class="flex_box headbox">
            <div class="boder_bottom">
              <div
                @click="handleSelect(1)"
                :class="activeIndex == 1 ? 'active_on' : ''"
              >
                邀约交易
              </div>
            </div>
            <div
              class="more"
              @click="InvitationClick"
              style="cursor: pointer; font-size: 14px; line-height: 48px"
            >
              查看更多
            </div>
          </div>
          <div class="list_box" v-if="InviteList.length > 0">
            <div :key="i" class="items" v-for="(item, i) in InviteList">
              <div style="display: flex; justify-content: space-between">
                <p
                  class="f-hide"
                  style="font-size: 16px; font-weight: bold; width: 200px"
                >
                  <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                  >
                    <span
                      v-for="(goodsName, index) in item.goodsCategoryList"
                      :key="index"
                      >{{ goodsName.goodsName }} &nbsp;
                      {{
                        index == item.goodsCategoryList.length - 1 ? "" : "|"
                      }}&nbsp;</span
                    >
                    <div
                      slot="reference"
                      style="
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-size: 16px;
                        font-weight: bold;
                        width: 200px;
                      "
                    >
                      <span
                        v-for="(goodsName, index) in item.goodsCategoryList"
                        :key="index"
                        >{{ goodsName.goodsName }} &nbsp;
                        {{
                          index == item.goodsCategoryList.length - 1 ? "" : "|"
                        }}&nbsp;</span
                      >
                    </div>
                  </el-popover>
                </p>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 4px;
                  color: #c5c5c5;
                "
              >
                <p style="font-size: 16px; color: #d32621">
                  <span style="font-size: 14px">{{
                    item.viewSet && item.viewSet.viewPrice != 1
                      ? "***元/吨"
                      : item.goodsCategoryList.length == 1
                      ? item.goodsCategoryList[0].goodsPrice + "元/吨"
                      : "详情查看价格信息"
                  }}</span>
                </p>
                <p>
                  {{
                    item.viewSet && item.viewSet.viewCount == 0
                      ? "***"
                      : item.ifConsultRealityCount == 0
                      ? item.initialCount + "吨"
                      : "以实际结算数量为准"
                  }}
                </p>
              </div>

              <div style="display: flex; justify-content: space-between">
                <div class="jiaoyi_c">
                  价格类型：{{ item.taxIncluded | taxIncluded }}
                  {{ item.priceType | priceType }}
                </div>
                <div class="jiaoyi_c">
                  配送方式：{{ item.deliveryMode | deliveryMode }}
                </div>
              </div>
              <div>
                <div
                  class="item-title f-hide item_type"
                  style="display: flex; justify-content: space-between"
                >
                  <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                    :content="item.initiatorName"
                  >
                    <div
                      slot="reference"
                      style="
                        width: 220px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-size: 14px;
                      "
                    >
                      {{ item.initiatorName }}
                    </div>
                  </el-popover>
                  <div class="btn_ins">
                    <el-button
                      type="warning"
                      size="mini"
                      @click="otherdetail(item)"
                      >查看详情</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="nodataImg" v-else style="margin-top: 20px">
            <img src="../../assets/zxx_img/nodata.png" alt="" />
          </div>
        </div>
      </div>
      <div class="market_list center" style="margin-top: 20px">
        <div class="Listing" style="margin-right: 20px">
          <div style="font-size: 24px">挂牌交易</div>
          <div style="margin-top: 17px">透明、公正、高效的交易方式</div>
          <div style="margin-top: 80px">
            <div>
              ·
              <span style="margin-left: 10px; cursor: pointer" @click="GPXSlick"
                >挂牌销售</span
              >
            </div>
            <div style="margin-top: 10px">
              ·<span
                style="margin-left: 13px; cursor: pointer"
                @click="GPCGclick"
                >挂牌采购</span
              >
            </div>
          </div>
        </div>
        <div>
          <div class="flex_box headbox">
            <div class="boder_bottom">
              <!-- <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item index="1">挂牌销售</el-menu-item>
              <el-menu-item index="2">挂牌采购</el-menu-item>
            </el-menu> -->
              <div
                @click="handleSelect(1)"
                :class="activeIndex == 1 ? 'active_on' : ''"
              >
                挂牌销售
              </div>
              <div
                @click="handleSelect(2)"
                :class="activeIndex == 2 ? 'active_on' : ''"
                class="caigou"
              >
                挂牌采购
              </div>
            </div>
            <div
              class="more"
              @click="tomall(1, activeIndex, 'shinglemarket')"
              style="cursor: pointer; font-size: 14px; line-height: 48px"
            >
              查看更多
            </div>
          </div>
          <div class="list_box" v-if="gplist.length > 0">
            <div :key="i" class="items" v-for="(item, i) in gplist">
              <div style="display: flex; justify-content: space-between">
                <p
                  class="f-hide"
                  style="font-size: 16px; font-weight: bold; width: 200px"
                >
                  <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                  >
                    <span
                      v-for="(goodsName, index) in item.goodsCategoryList"
                      :key="index"
                      >{{ goodsName.goodsName }} &nbsp;
                      {{
                        index == item.goodsCategoryList.length - 1 ? "" : "|"
                      }}&nbsp;</span
                    >
                    <div
                      slot="reference"
                      style="
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-size: 16px;
                        font-weight: bold;
                        width: 200px;
                      "
                    >
                      <span
                        v-for="(goodsName, index) in item.goodsCategoryList"
                        :key="index"
                        >{{ goodsName.goodsName }} &nbsp;
                        {{
                          index == item.goodsCategoryList.length - 1 ? "" : "|"
                        }}&nbsp;</span
                      >
                    </div>
                  </el-popover>
                </p>
                <p style="font-size: 12px; color: #c5c5c5">
                  <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                  >
                    <div
                      v-for="(goods, index) in item.goodsCategoryList"
                      :key="goods.goodsName + index"
                    >
                      <span>{{ goods.goodsName }}:</span>
                      <span
                        v-for="(quota, quotaIndex) in goods.quotas"
                        :key="quota.name + quotaIndex"
                        >{{
                          quota.name +
                          quota.unit.split(";")[2] +
                          quota.value +
                          quota.unit.split(";")[1] +
                          ","
                        }}</span
                      >
                    </div>
                    <div
                      slot="reference"
                      style="
                        width: 118px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                      "
                    >
                      {{ computedQuotas(item.goodsCategoryList) }}
                    </div>
                  </el-popover>
                </p>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 4px;
                  color: #c5c5c5;
                "
              >
                <p style="font-size: 16px; color: #d32621">
                  <span style="font-size: 14px">{{
                    item.goodsCategoryList | priceFilter
                  }}</span>
                  <span
                    style="font-size: 12px"
                    v-if="item.goodsCategoryList.length == 1"
                    >元/吨</span
                  >
                </p>
                <p>
                  {{
                    item.ifConsultRealityCount == 0
                      ? item.stockCount
                      : "以实际结算数量为准"
                  }}吨
                </p>
              </div>
              <div>
                <div
                  class="origin f-hide2"
                  style="
                    display: flex;
                    font-size: 12px;
                    background: #f2f4ff;
                    padding: 8px 6px;
                  "
                >
                  <!-- <i class="hy-icon-shouhuodizhi"></i> -->
                  <img
                    src="../../assets/home_img/9.png"
                    alt=""
                    style="width: 12px; height: 14px; margin-top: 1px"
                  />
                  <span
                    style="
                margin-left:5px
                    width: 356px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                  "
                  >
                    {{ item.originPlace }}{{ item.originPlaceDetail }}</span
                  >
                </div>
              </div>

              <div>
                <div
                  class="item-title f-hide item_type"
                  style="display: flex; justify-content: space-between"
                >
                  <el-popover
                    placement="top-start"
                    title=""
                    width="200"
                    trigger="hover"
                    :content="item.enterpriseName"
                  >
                    <div
                      slot="reference"
                      style="
                        width: 220px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-size: 14px;
                      "
                    >
                      {{ item.enterpriseName }}
                    </div>
                  </el-popover>
                  <div class="btn_in">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="todetail(item.goodsId, 1, activeIndex)"
                      >查看详情</el-button
                    >
                  </div>
                </div>
              </div>

              <!-- <div
              class="item-title f-hide item_type"
              style="display: flex; justify-content: space-between"
            >
              <el-popover
                placement="top-start"
                title=""
                width="200"
                trigger="hover"
                :content="item.enterpriseName"
              >
                <div
                  slot="reference"
                  style="
                    width: 220px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  <i
                    class="el-icon-office-building"
                    style="font-size: 18px; color: #3988ef"
                  ></i>
                  {{ item.enterpriseName }}
                </div>
              </el-popover>
              <div>
                <el-button
                  type="primary"
                  size="mini"
                  @click="todetail(item.goodsId, 1, 1)"
                  >查看详情</el-button
                >
              </div>
            </div>
            <div class="info">
              <div
                class="info-left"
                style="display: flex; font-size: 14px; align-items: baseline"
              >
                <p class="f-hide" style="font-size: 16px; font-weight: bold">
                  {{ item.goodsName }}
                </p>
                <p
                  class="f-hide"
                  style="margin-left: 10px; color: red; font-weight: bold"
                >
                  {{ item.stockCount }}吨
                </p>
              </div>
              <div
                class="price"
                style="color: red; font-weight: bold"
                :class="[+item.price == 0 ? 'no-price' : '']"
              >
                {{ item.price | priceFilter }}
              </div>
            </div>
            <div class="indicator f-hide2" v-if="item.title">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.title"
                placement="top-start"
              >
                <span>
                  <span class="indicator_span"
                    >商品指标：{{ item.title }}</span
                  ></span
                >
              </el-tooltip>
            </div>
            <div class="origin f-hide2" style="display: flex">
              <i class="hy-icon-shouhuodizhi"></i>
              <span
                style="
                  width: 356px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  display: inline-block;
                "
              >
                {{ item.originPlace }}{{ item.originPlaceDetail }}</span
              >
            </div> -->
            </div>
          </div>

          <div class="nodataImg" v-else style="margin-top: 20px">
            <img src="../../assets/zxx_img/nodata.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 运费入口 -->
      <!-- <div class="freight" v-if="testModuls === 'true' ? true : false">
        <div class="freightButton" @click="freightEntrance">点击进入></div>
      </div> -->
    </div>
    <!-- 招商服务 -->
    <div class="partner">
      <div class="partner_on">
        <div class="partner_tw">
          <div class="partner_title">合作伙伴</div>
          <div class="partner_title2">
            新疆煤炭交易中心与合作伙伴一起建设共赢煤炭交易生态圈
          </div>
        </div>
        <div class="panter_list">
          <div
            @click="toPartner(item.jump_url)"
            class="partner_item"
            :key="i"
            v-for="(item, i) in partnerList"
          >
            <el-image
              style="width: 180px; height: 72px"
              :src="item.partner_img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Bus from "../../api/eventBus";
Vue.prototype.$bus = Bus;
import {
  getbannerList,
  getpartnerList,
  biddingList,
  gmvdetail,
  nominal,
  getqxshuju,
  sysRotateTextList,
} from "@/api/home";
import { listTradeInvite } from "@/api/foundation";
import { listTbGoods } from "@/api/Logistics";
import { reqGetToekn } from "@/api/public";
const { baseUrl } = require("@/config/index.js");
import { mapState, mapActions } from "vuex";
import * as types from "@/store/action-types";
import PriceIndex from "./components/priceIndex.vue";
export default {
  data() {
    return {
      isShow: false,
      testModuls: process.env.VUE_APP_OPEN_TEST_MODULS,
      logisticsModule: process.env.VUE_APP_OPEN_LOGISTICS,
      TextList: [],
      InviteList: [],
      InActive: 1,
      activeIndex: "1",
      activeIndexs: "1",
      checked: "首页",
      userId: 0,
      busData:
        this.$bus.busData === ""
          ? (this.busData = this.$bus.busStorageData)
          : (this.busData = this.$bus.busData),
      navDel: {},
      bannerList: [{ banner_name: "" }],
      menuList: [
        {
          title: "首页",
          path: "/",
          limit: "all",
        },
        {
          title: "挂牌采购",
          path: "/mall",
          query: {
            selectTradeType: 1,
            selectOrderType: 2,
            title: "挂牌采购",
          },
          limit: "shinglepurchase",
        },
        {
          title: "挂牌销售",
          path: "/mall",
          query: {
            selectTradeType: 1,
            selectOrderType: 1,
            title: "挂牌销售",
          },
          limit: "shinglemarket",
        },
        {
          title: "竞价销售",
          path: "/mall",
          query: {
            selectTradeType: 2,
            selectOrderType: 1,
            title: "竞价销售",
          },
          limit: "biddingmarket",
        },
        {
          title: "竞价采购",
          path: "/mall",
          query: {
            selectTradeType: 2,
            selectOrderType: 2,
            title: "竞价采购",
          },
          limit: "biddingpurchase",
        },
        {
          title: "金融保理",
          path: "/financial",
          id: "14",
          query: {
            id: "14",
            title: "金融保理",
          },
          limit: "financial",
        },
        {
          title: "物流服务",
          path: "/financial",
          query: {
            id: "15",
            title: "物流服务",
          },
          limit: "logistics",
        },
        {
          title: "关于我们",
          path: "/aboutus",
          query: {
            id: "16",
            title: "关于我们",
          },
          limit: "aboutus",
        },
        {
          title: "中长期合同",
          path: "/MediumData",
          query: {
            id: "17",
            title: "中长期合同",
          },
          limit: "MediumData",
        },
      ],
      gplist: [],
      logisticsList: [],
      purchaseList: [],
      salesList: [],
      gmv: {},
      biddingXList: [],
      biddingTList: [],
      biddingList: [],
      BiddingNotice: [],
      winList: [],
      partnerList: [],
      logisList: [
        {
          prop: "goodsName",
          width: "",
          label: "货物名称",
        },
        {
          prop: "floorPrice",
          width: "",
          label: "竞价限价(元/吨)",
        },
        {
          prop: "monthFreightVolume",
          width: "",
          label: "发运量(吨)",
        },
        {
          prop: "lineName",
          width: "",
          label: "线路名称",
        },
        {
          prop: "transportDistance",
          width: "",
          label: "运距(公里)",
        },
        {
          prop: "biddingStartTime",
          width: "",
          label: "竞价开始时间",
        },
        {
          prop: "biddingEndTime",
          width: "",
          label: "竞价结束时间",
        },
        {
          prop: "biddingStatus",
          width: "",
          label: "状态",
        },
      ],
      tableList: [
        {
          prop: "goodsName",
          width: "",
          label: "商品名称",
        },
        {
          prop: "price",
          width: "120",
          label: "竞价底价(元)",
        },
        {
          prop: "stockCount",
          width: "100",
          label: "数量(吨)",
        },
        {
          prop: "deliveryPlace",
          width: "200",
          label: "交割地点",
        },
        {
          prop: "enterpriseName",
          width: "",
          label: "供应单位",
        },
        {
          prop: "biddingStartTime",
          width: "116",
          label: "竞价开始时间",
        },
        {
          prop: "biddingEtartTime",
          width: "116",
          label: "竞价结束时间",
        },
        {
          prop: "goodsStatus",
          width: "",
          label: "状态",
        },
      ],
      // regionData: regionData,
    };
  },
  components: {
    PriceIndex,
  },
  created() {
    this.gettoken();
    this.userId = Vue.ls.get("userId") || 0;
  },
  mounted() {
    this.jurisdiction();
  },
  filters: {
    deliveryMode(val) {
      switch (val) {
        case 1:
          return "送货";
          break;
        case 2:
          return "自提";
          break;
      }
    },
    taxIncluded(val) {
      let arr = ["含税", "不含税"];
      return arr[val];
    },
    priceType(val) {
      switch (val) {
        case 1:
          return "坑口价";
          break;
        case 2:
          return "出厂价";
          break;
        case 3:
          return "到港价";
          break;
        case 4:
          return "平仓价";
          break;
        case 5:
          return "车板价";
          break;
        case 6:
          return "到厂价";
          break;
      }
    },
    priceFilter(val) {
      if (val.length > 1) return "详情查看价格信息";
      if (val[0].goodsPrice == 0) return "￥面议";
      return `${val[0].goodsPrice}`;
    },
    goodsStatus(val) {
      switch (val) {
        case 0:
          return "未开始";
          break;
        case 1:
          return "进行中";
          break;
        case 2:
          return "已流拍";
          break;
        case 3:
          return "有中标";
          break;
        case 4:
          return "已取消";
          break;
      }
    },
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      refreshToken: (state) => state.refreshToken,
      permission: (state) => state.user.permission,
      massage: (state) => state.user.massage,
    }),
    computedQuotas() {
      return (val) => {
        if (val.length == 0) return "";
        let quotaValue = "";
        val.forEach((item) => {
          if (item.quotas?.length) {
            item.quotas.forEach((quota) => {
              quotaValue +=
                quota.name +
                quota.unit.split(";")[2] +
                quota.value +
                quota.unit.split(";")[1] +
                ",";
            });
          }
        });
        return quotaValue;
      };
    },
    computedLoginAndAuthentication() {
      let status =
        this.massage == "未登录请前往登录" ||
        this.massage == "请前往认证后操作";
      return !status;
    },
  },

  methods: {
    sysRotateTextList() {
      let config = {
        ifOpen: 0,
        pageSize: 10,
        pageNum: 1,
      };
      sysRotateTextList(config).then((res) => {
        if (res.code == 0) {
          let list = res.data.list;
          // list[0].src = require("../../assets/home_img/1.png");
          let newdata = {
            src: require("../../assets/home_img/1.png"),
          };
          list.unshift(newdata);
          this.TextList = res.data.list;
        }
      });
    },
    //运费入口
    freightEntrance() {
      this.$router.push({
        path: "/railway",
        query: {
          type: 1,
        },
      });
    },

    activeClick(e) {
      this.InActive = e;
    },
    handleSelect(key) {
      this.activeIndex = key;
      // this.salesList
      // purchaseList 采购
      if (key == 1) {
        this.gplist = this.salesList;
      } else {
        this.gplist = this.purchaseList;
      }
    },
    handleSelects(key) {
      // 1:salesList       1  1
      // 2:purchaseList     1 2
      this.activeIndexs = key;
    },
    ...mapActions([types.GET_TOKEN]),
    // 权限操作
    jurisdiction() {
      window.localStorage.setItem("massage", "");
      let token = window.localStorage.getItem("token");
      if (!token) {
        window.localStorage.setItem("massage", "未登录请前往登录");
      } else {
        this.everything();
      }
    },
    ItemClicks(item) {
      if (this.userId == item.publisherId) {
        this.$router.push({
          path: "/logisticsDetailss",
          query: {
            goodsId: item.goodsId,
          },
        });
      } else {
        this.$router.push({
          path: "/logisticsDetails",
          query: {
            goodsId: item.goodsId,
          },
        });
      }
    },

    ItemClick(item) {
      this.$router.push({
        path: "/goodsDetail",
        query: {
          goodsId: item.goodsId,
          selectTradeType: item.tradeType,
          selectOrderType: item.orderType,
        },
      });
    },
    async everything() {
      await getqxshuju().then((res) => {
        if (res.code == 0) {
          if (res.data) {
            res.data.auditStatus !== 1 &&
            res.data.auditStatus !== -1 &&
            res.data.auditStatus !== 2
              ? window.localStorage.setItem(
                  "massage",
                  "您的企业信息正在审核中请耐心等待"
                )
              : res.data.auditStatus == -1 || res.data.auditStatus == 2
              ? window.localStorage.setItem("massage", "请前往认证后操作")
              : "";
          } else {
            window.localStorage.setItem("massage", "请前往认证后操作");
          }
        }
      });
    },
    tomallClick() {
      this.$router.push({
        path: "/LogisticsBidding",
      });
    },
    tomallss(selectTradeType, selectOrderType, limi) {
      this.$router.push({
        path: "/mall",
        query: {
          selectTradeType,
          selectOrderType,
        },
      });
    },
    InvitationClick() {
      this.$router.push({
        path: "/Invitation",
        query: {
          type: 3,
        },
      });
    },
    otherdetail(item) {
      // console.log(item);
      let enterpriseId = Vue.ls.get(types.enterpriseId);
      if (!enterpriseId) {
        this.$message({
          message: "请先登录",
          type: "error",
        });
      } else {
        Vue.ls.set("ItemOn", item);
        this.$router.push({
          path: "/InvitationDetailed",
          query: {
            goodsName: item.goodsName,
            goodsNum: item.goodsNum,
            receiverName: item.receiverName,
            receiverTelephone: item.receiverTelephone,
            taxIncluded: item.taxIncluded,
            priceType: item.priceType,
            listingPrice: item.listingPrice,
            initialCount: item.initialCount,
            goodsId: item.goodsId,
            // fileId: item.contractInfoList[0].fileId,
            confirmStatus: item.confirmStatus,
            inviteEnterpriseId: item.inviteEnterpriseId,
            notes: "other",
            viewSet: JSON.stringify(item.viewSet),
            ifConsultRealityCount: item.ifConsultRealityCount,
          },
        });
      }
    },
    tomall(selectTradeType, selectOrderType, limit) {
      selectOrderType = this.activeIndex;
      this.$router.push({
        path: "/mall",
        query: {
          selectTradeType,
          selectOrderType,
        },
      });
    },
    tomallss() {
      // LogisticsBidding
      this.$router.push({
        path: "/LogisticsBidding",
      });
    },
    tomalls(selectTradeType, selectOrderType, limit) {
      selectOrderType = this.activeIndexs;
      this.$router.push({
        path: "/mall",
        query: {
          selectTradeType,
          selectOrderType,
        },
      });
    },
    todetail(id, a, b) {
      this.$router.push({
        path: "/goodsDetail",
        query: {
          goodsId: id,
          selectTradeType: a,
          selectOrderType: b,
        },
      });
    },
    async gettoken() {
      const userId = Vue.ls.get(types.userId);
      // console.log(userId);
      if (!userId) {
        let config = {
          grant_type: "init",
          client_id: "xjcec",
          client_secret: "xjcec_secret",
          scope: "all",
        };
        await reqGetToekn(config).then((res) => {
          if (res.code == 0) {
            Vue.ls.set(types.ACCESS_TOKEN, res.data.accessToken);
            Vue.ls.set(types.REFRESH_TOKEN, res.data.refreshToken);
            Vue.ls.set(types.checkUkeyWhite, res.data.checkUkeyWhite);
            Vue.ls.set(types.EXPIRES_IN, res.data.expiresTime + "000");
            // let token = Vue.ls.get("token")
            this.initialization();
          }
        });
      } else {
        this.initialization();
      }
    },
    toPartner(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    toannouncement(type, limit) {
      this.$router.push({
        path: "/announcement",
        query: {
          type,
        },
      });
    },
    JCXSClick() {
      this.$router.push({
        path: "/mall",
        query: {
          selectTradeType: 2,
          selectOrderType: 1,
        },
      });
    },
    GPXSlick() {
      this.$router.push({
        path: "/mall",
        query: {
          selectTradeType: 1,
          selectOrderType: 1,
        },
      });
    },
    GPCGclick() {
      this.$router.push({
        path: "/mall",
        query: {
          selectTradeType: 1,
          selectOrderType: 2,
        },
      });
    },
    JJCGClick() {
      this.$router.push({
        path: "/mall",
        query: {
          selectTradeType: 2,
          selectOrderType: 2,
        },
      });
    },
    tobiddingdeatil(type, messageId, limit) {
      this.$router.push({
        path: "/biddingdeatil",
        query: {
          type,
          messageId,
        },
      });
    },
    tojournalism(id) {
      this.$router.push({
        path: "/journalism",
        query: {
          id,
        },
      });
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }
    },
    // MM dd
    tommdd(data) {
      return (
        "[" +
        data.split("T")[0].split("-")[1] +
        "-" +
        data.split("T")[0].split("-")[2] +
        "]"
      );
    },
    async initialization() {
      const that_ = this;

      // banner
      const res = await getbannerList({
        dictType: "banner_trade",
        pageNum: 0,
        pageSize: 5,
        bannerPosition: 1,
      });
      if (res.code == 0) {
        this.bannerList = [];
        if (res.data?.list?.length) {
          res.data.list.forEach((item) => {
            let obj = {};
            if (that_.isJSON(item.dictValue)) {
              obj = JSON.parse(item.dictValue);
              obj.banner_img =
                baseUrl + "third/third/ossGetFile/" + obj.banner_img;
              this.bannerList.push(obj);
            }
          });
        } else {
          let list = [];
          list.push({
            banner_name: " ",
            banner_img: require("@/assets/home_img/123.png"),
            jump_url: "",
          });
          this.bannerList = list;
        }
      }

      let a = document.URL;
      var str1 = a.substring(0, a.indexOf("#"));
      let originPlace = "";
      let dictLabel = null;
      if (str1 == "https://north.xjcec.com/") {
        originPlace = "新疆维吾尔族自治区,伊犁哈萨克自治州";
        dictLabel = 1;
      } else {
        originPlace = "";
        dictLabel = 0;
      }
      //  交易总额
      const gmv = await gmvdetail({
        enterpriseName: "",
        originPlace: originPlace,
      });
      if (gmv.code == 0) {
        // console.log(gmv,'gmv');
        //totalCount累计交易量 totalPrice	//累计成交额
        this.gmv = gmv.data;
      }
      // 挂牌销售

      const Sales = await nominal({
        size: 6,
        page: 1,
        releaseDateOrderBy: 1,
        selectTradeType: 1,
        selectOrderType: 1,
        endFlag: false,
        originPlace: originPlace,
      });
      if (Sales.code == 0) {
        let list = Sales.data.records;
        list.forEach((item) => {
          let a = [];

          if (item.quotas) {
            let quotas = item.quotas;
            quotas.forEach((items) => {
              a.push(
                items.name +
                  items.unit.split(";")[2] +
                  items.value +
                  items.unit.split(";")[1]
              );
            });
          }
          item.title = a.toString();
        });
        this.gplist = list;
        this.salesList = list;
      }
      // 邀约交易列表
      const Invite = await listTradeInvite({
        pageSize: 6,
        pageNum: 1,
        searchType: 3,
        enterpriseId: Vue.ls.get(types.enterpriseId),
      });
      if (Invite.code == 0) {
        this.InviteList = Invite.data.list;
        // console.log(this.InviteList);
      }
      // 挂牌采购
      const purchase = await nominal({
        size: 6,
        page: 1,
        releaseDateOrderBy: 1,
        selectTradeType: 1,
        selectOrderType: 2,
        endFlag: false,
        originPlace: originPlace,
      });
      if (purchase.code == 0) {
        let list = purchase.data.records;
        list.forEach((item) => {
          let a = [];
          if (item.quotas) {
            let quotas = item.quotas;
            quotas.forEach((items) => {
              a.push(
                items.name +
                  items.unit.split(";")[2] +
                  items.value +
                  items.unit.split(";")[1]
              );
            });
          }
          item.title = a.toString();
        });
        this.purchaseList = list;
        // this.purchaseList = purchase.data.records;
        // console.log(this.salesList,'salesList')
      }
      // 竞价销售
      const biddingX = await nominal({
        size: 9,
        page: 1,
        // releaseDateOrderBy: 1,
        ifHomePage: true,
        selectTradeType: 2,
        selectOrderType: 1,
        endFlag: false,
        originPlace: originPlace,
      });
      if (biddingX.code == 0) {
        let list = biddingX.data.records;
        for (let i = 0; i < list.length; i++) {
          if (list[i].goodsStatus == 0) {
            list[i].goodsStatus = "未开始";
          }
          if (list[i].goodsStatus == 1) {
            list[i].goodsStatus = "进行中";
          }
          if (list[i].goodsStatus == 2) {
            list[i].goodsStatus = "已流拍";
          }
          if (list[i].goodsStatus == 3) {
            list[i].goodsStatus = "有中标";
          }
          if (list[i].goodsStatus == 4) {
            list[i].goodsStatus = "已取消";
          }
        }
        this.biddingXList = list.map((item) => {
          item.price = item.goodsCategoryList.reduce((per, cur, index) => {
            return (
              per +
              (cur.goodsPrice +
                (index == item.goodsCategoryList.length - 1 ? "" : ","))
            );
          }, "");
          item.stockCount = item.goodsCategoryList.reduce((per, cur, index) => {
            return (
              per +
              (cur.number +
                (index == item.goodsCategoryList.length - 1 ? "" : ","))
            );
          }, "");
          return item;
        });
        console.log(this.biddingXList);
        // this.biddingXList = biddingX.data.records.map((item) => {
        //   return {
        //     ...item,
        //     //        switch (val) {
        //     //   case 0:
        //     //     return "未开始";
        //     //     break;
        //     //   case 1:
        //     //     return "进行中";
        //     //     break;
        //     //   case 2:
        //     //     return "已流拍";
        //     //     break;
        //     //   case 3:
        //     //     return "有中标";
        //     //     break;
        //     //   case 4:
        //     //     return "已取消";
        //     //     break;
        //     // }
        //     goodsStatus:item.goodsStatus == 0 ? "未开始": item.goodsStatus == 1? "进行中" : "已结束",

        //     // goodsStatus:
        //     //   item.goodsStatus == 0
        //     //     ? "未开始"
        //     //     : item.goodsStatus == 1
        //     //       ? "进行中"
        //     //       : "已结束",
        //   };
        // });
        // console.log(this.salesList,'salesList')
      }
      // 竞价采购
      const biddingT = await nominal({
        size: 9,
        page: 1,
        ifHomePage: true,
        selectTradeType: 2,
        selectOrderType: 2,
        endFlag: false,
        originPlace: originPlace,
      });
      if (biddingT.code == 0) {
        let list = biddingT.data.records;
        for (let i = 0; i < list.length; i++) {
          if (list[i].goodsStatus == 0) {
            list[i].goodsStatus = "未开始";
          }
          if (list[i].goodsStatus == 1) {
            list[i].goodsStatus = "进行中";
          }
          if (list[i].goodsStatus == 2) {
            list[i].goodsStatus = "已流拍";
          }
          if (list[i].goodsStatus == 3) {
            list[i].goodsStatus = "有中标";
          }
          if (list[i].goodsStatus == 4) {
            list[i].goodsStatus = "已取消";
          }
        }
        this.biddingTList = list.map((item) => {
          item.price = item.goodsCategoryList.reduce((per, cur, index) => {
            return (
              per +
              (cur.goodsPrice +
                (index == item.goodsCategoryList.length - 1 ? "" : ","))
            );
          }, "");
          item.stockCount = item.goodsCategoryList.reduce((per, cur, index) => {
            return (
              per +
              (cur.number +
                (index == item.goodsCategoryList.length - 1 ? "" : ","))
            );
          }, "");
          return item;
        });
      }
      // 物流竞价
      const biddingS = await listTbGoods({
        pageSize: 9,
        pageNum: 1,
      });
      if (biddingS.code == 0) {
        let list = biddingS.data.list;
        for (let i = 0; i < list.length; i++) {
          if (list[i].biddingStatus == 0) {
            list[i].biddingStatus = "未开始";
          }
          if (list[i].biddingStatus == 1) {
            list[i].biddingStatus = "进行中";
          }
          if (list[i].biddingStatus == 2) {
            list[i].biddingStatus = "已流拍";
          }
          if (list[i].biddingStatus == 3) {
            list[i].biddingStatus = "有中标";
          }
          if (list[i].biddingStatus == 4) {
            list[i].biddingStatus = "已取消";
          }

          if (list[i].biddingStartTime) {
            list[i].biddingStartTime = list[i].biddingStartTime.replace(
              "T",
              " "
            );
          }
          if (list[i].biddingEndTime) {
            list[i].biddingEndTime = list[i].biddingEndTime.replace("T", " ");
          }
        }
        this.logisticsList = list;
      }

      // 合作伙伴
      const partner = await getpartnerList({
        dictType: "partner",
        pageNum: 1,
        pageSize: 12,
        dictLabel: dictLabel,
      });
      if (partner.code == 0) {
        this.partnerList = [];
        partner.data.list.map((item) => {
          if (that_.isJSON(item.dictValue)) {
            let obx = JSON.parse(item.dictValue);
            item.partner_img =
              baseUrl + "third/third/ossGetFile/" + obx.partner_img;
            item.jump_url = obx.jump_url;
            this.partnerList.push(item);
          }
        });
      }
      // 公告
      const bidding = await biddingList({
        messageType: 1,
        page: 1,
        size: 4,
      });
      if (bidding.code == 0) {
        this.biddingList = bidding.data.records.map((item) => {
          return {
            ...item,
            createTime: this.tommdd(item.createTime),
          };
        });
      }

      const biddings = await biddingList({
        messageType: 3,
        page: 1,
        size: 4,
      });
      if (biddings.code == 0) {
        this.BiddingNotice = biddings.data.records.map((item) => {
          return {
            ...item,
            createTime: this.tommdd(item.createTime),
          };
        });
      }

      const win = await biddingList({
        messageType: 2,
        page: 1,
        size: 4,
      });
      if (win.code == 0) {
        this.winList = win.data.records.map((item) => {
          return {
            ...item,
            createTime: this.tommdd(item.createTime),
          };
        });
        // console.log(this.winList,'this.winList');
      }
      this.sysRotateTextList();
    },
    open(url) {
      if (url) {
        url.indexOf("http") == -1
          ? window.open("http://" + url, "_blank")
          : window.open(url, "_blank");
      }
    },
    toOtherPage(data) {
      this.checked = data.title;
      this.$router.push({
        path: data.path,
        query: data.query ? data.query : {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.list_listall {
}
.actives {
  // background-color: #7343d1;
  color: rgba(255, 255, 255, 0.75);
  padding: 15px 16px;
  display: flex;
  align-items: center;
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
}

.active_2 {
  width: 100%;
  background-color: rgb(115, 67, 209);
  font-weight: 600;
  color: rgba(255, 255, 255, 0.75);
  padding: 15px 16px;
  display: flex;
  align-items: center;
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
}

.info-left {
  .f-hide {
    & + .f-hide {
      margin-top: 5px;
    }
  }
}

.list_box:after {
  content: "";
  width: 378px;
  height: 0;
  visibility: hidden;
}

.list_box {
  display: flex;
  // justify-content:start;
  justify-content: space-between;

  /* justify-content: flex-start; */
  flex-wrap: wrap;
  /* padding: 16px 20px; */
  padding-bottom: 0;

  .items {
    border: 1px solid #fff;
    background: #fff;
    min-width: 370px;
    width: 370px;
    // height: 156px;
    padding: 16px;
    margin-top: 10px;

    // background: linear-gradient(-45deg, #00A0FF, #0A65FF);
    // box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
    border-radius: 10px;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 3px;
    }
    /* &:hover {
      background: linear-gradient(-45deg, #00a0ff, #0a65ff);
      color: white;
    } */
    // &:nth-child(3n) {
    //   margin-right: 0 !important;
    // }

    /* &:hover {
      border: 1px solid #2084d6;
    } */
    .item-title {
      font-size: 12px;
      font-weight: bold;
      // padding-bottom: 10px;
      margin-top: 12px;
      align-items: center;
      /* border-bottom: 1px solid #e5e5e5; */
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 3px;

      /* p {
        width: 150px;
      } */
      .price {
        flex-shrink: 0;
      }
    }

    .origin {
      margin-top: 10px;
    }
  }
}

.el-table__header tr,
.el-table__header th {
  padding: 0 !important;
  height: 42px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #333333 !important;
}

.el-table__body tr,
.el-table__body td {
  padding: 0 !important;
  height: 42px !important;
}
</style>
<style lang="scss" scoped>
.image-slot {
  height: 100%;
  width: 100%;
}

.error_img {
  height: 100%;
  width: 100%;
  /* background: url("../../assets/img/banner.jpg"); */
  background-size: 100% 100%;
}

.partner_title {
  padding-top: 50px;
  text-align: left;
  font-size: 26px;
  font-weight: 500;
}

.partner_title2 {
  width: 263px;
  margin-top: 20px;
  text-align: left;
  line-height: 25px;
}

.flx_space {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.menu_box {
  background: rgb(255, 255, 255);
  position: fixed;
  top: 124px;
  z-index: 999;
  width: 100%;
}

::v-deep .el-carousel--horizontal {
  overflow-x: initial;
}

::v-deep .el-carousel {
  overflow: hidden;
}

.flex_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: #fff; */
  height: 48px;
  align-items: baseline;
}

.index {
  width: 100%;

  // 轮播图
  .banner {
    min-width: 1903px;
    position: relative;
    width: 100%;
    .indicator {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      left: 50%;
      bottom: 0;
      width: 1200px;
      height: 80px;
      z-index: 9999999;
      transform: translate(-50%, 50%);
      background-color: #fff;
      border: 1px solid #000;
    }
  }

  // 交易量
  .business {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // width: 100%;
    // min-width: 1903px;
    // background: url("../../assets/home_img/2.png") no-repeat;
    // background-size: 100% 100%;
    .itemAll {
      width: 1000px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    .business_item {
      display: flex;
      align-items: center;

      .item_img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 104px;
        height: 104px;
        border-radius: 50%;
        margin-left: 81px;
      }

      .inner {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 73px;
        margin-left: 28px;

        .num {
          font-size: 38px;
          font-weight: bold;

          .mini {
            font-size: 26px;
          }
        }
      }
    }
  }

  // 销售列表
  .market_list {
    // margin-top: 20px;
    width: 1440px;
    display: flex;
    .list_box {
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .every_box {
        background-color: #fff;
        padding: 14px 0;
        // height: 156px;
        box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
        border-radius: 10px;
        width: calc(calc(100% / 3) - 22px);

        .header {
          padding: 0 20px;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          border-bottom: 1px solid #dddddd;
          padding-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .body {
          margin: 0;
          color: #333333;
        }

        .foot {
          font-size: 12px;
          margin-top: 13px;
          padding: 0 20px;
          color: #999999;

          .onespan {
            display: inline-block;
            width: 337px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .mianyi {
          font-size: 20px;
          color: #fe7b35;
        }

        &:hover .mianyi {
          color: #fff100;
        }

        &:hover .body {
          color: #ffffff;
        }

        &:hover .header {
          color: #ffffff;
        }

        &:hover .foot {
          color: #ffffff;
        }
      }

      .every_box:hover {
        color: #fff;
        background: linear-gradient(125deg, #00a0ff, #0a65ff);
      }
    }

    /* .list_box :nth-child(n + 4) {
      margin-top: 22px;
    } */
  }

  .headbox {
    // width: 1142px;
    height: 48px;
    padding: 0 30px;
    background: #fff;
    align-items: baseline;
    border-radius: 8px;
  }

  .boder_bottom {
    display: flex;
    font-size: 16px;
    color: #333333;
    line-height: 42px;
  }

  .more {
    font-size: 15px;
    font-weight: bold;
    color: #4861fe;
    /* padding-top: 30px; */
  }

  .mores {
    font-size: 15px;
    font-weight: bold;
    color: #7937fe;
  }

  // 销售
  .market {
    width: 1440px;
    display: flex;
    position: relative;
    & > .right-freight {
      position: absolute;
      top: 0;
      width: 100px;
      height: 120px;
      right: -120px;
      background: url("../../assets/home_img/freight.png");
      background-size: contain;
      cursor: pointer;
      padding: 16px;
      .freight-check {
        margin-top: 4px;
        font-size: 10px;
        color: #999;
      }
      .freight-title {
        display: inline-block;
        font-size: 14px;
        .line {
          height: 6px;
          width: 100%;
          border-radius: 3px;
          background: linear-gradient(
            to right,
            rgba(54, 123, 248, 0.54),
            rgba(107, 177, 255, 0.54)
          );
          margin-top: -6px;
        }
      }
    }
    .market_left {
      width: 100%;
    }

    .market_right {
      width: 100%;
      /* box-shadow: 10px 10px 5px #f1f1f1; */
      background: #fd5b4c;
      color: #fff;
      padding: 20px;
    }

    .market_righton {
      width: 100%;
      /* box-shadow: -10px 10px 5px #f1f1f1; */
      /* padding-left: 10px; */
    }

    .data2 {
      width: 44px;
    }

    .title2 {
      text-align: left;
      width: 147px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  // 服务理念
  .service {
    // background: url("../../assets/img/kuanggong.jpg") no-repeat;
    background-size: cover;
    width: 100%;
    // height: 212px;
    margin-top: 20px;

    .service_box {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
    }

    .sercice_item {
      display: flex;
      width: calc(calc(100% / 3) - 20px);
      margin: 10px;
      align-items: center;
      justify-content: center;

      .img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #3ca9fc;
        background-image: url("../../assets/images/icon01.png");
      }

      .img2 {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #3ca9fc;
        background-image: url("../../assets/images/icon02.png");
      }

      .img3 {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #3ca9fc;
        background-image: url("../../assets/images/icon03.png");
      }

      .title {
        margin-left: 40px;
        height: 100px;
        padding-bottom: 6px;

        .one {
          font-size: 26px;
          font-weight: 400;
          color: #ffffff;
        }

        .two {
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }

  // 合作伙伴
  .partner_on {
    display: flex;
    width: 1440px;
    margin: 0 auto;
  }
  .partner_tw {
    width: 263px;
    margin-top: 20px;
  }
  .freight {
    width: 1440px;
    height: 120px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
    background-size: 100% 100%;
    background-image: url("../../assets/images/freightEntrance.png");
    .freightImg {
      position: relative;
    }
    .freightButton {
      cursor: pointer;
      position: absolute;
      left: 51%;
      margin-top: 46px;
      top: 0;
      z-index: 999;
      color: white;
      border: 1px solid white;
      width: 100px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      border-radius: 10px;
    }
  }
  .partner {
    width: 100%;
    /* background-color: #ffffff; */
    background-image: url("../../assets/home_img/8.png");
    background-size: 100% 100%;
    margin-top: 28px;
    .panter_list {
      margin-left: 36px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      justify-items: center;
      text-align: justify;
      padding: 55px 0;
      align-items: center;
      .partner_item {
        width: 180px;
        margin-top: 10px;
        margin-left: 10px;
        height: 72px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
}
// .panter_list:after {content: ""; width: 560px; }
.home_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  background: #ffffff;
  /* padding: 0 67px; */

  .home_menu_item {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }

  .home_menu_item_action {
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    color: #096aff;
  }
}

.blue_border {
  font-size: 20px;
  padding-bottom: 13px;
  /* border-bottom: 3px solid #3b76ff; */
}

.more_two {
  font-size: 16px;
  color: #fe7b35;
}

.more_tree {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}
::v-deep .banner {
  .el-carousel__container {
    position: relative;
    width: 100%;
    height: 440px !important;
  }
}

::v-deep .el-carousel {
  position: relative;
}

::v-deep .el-table__body tr,
.el-table__body td {
  cursor: pointer;
}

.nodataImg {
  padding: 100px 0;
  text-align: center;
  background: #fff;
}

.indicator_span {
  display: inline-block;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Listing {
  min-width: 284px;
  // height: 388px;
  background: url("../../assets/home_img/5.png") no-repeat;
  background-size: 100% 100%;
  padding: 45px 40px;
  color: white;
}
.Listingss {
  min-width: 284px;
  // height: 384px;
  background: url("../../assets/home_img/10.png") no-repeat;
  background-size: 100% 100%;
  padding: 45px 40px;
  color: white;
}

.Listings {
  margin-right: 20px;
  // height: 422px;
  min-width: 284px;
  background: url("../../assets/home_img/4.png") no-repeat;
  background-size: 100% 100%;
  padding: 45px 40px;
  color: white;
}

.btn_in {
  button {
    cursor: pointer;
    background: #4861fe;
  }
}
::v-deep {
  .Invitation {
    .active_on {
      font-weight: bold;
      color: #f48d0c;
      border-bottom: 2px solid #f48d0c;
    }
  }
}

.btn_ins {
  button {
    background: #fea638;
  }
}

::v-deep .boder_bottom {
  .el-menu {
    height: 48px !important;
    top: -28px;
    font-size: 14px !important;
  }

  .el-menu--horizontal > .el-menu-item {
    height: 47px;
  }
}

.gengduo {
  display: flex;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-rl;
  width: 32px;
  height: 105px;
  color: #7343d1;
  cursor: pointer;
  /* background: rgba(121, 55, 254, 1); */
  background-color: #f8f5ff;
  font-weight: 500;
  color: balck;
  /* padding-top: 15px; */
  /* margin: 12px; */
  margin-top: 12px;
  margin-left: 28px;
}

::v-deep .el-table .el-table__cell {
  padding: 7px 0;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  color: #409eff;
  font-size: 14px;
}
::v-deep .el-table th.el-table__cell {
  background: #f6f8fa;
}
::v-deep .el-table th.el-table__cell > .cell {
  background: #f6f8fa;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  font-size: 16px;
  line-height: 20px;
  height: 28px;
  line-height: 28px;
}
// ::v-deep .el-table .cell {
//   font-family: PingFangSC-Medium, PingFang SC;
//   font-weight: 500;
//   color: rgba(0, 0, 0, 0.77);
//   white-space: nowrap;
// }
::v-deep .item_Op {
  font-weight: 600;
}
.active_on {
  border-bottom: 3px solid #4861fe;
  color: #4861fe;
  font-weight: 600;
}
.caigou {
  margin-left: 34px;
  cursor: pointer;
}
.index_info {
  margin-top: 15px;
  width: 32px;
  height: 18px;
  background: #7343d1;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  text-align: center;
}
.index_infos {
  width: 203px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
  margin-top: 11px;
  cursor: pointer;
}
.index_p {
  height: 40px;
  text-overflow: ellipsis;
  font-weight: 600;
  overflow: hidden;
}
.jingjia_in {
  width: 203px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
  margin-top: 11px;
  cursor: pointer;
}
.jingjia_on {
  width: 32px;
  margin-top: 15px;
  height: 18px;
  background: #7343d1;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  text-align: center;
}
.messageCont {
  height: 40px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
}
.list_all {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.img_item {
  width: 100%;
  height: 440px;
}
.el-carousel__item h3 {
  color: black;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

::v-deep .TextClass {
  width: 100%;
  min-width: 1903px;
  background: url("../../assets/home_img/2.png") no-repeat;
  background-size: 100% 100%;
  .el-carousel__item h3 {
    line-height: 80px;
    text-align: center;
  }
}
.jiaoyi_c {
  margin-top: 12px;
  width: 162px;
  height: 28px;
  background: #f2f4ff;
  border-radius: 4px;
  line-height: 28px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #323232;
  padding-left: 14px;
}
.priceIndex-box {
  width: 1440px;
  display: flex;
  justify-content: space-between;
  margin: 20px auto 0;
  .priceIndex-box_left {
    width: 760px;
  }
  .priceIndex-box_right {
    width: 650px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .bg {
      position: absolute;
    }
    .detail_btn {
      color: white;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 56px;
      text-align: center;
      cursor: pointer;
      .arrow {
        width: 36px;
        margin-bottom: 5px;
      }
    }
  }
}
.priceIndex-box_btn {
  color: white;
}
.priceIndex-dialog-box {
  width: 100%;
  margin: 0 auto;
}
.my-dialog {
  ::v-deep .el-dialog__header,
  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
</style>
