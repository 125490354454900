<template>
  <div class="take">
    <div class="bidd">
      <!-- 挂牌采购我发布的 -->
      <div class="biddings">
        <div
          class="item"
          v-for="(item, index) in readlist"
          :key="index"
          @click="ItemClick(item)"
        >
          <div class="item_title">
            <div class="item_goodsName">
              <el-tooltip effect="dark" placement="top-start">
                <div slot="content">
                  <span
                    v-for="(goodsName, index) in item.goodsCategoryList"
                    :key="index"
                    >{{ goodsName.goodsName }} &nbsp;
                    {{
                      index == item.goodsCategoryList.length - 1 ? "" : "|"
                    }}&nbsp;</span
                  >
                </div>
                <div>
                  <span
                    v-for="(goodsName, index) in item.goodsCategoryList"
                    :key="index"
                    >{{ goodsName.goodsName }} &nbsp;
                    {{
                      index == item.goodsCategoryList.length - 1 ? "" : "|"
                    }}&nbsp;</span
                  >
                </div>
              </el-tooltip>
              <div style="color: rgb(208, 17, 26)">
                竞价开始日期：{{ item.biddingStartTime }}
              </div>
              <div style="margin-left: 20px; color: rgb(208, 17, 26)">
                竞价结束日期：{{ item.biddingEtartTime }}
              </div>
            </div>
            <div style="display: flex">
              <div style="line-height: 40px; margin-right: 10px">
                {{ item.createTime }}
              </div>
              <!-- <div
                class="title_but"
                @click="closeDemand(item)"
                v-if="item.goodsStatus == 0"
              >
                关闭需求
              </div> -->
              <div class="title_but" v-if="item.goodsStatus == 0">未开始</div>
              <div
                class="title_but"
                v-if="item.goodsStatus == 1"
                style="color: #139102"
              >
                进行中
              </div>
              <div
                class="title_but"
                v-if="item.goodsStatus == 2"
                style="color: #d0111a"
              >
                已流拍
              </div>
              <div
                class="title_but"
                v-if="item.biddingStatus == 1 && item.goodsStatus == 3"
              >
                已中标
              </div>
              <div
                class="title_but"
                v-if="item.biddingStatus == 0 && item.goodsStatus == 3"
              >
                未中标
              </div>
              <div class="title_but" v-if="item.goodsStatus == 4">已关闭</div>
            </div>
          </div>
          <div class="item_name">
            <div class="item_images">
              <span v-if="item.taxIncluded == 0">含税</span>
              <span v-if="item.taxIncluded == 1">不含税</span>
              <span v-if="item.priceType == 0">未知</span>
              <span v-if="item.priceType == 1">坑口价</span>
              <span v-if="item.priceType == 2">出厂价</span>
              <span v-if="item.priceType == 3">到港价</span>
              <span v-if="item.priceType == 4">平仓价</span>
              <span v-if="item.priceType == 5">车板价</span>
              <span v-if="item.priceType == 6">到厂价</span>
            </div>
            <div style="margin-left: 20px" class="price-count">
              竞价底价：
              <el-tooltip effect="dark" placement="top-start">
                <div slot="content">
                  <span
                    v-for="(goodsItem, i) in item.goodsCategoryList"
                    :key="i"
                  >
                    <span>￥</span><span>{{ goodsItem.goodsPrice }}</span
                    >元/吨 &nbsp;
                    <span>数量</span>
                    <span>{{ goodsItem.number }}</span
                    >吨
                    <span v-if="i != item.goodsCategoryList.length - 1">|</span>
                  </span>
                </div>
                <span>
                  <span
                    v-for="(goodsItem, i) in item.goodsCategoryList"
                    :key="i"
                  >
                    <span style="color: #d0111a">￥</span
                    ><span style="color: #d0111a; font-size: 30px">{{
                      goodsItem.goodsPrice
                    }}</span
                    >元/吨 &nbsp;
                    <span>数量</span>
                    <span style="font-size: 30px; color: #d0111a">{{
                      goodsItem.number
                    }}</span
                    >吨
                    <span
                      v-if="i != item.goodsCategoryList.length - 1"
                      style="font-size: 26px"
                      >|</span
                    >
                  </span>
                </span>
              </el-tooltip>
            </div>
          </div>
          <!-- <div class="item_num">
            <div>
              数量：<span>{{ item.stockCount }}(吨)</span>
            </div>
            <div>竞价开始日期：{{ item.deliveryStartTime }}</div>
            <div>竞价结束日期：{{ item.deliveryEndTime }}</div>
          </div> -->
          <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
          <div class="place">产地：{{ item.originPlace }}</div>
        </div>
        <div class="allpage" v-if="readlist.length > 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tradeGoods } from "@/api/public.js";
export default {
  data() {
    return {
      readlist: [],
      total: 0,
      size: 10,
      page: 1,
    };
  },
  mounted() {
    this.handleCurrentChange();
  },
  methods: {
    async handleCurrentChange(val) {
      let config = {
        page: val,
        size: this.size,
        selectOrderType: 2,
        selectTradeType: 2,
        selectType: 1,
      };
      let res = await tradeGoods(config);
      if (res.code == 0) {
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
        });
        this.readlist = list;
        this.total = res.data.total;
      }
    },
    async getlist() {
      let config = {
        page: this.page,
        size: this.size,
        selectOrderType: 2,
        selectType: 1,
      };
      let res = await tradeGoods(config);
      if (res.code == 0) {
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
        });
        this.readlist = list;
        this.total = res.data.total;
      }
    },
    ItemClick(item) {
      // this.$router.push("/details");
      this.$router.push({
        path: "/purchaseDetails",
        query: {
          goodsId: item.goodsId,
          status: item.goodsStatus,
          selectOrderType: 2,
          selectTradeType: 2,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}
.take {
  max-width: 1200px;
  margin: 20px auto 0;
}

.bidd {
  // width: 80%;
  // display: flex;
  // justify-content: space-between;
  background: white;
  padding: 10px;
  margin: 0 auto;
  .biddings {
    // width: 70%;
    .item {
      width: 100%;
      border: 1px solid #e5e5e5;
      padding: 10px;
      margin-top: 10px;
      .item_title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #ccc;
        // padding-bottom: 10px;
        .item_goodsName {
          display: flex;
          line-height: 40px;
          :nth-child(1) {
            font-size: 18px;
            color: black;
            font-weight: bold;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 200px;
          }
          :nth-child(2) {
            margin-left: 13px;
          }
        }
        .title_but {
          padding: 10px;
          // border: 1px solid #ccc;
          cursor: pointer;
        }
      }
      .item_name {
        display: flex;
        // justify-content: space-between;
        margin-top: 5px;
        .item_images {
          width: 100px;
          height: 24px;
          background: url("../../assets/images/tag.png") no-repeat;
          background-size: 100% 100%;
          color: white;
          padding-left: 3px;
          padding-top: 2px;
          margin-top: 10px;
        }
        // :nth-child(1) {
        //   width: 300px;
        // }
        // :nth-child(2) {
        //   width: 300px;
        // }
      }
      .item_num {
        :nth-child(1) {
          width: 300px;
        }
        :nth-child(2) {
          width: 300px;
        }
        display: flex;
        margin-top: 10px;
        // justify-content: space-between;
      }
      .Delivery {
        margin-top: 10px;
      }
      .place {
        margin-top: 10px;
      }
      .price-count {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 500px;
      }
    }
  }
  .allpage {
    text-align: right;
    margin-top: 10px;
  }
  .release {
    height: 40px;
    padding: 10px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
}
</style>
