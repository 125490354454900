<template>
  <div>
    <div class="add-goods">
      <el-form ref="form" :model="formData" :rules="rules" label-width="140px">
        <div class="title">基本信息</div>
        <div class="group">
          <div class="line">
            <div class="pirceType">
              <el-form-item label="价格类型" prop="taxIncluded">
                <el-select
                  v-model="formData.taxIncluded"
                  placeholder="是否含税"
                  @change="selectChange('taxIncluded')"
                >
                  <el-option label="含税" :value="0"></el-option>
                  <el-option label="不含税" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="priceType" label-width="0">
                <el-select
                  v-model="formData.priceType"
                  placeholder="请选择类型"
                  @change="selectChange('priceType')"
                >
                  <el-option
                    v-for="item in priceTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <el-form-item label="总量" :prop="radio ? '' : 'initialCount'">
              <el-input
                v-model.trim="formData.initialCount"
                :disabled="radio"
                placeholder="请输入总量"
                maxlength="7"
              >
                <!-- @input="formatData('initialCount')" -->

                <template slot="append">吨</template>
              </el-input>
              <el-checkbox
                style="margin-left: 20px"
                @change="priceChanges"
                v-model="radio"
                :disabled="formData.initialCount > 0"
                >以实际结算数量为准</el-checkbox
              >
            </el-form-item>
          </div>
          <div class="line">
            <div class="pay-type">
              <el-form-item label="支付方式" prop="payType" label-width="140">
                <el-select
                  v-model="formData.payType"
                  placeholder="请选择支付方式"
                  @change="selectChange('payType')"
                >
                  <el-option
                    v-for="item in payType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="pay-type" v-if="formData.orderType == 1">
              <el-form-item
                label="标的物是否拆分"
                prop="ifSplit"
                label-width="140"
              >
                <el-select
                  v-model="formData.ifSplit"
                  placeholder="请选择标的物是否拆分"
                  :disabled="formData.orderType == 1 && radio"
                  @change="selectChange('ifSplit')"
                >
                  <el-option
                    v-for="item in ifSplit"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="pay-type" v-else>
              <el-form-item
                label="配送方式"
                prop="deliveryMode"
                label-width="140"
              >
                <el-select
                  v-model="formData.deliveryMode"
                  placeholder="请选择配送方式"
                  @change="selectChange('deliveryMode')"
                >
                  <el-option
                    v-for="item in deliveryMode"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="line">
            <div class="pay-type" v-if="formData.orderType == 1">
              <el-form-item
                label="配送方式"
                prop="deliveryMode"
                label-width="140"
              >
                <el-select
                  v-model="formData.deliveryMode"
                  placeholder="请选择配送方式"
                  @change="selectChange('deliveryMode')"
                >
                  <el-option
                    v-for="item in formData.orderType == 1
                      ? deliveryMode1
                      : deliveryMode"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div v-if="formData.orderType == 2">
              <el-form-item label="下架时间" prop="offShelfTime">
                <el-date-picker
                  v-model="formData.offShelfTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择下架时间"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="line" v-if="formData.orderType == 1">
              <el-form-item label="下架时间" prop="offShelfTime">
                <el-date-picker
                  v-model="formData.offShelfTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择下架时间"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>

          <div
            class="line goods-list"
            v-for="(goods, index) in formData.goodsCategoryList"
            :key="goods.key"
          >
            <el-form-item
              label="商品分类"
              :prop="'goodsCategoryList.' + index + '.goodsType'"
              :rules="{
                required: true,
                message: '请选择商品分类',
                trigger: 'change',
              }"
            >
              <el-select
                v-model="formData.goodsCategoryList[index].goodsType"
                @change="goodsChange($event, goods, index)"
                placeholder="请选择商品分类"
              >
                <el-option
                  v-for="item in nameList"
                  :key="item.id"
                  :label="item.dictName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="商品名称"
              label-width="140px"
              :prop="'goodsCategoryList.' + index + '.goodsName'"
              :rules="{
                required: true,
                message: '请输入商品名称',
                trigger: 'blur',
              }"
            >
              <!-- <el-cascader v-model="formData.goodsName" :options="nameList" :props="{
                  value: 'id',
                  label: 'dictName',
                }" placeholder="请选择商品名称" @change="goodsChange"></el-cascader> -->
              <el-input
                v-model="formData.goodsCategoryList[index].goodsName"
                placeholder="请输入商品名称"
              >
              </el-input>

              <!-- <el-select v-model="formData.goodsName" @change="goodsChange" placeholder="请选择商品名称">
                <el-option v-for="item in nameList" :key="item.id" :label="item.dictName" :value="item.id">
                </el-option>
              </el-select> -->
            </el-form-item>

            <el-form-item
              label="价格"
              label-width="80px"
              :prop="'goodsCategoryList.' + index + '.goodsPrice'"
              :rules="{
                required: !formData.goodsCategoryList[index].checked,
                message: '请输入价格',
                trigger: 'blur',
              }"
              class="listingPrice"
            >
              <el-input
                v-model="formData.goodsCategoryList[index].goodsPrice"
                placeholder="请输入价格"
                maxlength="20"
                @input="formatDatas(index, 'goodsPrice')"
                :readonly="formData.goodsCategoryList[index].checked"
                :disabled="formData.goodsCategoryList[index].checked"
              >
                <template slot="append">元/吨</template>
              </el-input>
              <el-checkbox
                v-model="formData.goodsCategoryList[index].checked"
                :disabled="formData.goodsCategoryList[index].goodsPrice != ''"
                @change="priceChange($event, index)"
                >面议</el-checkbox
              >
            </el-form-item>
            <el-button
              v-if="goods.key == 0 && formData.orderType == 1"
              style="margin-left: 10px"
              @click="addGoodes()"
              >增加</el-button
            >
            <el-button
              v-if="goods.key != 0 && formData.orderType == 1"
              style="margin-left: 10px"
              @click="delGoods(goods)"
              >删除</el-button
            >
          </div>

          <div
            v-for="(goods, goodsIndex) in formData.goodsCategoryList"
            :key="'goods' + goods.key"
          >
            <div class="quotaList-box" v-if="goods.quotaList.length > 0">
              <h3>{{ goods.dictName }}商品指标</h3>
              <div class="list">
                <div
                  class="quota-item"
                  v-for="(item, index) in goods.quotaList"
                  :key="item.id"
                >
                  <el-form-item
                    :label="item.dictName"
                    :prop="
                      'goodsCategoryList.' +
                      goodsIndex +
                      '.quotaList.' +
                      index +
                      '.quotaValue'
                    "
                    :rules="{
                      required: item.ifMust == 0,
                      message: item.dictName + '不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model.trim="item.quotaValue"
                      :placeholder="`请输入${item.dictName}`"
                      @input="formatFloat(goodsIndex, index, 'quotaValue')"
                    >
                      <template slot="append" v-if="item.dictValue">{{
                        item.dictValue
                      }}</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="textarea-box">
            <el-form-item label="备注" prop="">
              <el-input
                type="textarea"
                v-model="formData.remark"
                :rows="3"
                maxlength="10000"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="title">其他信息</div>
        <div class="group">
          <div class="line adress">
            <el-form-item label="交割地" prop="deliveryPlace">
              <el-cascader
                v-model="formData.deliveryPlace"
                :options="citys"
                @change="selectChange('deliveryPlace')"
                placeholder="请选择交割地"
                :props="{
                  value: 'name',
                  label: 'name',
                  checkStrictly: true,
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="交割地详细地址" prop="">
              <el-input
                v-model.trim="formData.deliveryPlaceDetail"
                placeholder="请输入详细地址"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
          <div class="line adress">
            <el-form-item label="产地" prop="originPlace">
              <el-cascader
                v-model="formData.originPlace"
                :options="citys"
                placeholder="请选择产地"
                @change="selectChange('originPlace')"
                :props="{
                  value: 'name',
                  label: 'name',
                  checkStrictly: true,
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="产地详细地址" prop="">
              <el-input
                v-model.trim="formData.originPlaceDetail"
                placeholder="请输入产地详细地址"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
          <div class="line line3">
            <el-form-item label="企业名称" prop="enterpriseName">
              <el-input
                v-model.trim="formData.enterpriseName"
                placeholder="请输入企业名称"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contacts">
              <el-input
                v-model.trim="formData.contacts"
                placeholder="请输入联系人"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="联系方式"
              prop="contactInfo"
              class="contactInfo"
              label-width="90px"
            >
              <el-input
                v-model.trim="formData.contactInfo"
                placeholder="请输入联系方式"
                maxlength="11"
                @input="formatData('contactInfo')"
              ></el-input>
            </el-form-item>
          </div>
          <!-- <div class="line">
            <el-form-item label="交割开始日期" prop="deliveryStartTime">
              <el-date-picker
                v-model="formData.deliveryStartTime"
                type="datetime"
                placeholder="请选择交割开始日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="交割结束日期" prop="deliveryEndTime">
              <el-date-picker
                v-model="formData.deliveryEndTime"
                type="datetime"
                placeholder="请选择交割结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </div> -->
        </div>
        <div class="btn-wrap">
          <el-button type="primary" class="hy-btn" @click="submit"
            >立即发布</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { reqDictType, reqAddGoods } from "@/api/hy";
import citys from "@/assets/json/citys";
import { getTreeV2 } from "@/api/Medium.js";
export default {
  data() {
    const validateContacts = (rule, value, callback) => {
      let validate = /^[\u4e00-\u9fa5·]{2,20}$/;
      if (!validate.test(value)) {
        return callback(new Error("名称只能输入2-20位汉字"));
      } else {
        return callback();
      }
    };
    return {
      citys: citys,
      radio: false,
      formData: {
        initialCount: "",
        taxIncluded: "",
        priceType: "",
        remark: "",
        deliveryPlace: "",
        originPlace: "",
        enterpriseName: "",
        contacts: "",
        payType: "",
        ifSplit: "",
        contactInfo: "",
        deliveryStartTime: "",
        deliveryEndTime: "",
        tradeType: 1,
        orderType: 1,
        deliveryPlaceDetail: "",
        originPlaceDetail: "",
        deliveryMode: "", //配送方式
        offShelfTime: "",
        goodsCategoryList: [
          {
            goodsName: "", //商品名称
            goodsType: "", //商品类型
            goodsPrice: "", //商品价格
            key: 0, //key
            quotaList: [], //指标列表
            checked: false, //价格是否面议
          },
        ],
      },
      rules: {
        offShelfTime: [
          { required: true, message: "请选择下架时间", trigger: "change" },
        ],
        goodsName: [
          { required: true, message: "请输入商品名称", trigger: "change" },
        ],
        goodsType: [
          { required: true, message: "请选择商品分类", trigger: "change" },
        ],
        initialCount: [
          { required: true, message: "请输入总量", trigger: "blur" },
        ],
        taxIncluded: [
          { required: true, message: "请选择价格类型", trigger: "blur" },
        ],
        priceType: [
          { required: true, message: "请选择价格类型", trigger: "blur" },
        ],
        payType: [
          { required: true, message: "请选择支付方式", trigger: "blur" },
        ],
        deliveryMode: [
          { required: true, message: "请选择配送方式", trigger: "blur" },
        ],
        ifSplit: [
          { required: true, message: "请选择标的物是否拆分", trigger: "blur" },
        ],
        listingPrice: [
          // { required: true, message: "请输入价格", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!this.formData.checked && value == "") {
                return callback(new Error("请输入价格"));
              } else if (this.formData.checked) {
                return callback();
              } else {
                return callback();
              }
            },
            trigger: "blur",
          },
        ],
        deliveryPlace: [
          { required: true, message: "请选择交割地", trigger: "blur" },
        ],
        originPlace: [
          { required: true, message: "请选择产地", trigger: "blur" },
        ],
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { validator: validateContacts, trigger: "blur" },
        ],
        contactInfo: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        deliveryStartTime: [
          { required: true, message: "请选择交割开始日期", trigger: "blur" },
        ],
        deliveryEndTime: [
          { required: true, message: "请选择交割结束日期", trigger: "blur" },
        ],
        deliveryPlaceDetail: [
          { required: true, message: "请输入交割地详细地址", trigger: "blur" },
        ],
        originPlaceDetail: [
          { required: true, message: "请输入产地详细地址", trigger: "blur" },
        ],
      },
      goodsNameList: [],
      normList: [],
      priceTypeList: [
        { value: 1, label: "坑口价" },
        { value: 2, label: "出厂价" },
        { value: 3, label: "到港价" },
        { value: 4, label: "平仓价" },
        { value: 5, label: "车板价" },
        { value: 6, label: "到厂价" },
      ],
      payType: [
        { value: 1, label: "现金" },
        { value: 2, label: "承兑" },
        { value: 3, label: "现金+承兑" },
      ],
      deliveryMode: [
        { value: 1, label: "送货" },
        { value: 2, label: "自提" },
      ],
      deliveryMode1: [
        { value: 1, label: "送货(以实际拉运方式为准)" },
        { value: 2, label: "自提(以实际拉运方式为准)" },
      ],
      ifSplit: [
        { value: 0, label: "是" },
        { value: 1, label: "否" },
      ],
      nameList: [],
      dictList: [],
    };
  },
  created() {
    this.formData.enterpriseName = window.localStorage.getItem("userName");
    this.formData.contactInfo = window.localStorage.getItem("phone");
  },
  watch: {
    radio() {
      if (this.radio) {
        if (this.formData.orderType == 1) {
          this.formData.ifSplit = 0;
        }
      }
    },
  },
  mounted() {
    // this.citys = citys.filter(item => item.value == '650100')
    let routeQuery = this.$route.query;
    this.formData.orderType = routeQuery.selectOrderType;
    this.getTree();
    this.getGoodsName();
  },
  methods: {
    delGoods(goods) {
      this.formData.goodsCategoryList = this.formData.goodsCategoryList.filter(
        (item) => item.key != goods.key
      );
    },
    addGoodes() {
      if (this.formData.goodsCategoryList.length >= 5) {
        this.$message.error("数量超出限制");
        return;
      }
      this.formData.goodsCategoryList.push({
        goodsType: "",
        goodsName: "",
        goodsPrice: "",
        key:
          this.formData.goodsCategoryList[
            this.formData.goodsCategoryList.length - 1
          ].key + 1,
        quotaList: [],
        checked: false,
      });
      console.log(this.formData.goodsCategoryList);
    },
    getTree() {
      getTreeV2().then((res) => {
        if (res.code == 0) {
          // this.citys = res.data
          this.citys = this.deleteChildren(res.data);
        }
      });
    },
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
          if (childs[i].children.length) {
            this.deleteChildren(childs[i].children);
          } else {
            delete childs[i].children;
          }
        }
      }
      return arr;
    },
    formatFloat(goodsIndex, index, name) {
      this.formData.goodsCategoryList[goodsIndex].quotaList[index][name] =
        this.formData.goodsCategoryList[goodsIndex].quotaList[index][
          name
        ].match(/^\d*(\.?\d{0,23})/g)[0] || "";
      if (
        this.formData.goodsCategoryList[goodsIndex].quotaList[index][
          name
        ].indexOf(".") > 0
      ) {
        this.formData.goodsCategoryList[goodsIndex].quotaList[index][name] =
          this.formData.goodsCategoryList[goodsIndex].quotaList[index][
            name
          ].substring(
            0,
            this.formData.goodsCategoryList[goodsIndex].quotaList[index][
              name
            ].indexOf(".") + 3
          );
      } else {
        this.formData.goodsCategoryList[goodsIndex].quotaList[index][name] =
          this.formData.goodsCategoryList[goodsIndex].quotaList[index][
            name
          ].substring(0, 9);
      }
    },
    priceChange(e, index) {
      if (this.formData.goodsCategoryList[index].checked) {
        this.formData.goodsCategoryList[index].goodsPrice = "";
      }
      this.$refs.form.clearValidate(
        "goodsCategoryList." + index + ".goodsPrice"
      );
    },

    priceChanges() {
      if (this.radio) {
        this.formData.initialCount = "";
      }
      this.$refs.form.clearValidate("initialCount");
    },
    selectChange(name) {
      this.$refs.form.clearValidate(name);
    },
    goodsChange(e, obj, index) {
      outer: for (let i = 0; i < this.dictList.length; i++) {
        let item = this.dictList[i];
        if (item.id == e) {
          item.children.forEach((items) => {
            this.$set(items, "quotaValue", "");
          });
          console.log(item);
          obj.dictName = item.dictName;
          this.goodsName = item.dictName;
          obj.quotaList = item.children.map((item) => {
            let arr = item.dictValue.split(";");
            return {
              ...item,
              dictName: item.dictName + "(" + arr[0] + ")" + arr[2],
              dictValue: arr[1],
            };
          });
          break outer;
        }
        // for (let j = 0; j < item.children.length; j++) {
        //   let sub = item.children[j];
        //   if (item.id == e[0] && sub.id == e[1]) {
        //     sub.children.forEach((item) => {
        //       this.$set(item, "quotaValue", "");
        //     });
        //     console.log(sub);
        //     this.goodsName = sub.dictName;
        //     this.formData.quotaList = sub.children.map((item) => {
        //       let arr = item.dictValue.split(";");
        //       return {
        //         ...item,
        //         dictName: item.dictName + "(" + arr[0] + ")" + arr[2],
        //         dictValue: arr[1],
        //       };
        //     });
        //     console.log(this.formData.quotaList);
        //     break outer;
        //   }
        // }
      }
      if (e.length > 0) {
        this.$refs.form.clearValidate(
          "goodsInfo.goodsCategoryList." + index + ".goodsName"
        );
      }
    },
    formatData(name) {
      this.formData[name] = this.formData[name].match(/^\d*/g)[0] || "";
      if (name == "initialCount") {
        if (this.formData.initialCount == 0) {
          this.formData.initialCount = "";
          this.$message({
            message: "数量不能为0",
            type: "warning",
          });
        }
      }
    },
    formatDatas(index, name) {
      //  this.formData[name] = this.formData[name].replace(/^\D*([0-9]\d*\.?\d{0,6})?.*$/, "$1");
      this.formData.goodsCategoryList[index][name] =
        this.formData.goodsCategoryList[index][name].match(/^\d*/g)[0] || "";
      if (name == "goodsPrice") {
        if (this.formData.goodsCategoryList[index].goodsPrice == 0) {
          this.formData.goodsCategoryList[index].goodsPrice = "";
          this.$message({
            message: "价格不能为0",
            type: "warning",
          });
        }
      }
    },
    getGoodsName() {
      reqDictType({ dictType: "coal_type" })
        .then((ret) => {
          if (ret.code == 0) {
            this.dictList = JSON.parse(JSON.stringify(ret.data.children));
            let data = ret.data.children;
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              for (let j = 0; j < item.children.length; j++) {
                let sub = item.children[j];
                delete sub.children;
              }
            }
            this.nameList = data;
            console.log(this.dictName);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    submit() {
      if (this.radio) {
        this.formData.initialCount = "0";
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitFn();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    initSubmitData() {
      let data = {
        ...this.formData,
      };
      // data.goodsType = data.goodsName;
      data.deliveryPlace = data.deliveryPlace.join(",");
      data.originPlace = data.originPlace.join(",");
      // data.goodsName = this.goodsName;
      data.goodsCategoryList.forEach((item) => {
        item.quotaList = item.quotaList.map((quota) => {
          return {
            quotaId: quota.id || quota.quotaId,
            quotaValue: quota.quotaValue,
          };
        });
        if (item.checked) {
          item.goodsPrice = 0;
        }
      });
      // delete data.deliveryPlaceDetail;
      // delete data.originPlaceDetail;
      data.goodsCategoryList = data.goodsCategoryList.map((item) => {
        return {
          goodsName: item.goodsName,
          goodsPrice: item.goodsPrice,
          goodsType: item.goodsType,
          quotaList: item.quotaList,
        };
      });
      return data;
    },
    submitFn() {
      if (this.submiting) return;
      this.submiting = true;
      let data = this.initSubmitData();
      console.log(data);
      reqAddGoods(data)
        .then((ret) => {
          if (ret.code == 0) {
            this.$alert("发布成功,请等待平台审核", "提示", {
              confirmButtonText: "确定",
              customClass: "hy-confirm",
              cancelButtonClass: "hy-confirm-cancel-btn",
              iconClass: "hy-icon-jingjia",
              callback: () => {
                this.$router.go(-1);
              },
            });

            // this.$confirm("发布成功,请等待平台审核", "提示", {
            //   // confirmButtonText: "确认",
            //   cancelButtonText: "关闭",
            //   // cancelButtonClass: "hy-confirm-cancel-btn",
            //   type: "warning",
            //   // customClass: "hy-confirm",
            //   iconClass: "hy-icon-jingjia",
            // })
            //   .then(() => {
            //     this.$router.go(-1);
            //   })
            //   .catch(() => {
            //     this.$router.go(-1);
            //   });
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
          this.submiting = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-goods {
  width: 1195px;
  margin: 14px auto;
  background: white;
  border: 1px solid #e5e5e5;
  border-top: none;

  &::before,
  &::after {
    display: table;
    content: "";
  }
}

.title {
  font-size: 18px;
  font-weight: bold;
  font-family: PingFang SC;
  padding: 14px 15px;
  border: 1px solid #e5e5e5;
  border-left: none;
  border-right: none;
  background: #f7f7f7;
  display: flex;
  align-items: center;
}

.btn-wrap {
  text-align: center;
  margin-bottom: 35px;

  .hy-btn {
    width: 193px;
  }
}

.listingPrice .el-checkbox {
  margin-left: 20px;
}

.textarea-box {
  width: 100%;
}

.quotaList-box {
  h3 {
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    font-weight: normal;
    width: 140px;
    text-align: right;
    padding-right: 12px;
  }

  .list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .quota-item {
      width: 50%;
      flex-shrink: 0;
    }
  }
}

.group {
  padding: 32px 39px;
  background: white;
}

.line {
  display: flex;
  align-items: center;

  .el-input,
  .el-select,
  .el-cascader {
    width: 275px;
  }

  .pay-type {
    .el-form-item {
      display: flex;

      ::v-deep .el-form-item__label {
        width: 140px;
      }
    }
  }
}
.goods-list {
  align-items: flex-start;
  .el-input,
  .el-select,
  .el-cascader {
    width: 175px;
  }
  .el-form-item {
    ::v-deep .el-form-item__label {
      width: 100px;
    }
  }
}
.adress {
  .el-form-item:nth-child(2) {
    .el-input {
      width: 564px;
    }
  }
}

.line3 .contactInfo {
  .el-input {
    width: 200px;
  }
}

.pirceType {
  display: flex;
  align-items: center;

  .el-select,
  .el-input {
    width: 137.5px;
  }
}
</style>
